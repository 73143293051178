import styled from 'styled-components/macro'
import { screens } from '../../../constants/theme'

export const CardsContainerCss = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1125px;
  margin: 60px auto;

  @media (max-width: ${screens.lg}) {
    margin-left: 0px;
  }
`