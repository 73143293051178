import styled from 'styled-components'
import { screens, colors } from '../../../constants/theme'
import { motion } from 'framer-motion'

export const JoinUsFirstSectionWrapperCss = styled.div`
  /* display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;
  place-content: center;
  overflow: hidden; */
`

export const StoriesWrapperMobile = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  background-color: ${colors.black};
`

export const StoriesBlockOuterCss = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
`

export const StoriesBlockCss = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;

  @media (min-width: ${screens.lg}) {
    width: var(--story-w);
    height: var(--story-h);
    padding: 90px 0;
    margin-left: 37vw;
  }
`

export const StoriesInnerCss = styled(motion.div)`
  height: 100%;
  display: flex;

  @media (min-width: ${screens.lg}) {
    height: var(--story-h);
    column-gap: 35px;
  }
`

export const StoriesItemCss = styled(motion.div)`
  position: relative;
  background-color: #29292d;
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  @media (min-width: ${screens.lg}) {
    width: var(--story-w);
    height: var(--story-h);
  }

  .content,
  .gif-cover,
  .gradient-bg {
    position: absolute;
  }

  .gradient-bg {
    left: 0;
    right: 0;
    bottom: 0;
    height: 70%;
    background: linear-gradient(180deg,rgba(0,0,0,0) 10%,rgba(0,0,0,0.96) 100%);
    z-index: 2;
  }

  .content {
    object-fit: cover;
    z-index: 3;
    height: 100vh;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: ${screens.lg}) {
      width: var(--story-w);
      height: var(--story-h);
    }
  }

  .gif-cover {
    z-index: 1;
    width: 100%;
  }

  .footer {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6;
    bottom: 14vh;

    @media (min-width: ${screens.lg}) {
      bottom: 30px;
    }

    a {
      margin: 0 !important;
      font-size: 12px;
    }
  }
`

export const StoriesIndicators = styled.div`
  position: absolute;
  left: 8px;
  right: 8px;
  top: 16px;
  display: flex;
  z-index: 3;

  @media (min-width: ${screens.lg}) {
    transform: scale(1.35);
  }

  > span {
    flex: 1;
    height: 10px;
    margin: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`

export const StoriesIndicatorItem = styled(motion.span)`
  background-color: #c7c7c7;
  display: block;
  width: 100%;
  height: 2px;
`

export const NavigationBlockCss = styled.div`
  display: block;
  position: absolute;
  display: grid;
  width: 2px;
  height: 2px;
  place-items: center;
  place-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-1px, -1px);
  z-index: 4;

  div {
    width: var(--story-nav-w);
    height: 48px;
    position: relative;
  }

  button {
    all: unset;
    position: absolute;
    top: 0px;
    width: 48px;
    height: 48px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    &.prev {
      left: 0px;
      img {
        transform: rotate(-90deg);
      }
    }

    &.next {
      right: 0;
      img {
        transform: rotate(90deg);
      }
    }
  }
`

export const StoriesTriggerCss = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .outer {
    width: 160px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .outline {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    border: 6px solid ${colors.blue};
    box-sizing: border-box;
  }

  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    background-color: ${colors.white};
    font-size: 18px;
    color: ${colors.black};
    text-align: center;
    border-radius: 19px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .icon {
    width: 80px;
    height: 80px;
  }
`



export const MobileNavigationBlockCss = styled.div`
  display: flex;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  top: 50px;

  button {
    all: unset;
    flex: 1;
    height: 100%;
  }
`