import styled, { keyframes } from 'styled-components';
import { screens } from '../../../constants/theme';
import launchMask from './assets/launch-mask.png';

export const GifWrapperCss = styled.div`
  position: relative;
  max-width: 413px;
  max-height: 333px;
  margin-top: 50px;

  @media screen and (max-width: ${screens.xs}) {
    max-width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const LaunchGifCss = styled.img`
  mask-image: url(${launchMask});
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const handWave = keyframes`
  0% {
    transform: scale(0.9, 0.9) rotate(0deg);
  }

  50% {
    transform: scale(0.9, 0.9) rotate(4deg);
  }

  100% {
    transform: scale(0.9, 0.9) rotate(0deg);
  }
`

const mobileHandWave = keyframes`
  0% {
    transform: scale(0.7, 0.7) rotate(0deg);
  }

  50% {
    transform: scale(0.7, 0.7) rotate(4deg);
  }

  100% {
    transform: scale(0.7, 0.7) rotate(0deg);
  }
`

export const HandCss = styled.img`
  position: absolute;
  right: -40px;
  bottom: -40px;

  animation: ${handWave} 2.5s linear infinite;

  @media screen and (max-width: ${screens.sm}) {
    animation: ${mobileHandWave} 2.5s linear infinite;
  }
`;