import React from "react";
import {
  SeventhSectionWrapperCss,
  BlockCss,
  InfoCss,
  ImgAndVideoCss,
  ImgAndVideoCssMobile,
} from "./index.css";
import img1 from "./assets/image1.png";
import headlights from "./assets/Headlights.mp4";

const SeventhSection = () => {
  return (
    <SeventhSectionWrapperCss>
      <BlockCss>
        <InfoCss>
          <div>
            <h1>Complex yet Responsive</h1>
            <p>
              There’s a lot to take in, but the user receives information in an
              appropriate sequence. Well-thought layouts contribute to a
              seamless and immersive browsing experience, on any device.
            </p>
          </div>
        </InfoCss>
        <ImgAndVideoCss>
          <img src={img1} alt="" />
          <video
            className="headlights"
            controls={false}
            preload="auto"
            autoPlay={true}
            loop={true}
            muted="muted"
            playsInline
            webkit-playingInline
          >
            <source src={headlights} type="video/mp4" />
          </video>
        </ImgAndVideoCss>
        <ImgAndVideoCssMobile>
          <img src={img1} alt="" />
          <video
            className="headlights"
            controls={false}
            preload="auto"
            autoPlay={true}
            loop={true}
            muted="muted"
            playsInline
            webkit-playingInline
          >
            <source src={headlights} type="video/mp4" />
          </video>
        </ImgAndVideoCssMobile>
      </BlockCss>
    </SeventhSectionWrapperCss>
  );
};

export default SeventhSection;
