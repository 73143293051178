import React from 'react'
import {
  FourthSectionWrapperCss,
  BlockCss,
  InfoCss,
  GifCss,
  ImageCss,
  VideoAndImageBlockCss,
} from './index.css'

import gif from './assets/gif.mp4'
import vid1 from './assets/vid1.mp4'
import img1 from './assets/image1.png'
import img12x from './assets/image1@2x.png'
import img2 from './assets/2.5-new.gif'
import img3 from './assets/image3.svg'
import img4 from './assets/image4.svg'

const FourthSection = () => {
  return (
    <FourthSectionWrapperCss>
      <BlockCss>
        <InfoCss>
          <h2>Tech Solutions</h2>
          <p>
            In order to enhance interactivity and dynamism, we produced
            scroll-based animations via WebGL. A very demanding implementation
            process that allowed the users to browse an immersive digital
            experience.
          </p>
        </InfoCss>
        <GifCss>
          <video
            className="gif"
            controls={false}
            preload="auto"
            autoPlay={true}
            loop={true}
            muted="muted"
            playsInline
            webkit-playingInline
          >
            <source src={gif} type="video/mp4" />
          </video>
        </GifCss>
      </BlockCss>

      <InfoCss>
        <h2>3D Visual Process</h2>
        <p>
          3D renders contribute to the websites’ appeal, clearly showcasing the
          products' features. Together with Lea Studio, we started by presenting
          clay models to the client, in order to ensure an accurate
          representation of the product before approving the final draft.
          Afterwards, lights and textures were added to complete the final
          render.
        </p>
      </InfoCss>
      <ImageCss>
        <img src={img1} srcSet={`${img1} 1.5x, ${img12x} 2x`}  alt="" />
        <img src={img2} alt="" />
      </ImageCss>
      <InfoCss>
        <h2>SVG Animations</h2>
        <p>
          Since engagement and visual dynamism are paramount, we proposed a
          series of interactive SVG illustrations that gradually increased the
          website’s loading speed.
        </p>
      </InfoCss>
      <VideoAndImageBlockCss>
        <div className="left">
          <video
            className="vid1"
            controls={false}
            preload="auto"
            autoPlay={true}
            loop={true}
            muted="muted"
            playsInline
            webkit-playingInline
          >
            <source src={vid1} type="video/mp4" />
          </video>
        </div>
        <div className="right">
          <img src={img3} alt="" />
          <img src={img4} alt="" />
        </div>
      </VideoAndImageBlockCss>
    </FourthSectionWrapperCss>
  )
}
export default FourthSection
