import React from "react";
import {
    Section1,
    Section2
} from "../components/Projects";
import PageTransition from "../components/PageTransition";


const Projects = () => {
  return (
    <PageTransition>
      <Section1 />
      <Section2 />
    </PageTransition>
  );
};

export default Projects;