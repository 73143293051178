import styled from 'styled-components';
import { screens, colors } from '../../../constants/theme';

export const BloomdSectionWrapperCss = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 130px 0;
  background: ${colors.lightGrey2};
  overflow: hidden;

  justify-content: center;
  align-items: center;

  @media (max-width: ${screens.sm}) {
    padding: 50px 0;
  }

  @media (max-width: ${screens.lg}) {
    flex-direction: column;
    width: auto;
  }
`;

export const TitleWrapperCss = styled.div`
  display: flex;

  margin: 0 auto;
  width: 50vw;
  max-width: 648px;

  .logo svg {
    margin-top: 11px;
    margin-right: 50px;
    width: 130px;
    height: auto;
  }

  &&& h1 {
    margin-top: 0;
    color: ${colors.black};
  }

  &&& p {
    color: ${colors.grey2};
  }

  @media (max-width: ${screens.lg}) {
    width: 100%;

    p,
    h1,
    h4 {
      padding: 0px 25px;
      width: auto;
    }
  }

  @media (max-width: ${screens.md}) {
    .logo {
      display: none;
    }
  }
`;

export const ContentWrapperCss = styled.div`
  width: 80vw;
  max-width: 1300px;
  margin-top: 130px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  em {
    font-size: 15px;
    display: block;
    color: ${colors.grey};
    text-align: center;
    margin: 0;
    margin-top: 50px;
    height: 40px;
  }

  .left,
  .center,
  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .left {
    img {
      width: 100%;
    }

    img:first-child {
      margin-bottom: 30px;
    }
  }

  .center {
    img {
      width: 98%;
      height: auto;
    }
  }

  .right-images {
    display: flex;
    justify-content: space-between;

    & > * {
      width: 49%;

      &:first-child {
        margin-right: 2%;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  & > * {
    margin-right: 3%;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${screens.lg}) {
    flex-direction: column;
    align-items: center;

    & > * {
      margin-bottom: 50px;
    }
  }

  @media (max-width: ${screens.sm}) {
    margin-top: 60px;
  }
`;
