import React, { useEffect, createRef } from 'react'
import desktop from './video/desktop.mp4'
import * as css from './index.css'
import SectionLayout from '../section-layout/section-layout'

const Content = () => {
  const videoRef = createRef()

  useEffect(() => {
    /**
     * Checks if the video is in the viewport
     * @param {IntersectionObserverEntryInit[]} entries
     * @param {IntersectionObserver} observer
     */
    function intersectionCallback(entries, observer) {
      if (videoRef.current) {
        const isPlaying = !videoRef.current.paused && !videoRef.current.ended

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Play/Pause on subsequent intersections
            if (!isPlaying) {
              videoRef.current.play()
            }
          } else {
            if (isPlaying) {
              videoRef.current.pause()
            }
          }
        })
      }
    }

    let observer = new IntersectionObserver(intersectionCallback, {
      threshold: 0.4,
    })

    observer.observe(videoRef.current)
  }, [videoRef])

  return (
    <css.VideoCss
      src={desktop}
      ref={videoRef}
      autoPlay={true}
      loop={true}
      // For autoplay to work on refresh, this has to be set to true
      // Refer here for details https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
      muted={true}
    />
  )
}

const InternshipSection7 = () => {
  return (
    <SectionLayout leftTitle='Your schedule'
      rightTitle='The typical day at Kappa'
      RightComponent={Content}
    />
  )
}

export default InternshipSection7
