export const firstTechRow = [
  'HTML',
  'CSS',
  'JavaScript',
  'ReactJS',
  'TypeScript'
]

export const secondTechRow = [
  'jQuery',
  'MongoDB',
  'NodeJS',
  'NestJS',
  'MySQL',
]

export const thirdTechRow = [
  'UI/UX Design',
  'Interactions',
  'Web Design',
  'Branding',
  'Prototyping'
]