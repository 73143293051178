import React from 'react'
import cursorR from './images/cursorR.png'
import cursorY from './images/cursorY.png'
import file from './images/file.png'
import handbag from './images/handbag.png'
import handDown from './images/handDown.png'
import handRight from './images/handRight.png'
import handUp from './images/handUp.png'
import keyboard from './images/keyboard.png'

import {
  GifContainerCss,
  ImageWrapperCss,
  TextWrapperCSS,
  HandPickingFile,
  File,
  HandHoldingMug,
  Mug,
  HandHoldingKeyboard,
  KeyBoard,
  YerzCursor,
  RaluCursor,
  HandUpAnimation,
  FileAnimation,
  HandDownAnimation,
  MugAnimation,
  KeyboardAnimation,
  HandRightAnimation,
  YerzAnimation,
  RaluAnimation,
} from './index.css'
import SectionLayout from '../section-layout/section-layout'

export function useOnScreen(options) {
  const ref = React.useRef()
  const [visible, setVisible] = React.useState(false)

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options)

    if (ref.current) {
      observer.observe(ref.current)
    }

    const savedContext = ref.current;

    return () => {
      if (ref) {
        observer.unobserve(savedContext)
      }
    }
  }, [ref, options])

  return [ref, visible]
}

const Content = () => {
  const [ref, visible] = useOnScreen({ threshold: 1 })

  return (
    <>
      <TextWrapperCSS>
        <p>
          Besides coding, you’ll gain knowledge from adjacent areas
          <br /> (i.e. <span>
            design, marketing, agency and start-up life)
          </span>{' '}
          that will enable you to become a complete professional developer. Our
          curriculum activities will also help you better understand digital
          products through interaction on specialty makers platforms. You will
          establish an online identity for an in-house project that will have
          your name on it.
        </p>
      </TextWrapperCSS>

      <GifContainerCss ref={ref}>
        <ImageWrapperCss></ImageWrapperCss>

        {visible ? (
          <HandUpAnimation>
            <HandPickingFile>
              <img src={handUp} alt="hand"  />
            </HandPickingFile>
          </HandUpAnimation>
        ) : (
          <HandPickingFile>
            <img src={handUp} alt="hand" />
          </HandPickingFile>
        )}

        {visible ? (
          <FileAnimation>
            <File>
              <img src={file} alt="file" />
            </File>
          </FileAnimation>
        ) : (
          <File>
            <img src={file} alt="file" />
          </File>
        )}

        { visible ? (
          <HandDownAnimation>
            <HandHoldingMug>
              <img src={handDown} alt="hand" />
            </HandHoldingMug>
          </HandDownAnimation>
        ) : (
          <HandHoldingMug>
            <img src={handDown} alt="hand" />
          </HandHoldingMug>
        )}

        { visible ? (
          <MugAnimation>
            <Mug>
              <img src={handbag} alt="handbag" />
            </Mug>
          </MugAnimation>
        ) : (
          <Mug>
            <img src={handbag} alt="handbag" />
          </Mug>
        )}

        {visible ? (
          <HandRightAnimation>
            <HandHoldingKeyboard>
              <img src={handRight} alt="hand" />
            </HandHoldingKeyboard>
          </HandRightAnimation>
        ) : (
          <HandHoldingKeyboard>
            <img src={handRight} alt="hand" />
          </HandHoldingKeyboard>
        )}

        {visible ? (
          <KeyboardAnimation>
            <KeyBoard>
              <img src={keyboard} alt="keyboard" />
            </KeyBoard>
          </KeyboardAnimation>
        ) : (
          <KeyBoard>
            <img src={keyboard} alt="keyboard" />
          </KeyBoard>
        )}

        {visible ? (
          <YerzAnimation>
            <YerzCursor>
              <img src={cursorY} alt="Yerzhan cursor" />
            </YerzCursor>
          </YerzAnimation>
        ) : (
          <YerzCursor>
            <img src={cursorY} alt="Yerzhan cursor" />
          </YerzCursor>
        )}

        {visible ? (
          <RaluAnimation>
            <RaluCursor>
              <img src={cursorR} alt="Ralu cursor" />
            </RaluCursor>
          </RaluAnimation>
        ) : (
          <RaluCursor>
            <img src={cursorR} alt="Ralu cursor" />
          </RaluCursor>
        )}
      </GifContainerCss>
    </>
  )
}

const InternshipSection5 = () => {
  return <SectionLayout leftTitle="More than just coding" RightComponent={Content} />
}

export default InternshipSection5
