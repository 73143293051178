import styled, { css, keyframes } from 'styled-components/macro'
import { screens, colors } from '../../../constants/theme'
import pMask from './assets/p1.png';

export const Background = styled.div`
    width: 100%;
    padding: 120px 0px 70px;
    background-color: ${colors.darkGrey};

    @media screen and (max-width: ${screens.md}) {
        padding: 50px 0px 25px;
    }
`;

export const AlignContent = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px 110px 50px;

    @media screen and (max-width: ${screens.md}) {
        flex-direction: column-reverse;
        padding: 0px;
    }

    @media screen and (min-width: calc(${screens.md} + 1px)) and (max-width: ${screens.lg}) {
        padding-left: 50px;
        padding-bottom: 30px;
    }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-left: 30px;

  p,
  h3 {
    max-width: 450px;
  }

  &&& {
    p {
      margin: 0px;
      font-size: 16px;
      color: ${colors.grey4};
    }
  }

  @media screen and (max-width: ${screens.md}) {
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0px;
  }
`;

export const GifWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const MaskedGif = styled.img`
  min-width: 306px;
  mask-image: url(${pMask});
  mask-repeat: no-repeat;
  mask-position: center;
  align-self: center;

  @media screen and (max-width: ${screens.md}) {
      transform: scale(0.7, 0.7);
  }
`;

export const BannerWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin: 10px auto;
  display: flex;
`;

export const RollingBanner = styled.div`
  width: 1792.15px;
  display: inline-flex;
  flex-direction: row;
`;

export const BannerElement = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: ${colors.white};
  font-size: 20px;
  margin: 0px 20px;

  ${props => addAnimation(props)}

  span {
      color: ${colors.blue};
  }

  .symbol {
      font-style: italic;
  }
`;

export const LottieAnimation = styled.div`
  margin-right: 20px;
`;


const bannermove = keyframes`
  0% {margin-left: 20px;}
  100% {margin-left: -1772.15px;}
`


// ====== UTILS ======
const addAnimation = (props) => {
  return props.isFirst && css`
    animation: ${bannermove} 20s linear infinite;
  `
}