import styled, { css } from 'styled-components/macro'
import { screens, colors } from '../../../constants/theme'

export const FAQWrapper = styled.div`
  margin-bottom: 40px;
`

export const ToggleAccordion = styled.div`
  display: flex;
  align-items: center;
`

export const Question = styled.div`
  &&& h3 {
    margin: 0px;
    line-height: 40px;
    text-decoration: underline;
    text-decoration-color: ${colors.grey};
    text-underline-offset: 6px;
    text-decoration-thickness: 2px;
    display: inline-block;
    cursor: pointer;
    margin-left: 35px;
  }

  @media screen and (max-width: ${screens.md}) {
    &&& h3 {
      margin-left: 20px;
      font-size: 18px;
      line-height: 30px;
    }
  }
`

export const Answer = styled.div`
  max-width: 550px;
  overflow: hidden;
  height: ${(props) => (props.show ? props.childrenHeight + 30 + 'px' : '0px')};
  transition: all 0.3s;

  &&& p {
    margin-bottom: 0px;
    font-size: 16px;
  }

  @media screen and (min-width: ${screens.lg}) {
    p {
      margin-left: 77px;
    }
  }
`

export const IconCss = styled.div`
  min-width: 38px;
  min-height: 38px;
  border-radius: 50%;
  border: 2px solid ${colors.white};
  cursor: pointer;
  align-self: flex-start;

  transition: transform 0.3s;
  transform: rotate(180deg);
  position: relative;

  &::before {
    content: '';

    display: block;
    width: 18px;
    height: 0px;
    border-bottom: solid 2px ${colors.white};
    position: absolute;
    bottom: 18px;
    left: 10px;
    transform: rotate(90deg);
    transition: width 0.3s;
  }

  &::after {
    content: '';
    display: block;
    width: 18px;
    height: 0px;
    left: 10px;
    border-bottom: solid 2px ${colors.white};
    position: absolute;
    bottom: 18px;
  }

  @media screen and (max-width: ${screens.md}) {
    min-width: 26px;
    min-height: 26px;

    &::before,
		&::after {
      content: '';
      display: block;
      width: 16px;
      height: 0px;
      border-bottom: solid 2px ${colors.white};
      position: absolute;
      bottom: 12px;
      left: 5px;
    }

		&::before {
			transform: rotate(90deg);
      transition: width 0.3s;
		}
  }

  ${(props) =>
    props.show &&
    css`
      transform: rotate(0deg);

      &::before {
        content: '';
        display: block;
        width: 0px;
        height: 0px;
        border-bottom: solid 2px ${colors.white};
        position: absolute;
        left: 10px;
        bottom: 18px;
        transform: rotate(90deg);
      }
    `}
`
