import styled, { createGlobalStyle } from 'styled-components/macro'
import { screens, fonts, colors } from './constants/theme'

export const GlobalStyle = createGlobalStyle`
  /* @import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital@1&display=swap'); */
  @import url('https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;600&family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
  html {
    width: 100%;
    height: 100%;
  }
  body {
    font-family: ${fonts.primary};
    background-color:${colors.black};
	  margin:10px 0px 0px;
    width: 100%;

    &.scroll-disable{
      overflow: hidden !important;
      height: 100%;
      touch-action: none;
    }
  }
`

export const LayoutsWrapperCss = styled.div`
  min-height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (max-width: ${screens.md}) {
    min-height: 100vh;
  }

  @media (min-width: ${screens.md}) and (max-width: ${screens.lg}) {
    min-height: calc(100vh - 100px);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  li,
  nav,
  button {
    outline: none;
    border: none;
    font-family: ${fonts.primary};
  }

  h1,
  h2,
  h3,
  h5,
  h6,
  p,
  a,
  li,
  nav {
    color: ${colors.white};
  }

  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 42px;

    @media (max-width: ${screens.lg}) {
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 34px;
    }
  }

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 36px;

    @media (max-width: ${screens.lg}) {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 31px;
    }
  }

  h3 {
    font-style: italic;
    font-weight: normal;
    font-size: 28px;
    line-height: 36px;
    @media (max-width: ${screens.lg}) {
      font-style: italic;
      font-weight: normal;
      font-size: 24px;
      line-height: 31px;
    }
  }

  h4 {
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: ${colors.white};

    &.grey {
      color: ${colors.grey};
    }

    span.symbol {
      color: ${colors.blue};
    }

    @media (max-width: ${screens.lg}) {
      font-style: italic;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
    }
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;

    @media (max-width: ${screens.lg}) {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
    }
  }

  li {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.03em;
    @media (max-width: ${screens.lg}) {
      font-weight: normal;
      font-size: 16px;
    }
  }

  nav {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;

    ul {
      display: flex;
      li {
        margin: 0px 21px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
      }
    }
    @media (max-width: ${screens.lg}) {
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
    }
  }

  .numbers {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    @media (max-width: ${screens.lg}) {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
  }
`
