import styled, { css } from 'styled-components/macro'
import { colors, screens } from '../../../constants/theme'
import { OVERLAY_POSITIONS } from './card'

export const CardWrapperCss = styled.div`
  flex: 1;
  box-sizing: border-box;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  height: 200px;
  margin: 0 12.5px 25px;
  padding: 25px;
  position: relative;
  cursor: pointer;

  background: ${colors.darkGrey};

  &&& {
    h4 {
      font-style: italic;
      color: ${colors.grey4};
      white-space: normal;
      text-align: center;
    }

    :hover img {
      visibility: visible;
    }

    :hover {
      background-color: #737589;
    }

    :hover h4 {
      color: white;
      transition: all 0.5s linear;
    }
  }
`

export const CardCss = styled.div`
  box-sizing: border-box;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  height: 150px;
`

export const ImageWrapperCss = styled.div`
  height: 60px;
  text-align: center;

  img {
    width: 50%;
    height: 100%;
    visibility: hidden;
  }
`

export const ExpandCss = styled.div`
  position: absolute;
  box-sizing: border-box;
  padding: 35px 20px;
  background: ${colors.black};
  border: 2px solid ${colors.grey2};
  opacity: 0;
  pointer-events: none;
  transition: all 0.1s linear;

  ${(props) => setOverlayPosition(props)}

  img {
    visibility: visible;
  }

  @media screen and (max-width: ${screens.sm}) {
    width: 320px;
  }
`

export const TitleWrapperCss = styled.div`
  width: 150px;
  margin: 0 auto;

  h4 {
    color: ${colors.white};
  }
`

export const TextWrapperCss = styled.div`
  &&& {
    p {
      line-height: 28px;
      color: ${colors.grey4};
    }
  }
`

export const LinkWrapperCss = styled.div`
  text-align: center;
`

export const PrimaryLinkCss = styled.a`
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-decoration: none;
  width: fit-content;
  color: ${colors.white};
  display: inline-block;

  padding: 7px 7px 8px;
  margin: -7px 25px 20px 0px;

  text-align: center;

  position: relative;
  overflow: hidden;
  transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);

  &:after {
    position: absolute;
    transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    content: '';
    width: 100%;
    right: auto;
    left: 0;
    bottom: 0;
    height: 2px;
    background: ${colors.blue};
  }

  &:hover {
    cursor: pointer;
    &:after {
      width: 0%;
      right: 0;
      left: auto;
    }
  }
`

function setOverlayPosition(props) {
  // console.log('+++ props.position', props.position)
  const common = css`
    opacity: 1;
    pointer-events: all;
    width: 425px;
    height: 400px;
    z-index: 1;
  `

  if (props.isOpen) {
    switch (props.position) {
      case OVERLAY_POSITIONS.TOP_LEFT:
        return css`
          ${common}
          top: 0px;
          left: 0px;
        `

      case OVERLAY_POSITIONS.TOP_RIGHT:
        return css`
          ${common}
          top: 0px;
          right: 0px;
        `

      case OVERLAY_POSITIONS.BOTTOM_LEFT:
        return css`
          ${common}
          bottom: 0px;
          left: 0px;
        `

      case OVERLAY_POSITIONS.BOTTOM_RIGHT:
        return css`
          ${common}
          bottom: 0px;
          right: 0px;
        `

      case OVERLAY_POSITIONS.CARD_CENTERED:
      case OVERLAY_POSITIONS.PAGE_CENTERED:
        return css`
          ${common}
          top: 0px;
          right: 50%;
          transform: translateX(50%);
        `
      default:
        return common
    }
  }
}
