import React, { useEffect, useState } from 'react'
import { ParagraphCss } from './index.css'
import {
  CalendarsCss,
} from './planning.css'
import Lottie from 'react-lottie'
import calendar from './assets/calendar.json'
import { useOnScreen } from '../../Internship/section5'
import { useWindowSize } from '../../Internship/section6/section6.utils'
import { screens } from '../../../constants/theme'
import { SPACING } from './testing.css'

const Planning = () => {
  const [animateOnce, setAnimateOnce] = useState(false);
  const [ref, isVisible] = useOnScreen({ threshold: 0.9 })
  const [svgDim, setSvgDim] = useState(400);
  const { width } = useWindowSize();

  const lottieOptions = {
    loop: false,
    autoplay: false,
    animationData: calendar,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    const mobileWidth = parseInt(screens.sm);
    const spacing = parseInt(SPACING);

    if (width <= mobileWidth) {
      setSvgDim(width - spacing);
    } else {
      setSvgDim(400);
    }
  }, [width]);

  // Play the animation only once.
  useEffect(() => {
    if (isVisible) {
      setAnimateOnce(true);
    }
  }, [isVisible]);

  return (
    <>
      <ParagraphCss>
        Once we agree upon a 'look' and 'feel' for your website, we then plan
        every step of the process together: design, development, review
        sessions, and everything in between. You will be aware of the progress
        and will be involved in every major decision.
      </ParagraphCss>

      {/** Calendars */}
      <CalendarsCss ref={ref}>
        <Lottie options={lottieOptions} isPaused={!animateOnce} height={svgDim} width={svgDim} />
      </CalendarsCss>
    </>
  )
}

export default Planning
