import styled from 'styled-components'
import { colors, screens } from '../../../constants/theme'

export const ProcessWrapperCss = styled.div`
  display: block;
  width: 100%;

  margin-top: 3px;
  margin-bottom: 100px;

  @media (max-width: ${screens.avg}) {
    margin-top: 40px;
    /* margin-bottom: 0px; */
    margin-bottom: 50px;
  }
`

export const StepCss = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  right: 58px;

  @media (max-width: ${screens.xl}) {
    right: 0px;
  }
`

export const IndexCss = styled.div`
  width: 38px;
  height: 38px;

  border-radius: 50%;
  border: 2px solid ${colors.white};
  margin-right: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${colors.white};
  font-size: 18px;
  font-weight: 600;
  text-align: center;

  box-sizing: border-box;
  position: relative;

  span {
    position: absolute;
    top: 6px;
    left: 12px;
  }
`

export const TitleCss = styled.h1`
  && {
    font-style: italic;
    margin: 0px;
    padding: 0px 10px 2px;
    border-bottom: 2px solid ${colors.grey};
  }

  @media (max-width: ${screens.sm}) {
    && {
      border-bottom: none;
      padding: 0px;
      text-decoration: underline;
      text-underline-offset: 6px;
      text-decoration-color: ${colors.grey};
    }
  }
`
