import { useState } from "react";
import { send } from 'emailjs-com';

const sendMsg = async (params) => {
  return fetch('https://kappa-website-api.vercel.app/api/msg', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
}

const sendEmail = async (reply_to, message) => {
  var templateParams = {
    from_name: reply_to.substr(0, reply_to.indexOf('@')),
    reply_to,
    message,
  };
  return send("service_lunsgl1", "template_klnie9o", templateParams, "user_INDtzBlUsUZuLX8hxacDl");
}

export const useSubmit = () => {
  const [status, setStatus] = useState('idle');

  const sendBulk = async ({ email, value }) => {
    setStatus('loading');
    const phones = ['+40757756936', '+447754458495'];
    Promise.all([
      sendEmail(email, value),
      sendMsg({phone: phones[0], message: `sender: ${email} \n msg: ${value}`}),
      sendMsg({phone: phones[1], message: `sender: ${email} \n msg: ${value}`}),
    ])
    .then(function (responses) {
      setStatus('success');
    }).catch(function (error) {
      setStatus('error');
      console.log(error);
    });
    
  }

  return { status, sendBulk }
}