import styled from "styled-components";
import { screens } from "../../../constants/theme";

export const EleventhSectionWrapperCss = styled.div`

  margin-top:200px;
  h4 {
    margin-bottom: 100px;
    @media (max-width: ${screens.md}) {
      margin: 0px 0px 48px 20px;
    }
  }

  @media (max-width: ${screens.md}) {
    padding: 20px 0px 0px;
  }
`;

export const BlockCss = styled.div`
  display: flex;
  flex-direction:column;
  justify-content:center;
  margin-bottom: 105px;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;

  section {
    margin: 40px 0px;
  }

  @media (max-width: ${screens.md}) {
    // flex-direction: column-reverse;
    section {
      margin: 0px;
    }
  }
`;


export const InfoCss = styled.div`
  margin: 0 auto;
  margin-bottom:200px;

  div {
    display:flex;
    flex-direction:column;
    max-width: 648px;

    a {
      margin:0 auto;
    }

    h1 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 34px;
      text-align: center;
      color: #F5F5F5;
      margin: 40px 0px;

      @media (max-width: ${screens.md}) {
        font-size: 26px;
        line-height: 34px;
      }
    }
  }

  @media (max-width: ${screens.md}) {
    margin: 0px 25px;
    margin-bottom:200px;

    width: auto;
  }
`;

export const ImgBlockCss = styled.div`
  cursor:pointer;

  img {
      max-width: 150px;
  }

  .headlights{
      position:absolute;
      right:-40%;
      top:-5%;
      border-radius: 25px;
      height: 120%;
  }
`;
