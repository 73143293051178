import React, { useState, useRef, useEffect } from 'react'
import { motion } from 'framer-motion'
import gif1 from './images/1.gif'
import mask from './images/mask.png'
import { PrimaryLinkCss } from '../../../layout/index.css'
import SectionLayout from '../section-layout/section-layout'

import {
  Section1ContainerCss,
  FormWrapperCss,
  LabelCss,
  InputWrapperCss,
  RealInputCss,
  FakeInputCss,
  DollarSignCss,
  CursorCss,
  InputValuWrapperCss,
  LabelMarkCss,
} from './index.css'
import { useSubmit } from './api'

const Content = () => {
  const { sendBulk, status } = useSubmit()
  const [value, setValue] = useState('')
  const [email, setEmail] = useState('')
  const [msg, setMsg] = useState('')
  const [activeInput, setActiveInput] = useState('email')
  const [isEmailValid, setEmailValid] = useState(false)
  const [isMsgValid, setIsMsgValid] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [showSuccess, setShouwSucces] = useState(false)
  const [showError, setShowError] = useState(false)

  const successRef = useRef()
  const inputRef = useRef()
  const inputWrapRef = useRef()

  const handleOnChangeInput = (e) => setValue(e.target.value)

  const handleOnHitEnter = () => {
    if (activeInput === 'email') {
      setEmail(value)
      setEmailValid(true)
      setActiveInput('msg')
    } else if (activeInput === 'msg') {
      setMsg(value)
      setIsMsgValid(true)
      setActiveInput('none')
      sendBulk({ email, value })
    } else if (activeInput === 'try') {
      if (value.toLowerCase() === 'y') {
        setActiveInput('none')
        sendBulk({ email, value: msg })
      }
    }
    setValue('')
  }

  useEffect(() => {
    if (status === 'loading') {
      setShowLoading(true)
    }

    if (status === 'success') {
      setShouwSucces(true)
      if (!!successRef.current) {
        console.log('successRef.current', successRef.current)
        successRef.current.scrollIntoViewIfNeeded()
      }
    }

    if (status === 'error') {
      setShowError(true)
      setShowLoading(false)
      setActiveInput('try')
    }
  }, [status])

  const handleOnKeyDown = (e) => {
    if (!!inputRef.current) {
      let code = e.key
      let reservedKeys = [
        'Tab',
        'Escape',
        'ArrowDown',
        'ArrowUp',
        'ArrowRight',
        'ArrowLeft',
      ]

      if (activeInput === 'email') {
        reservedKeys.push(' ')
      }

      if (reservedKeys.includes(code)) {
        e.preventDefault()
      } else if (code === 'Enter') {
        handleOnHitEnter()
      } else {
        inputRef.current.focus()
      }
    }
  }

  useEffect(() => {
    if (!!inputWrapRef.current && !!inputRef.current) {
      const handleOnClickForm = (e) => {
        inputRef.current.focus()
      }

      inputWrapRef.current.addEventListener('click', handleOnClickForm)

      return () =>
        inputWrapRef.current.removeEventListener('click', handleOnClickForm)
    }
  }, [])

  return (
    <FormWrapperCss>
      <LabelCss>
        <LabelMarkCss className="default">?</LabelMarkCss>
        Enter your email address [press enter to proceed].
      </LabelCss>
      {isEmailValid && (
        <>
          <InputWrapperCss initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <DollarSignCss>~$:&nbsp;</DollarSignCss>
            <FakeInputCss>[hidden]</FakeInputCss>
          </InputWrapperCss>
          <LabelCss initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <LabelMarkCss className="default">?</LabelMarkCss>
            How can we help?
          </LabelCss>
        </>
      )}
      {isMsgValid && (
        <>
          <InputWrapperCss initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <DollarSignCss>~$:&nbsp;</DollarSignCss>
            <FakeInputCss>{msg}</FakeInputCss>
            {console.log('msg', msg)}
          </InputWrapperCss>
        </>
      )}

      {activeInput !== 'none' && (
        <InputWrapperCss ref={inputWrapRef}>
          <DollarSignCss>~$:&nbsp;</DollarSignCss>
          <InputValuWrapperCss>
            <FakeInputCss>{value}</FakeInputCss>
            <CursorCss>&nbsp;</CursorCss>
            <RealInputCss
              ref={inputRef}
              value={value}
              onChange={handleOnChangeInput}
              onKeyDown={handleOnKeyDown}
              autoFocus
            />
          </InputValuWrapperCss>
        </InputWrapperCss>
      )}

      {showLoading && (
        <LabelCss initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <LabelMarkCss className="loading">i</LabelMarkCss>
          Loading...
        </LabelCss>
      )}

      {showSuccess && (
        <LabelCss
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          ref={successRef}
        >
          <LabelMarkCss className="default">
            <svg
              width="12"
              height="9"
              viewBox="0 0 12 9"
              class="fill-current col-auto text-green-400 mt-2"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7488 0.251184C11.9096 0.412066 12 0.630239 12 0.857726C12 1.08521 11.9096 1.30339 11.7488 1.46427L4.88554 8.32754C4.72466 8.48837 4.50649 8.57872 4.279 8.57872C4.05152 8.57872 3.83334 8.48837 3.67246 8.32754L0.240825 4.8959C0.0845501 4.7341 -0.00192225 4.51739 3.24312e-05 4.29245C0.00198711 4.06751 0.0922125 3.85233 0.251276 3.69327C0.410339 3.53421 0.625513 3.44398 0.850454 3.44203C1.0754 3.44007 1.2921 3.52655 1.45391 3.68282L4.279 6.50791L10.5357 0.251184C10.6966 0.0903511 10.9148 0 11.1423 0C11.3698 0 11.5879 0.0903511 11.7488 0.251184Z"
              ></path>
            </svg>
          </LabelMarkCss>
          Success! You're on the list.
        </LabelCss>
      )}
    </FormWrapperCss>
  )
}

const InternshipSection1 = () => {
  return (
    <Section1ContainerCss>
      <SectionLayout leftTitle="Contact Us" RightComponent={Content} />
    </Section1ContainerCss>
  )
}

export default InternshipSection1
