import React from 'react'
import './svg1.scss'
const svg1 = () => {
    return (
        <>
            <svg
              version="1.1"
              className='svg1'
              id="el_NaU_-9vIv"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="100%"
              viewBox="0 0 224 182"
              style={{ enableBackground: "new 0 0 224 182.7" }}
              xmlSpace="preserve"
            >
              <g id="el_9KySO3ru1k">
                <defs>
                  <path
                    id="SVGID_1_"
                    d="M127,63.5c0,15.6-5.6,29.9-15,41c-9.4-11.1-15-25.4-15-41c0-2.3,0.1-4.6,0.4-6.9c4.5-1,9.3-1.6,14.1-1.6&#10;&#9;&#9;&#9;c5.2,0,10.3,0.6,15.2,1.8C126.9,59,127,61.2,127,63.5z"
                  />
                </defs>
                <clipPath id="SVGID_2_">
                  <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
                </clipPath>
                <g id="el_gL-DACtn_R">
                  <g id="el_6akRxkhApo">
                    <g
                      id="el_yEzVgbEnuq_an_QT_aRxAE8"
                      data-animator-group="true"
                      data-animator-type="0"
                    >
                      <polyline
                        points="128.5,97 95,97 95,91 128.5,91 128.5,85 95,85 95,79 128.5,79 128.5,73 95,73 95,67 128.5,67 &#10;&#9;&#9;&#9;&#9;128.5,61 95,61 95,55 128.5,55 128.5,49 95,49 95,43 128.5,43 128.5,37 95,37 95,31 128.5,31 &#9;&#9;&#9;"
                        id="el_yEzVgbEnuq"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="el_f_PzptpOU7">
                <path
                  d="M160.5,1C195,1,223,29,223,63.5S195,126,160.5,126S98,98,98,63.5S126,1,160.5,1 M160.5,0&#10;&#9;&#9;C125.4,0,97,28.4,97,63.5s28.4,63.5,63.5,63.5S224,98.6,224,63.5S195.6,0,160.5,0L160.5,0z"
                  id="el_cEBBkr4tF6"
                />
              </g>
              <g id="el_-b0r1jHlvo">
                <path
                  d="M63.5,1C98,1,126,29,126,63.5S98,126,63.5,126S1,98,1,63.5S29,1,63.5,1 M63.5,0C28.4,0,0,28.4,0,63.5&#10;&#9;&#9;S28.4,127,63.5,127S127,98.6,127,63.5S98.6,0,63.5,0L63.5,0z"
                  id="el_EjFbtbwS2D"
                />
              </g>
              <g id="el_-Hz3viQM2H">
                <path
                  d="M111.5,56C146,56,174,84,174,118.5S146,181,111.5,181S49,153,49,118.5S77,56,111.5,56 M111.5,55&#10;&#9;&#9;C76.4,55,48,83.4,48,118.5S76.4,182,111.5,182s63.5-28.4,63.5-63.5S146.6,55,111.5,55L111.5,55z"
                  id="el_B8m5BWzwCt"
                />
              </g>
            </svg>
        </>
    )
}

export default svg1
