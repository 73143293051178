import React from 'react'
import gif1 from './images/1.gif'
import mask from './images/mask.png'
import { PrimaryLinkCss } from '../../../layout/index.css'
import SectionLayout from '../section-layout/section-layout'

import {
  Section1ContainerCss,
  ImageWrapperCss,
} from './index.css'

const Content = () => {
  return (
    <>
      <PrimaryLinkCss to="#">Let's markup together</PrimaryLinkCss>

      <ImageWrapperCss>
        <img src={gif1} alt="gif1" />
        <img src={mask} alt="mask" />
      </ImageWrapperCss>
    </>
  )
}

const InternshipSection1 = () => {
  return (
    <Section1ContainerCss>
      <SectionLayout leftTitle="Intern with us"
        rightTitle="You are the CSS to our HTML"
        RightComponent={Content}
      />
    </Section1ContainerCss>
  )
}

export default InternshipSection1
