import React from 'react'
import {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
    Section7,
    Section8,
    Section9,
    Section10,
    Section11,
    Section12,
    Section13,
    Section14,
} from '../components/Internship'
import PageTransition from "../components/PageTransition";


const Internship = () => {
  return (
    <PageTransition>
      <Section1 />

      <Section2 />

      <Section3 />

      <Section4 />

      <Section5 />

      <Section6 />

      <Section7 />

      <Section8 />

      <Section9 />

      <Section10 />

      <Section11 />

      <Section12 />

      <Section13 />

      {/* <Section14 /> */}
    </PageTransition>
  )
}

export default Internship
