import styled, { css, keyframes } from 'styled-components'
import { colors, screens } from '../../../constants/theme'
import aMask from './assets/a2.png'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px 110px 70px;
  overflow: visible;

  @media screen and (max-width: ${screens.lg}) {
    padding: 60px 0px 40px;
  }
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;

  p,
  h3 {
    max-width: 530px;
  }

  p:first-of-type {
    margin-top: 0px;
  }

  @media screen and (max-width: ${screens.md}) {
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0px;
  }
`

export const GifWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 50px 0px;

  @media screen and (max-width: ${screens.md}) {
    margin: 30px 0px;
    transform: scale(0.7, 0.7);
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;

  @media screen and (max-width: ${screens.lg}) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0px;
  }
`

export const TipsList = styled.ul`
  margin: 0px;
  padding-inline-start: 18px;
`

export const ListItem = styled.li`
  &&& {
    color: ${colors.grey4};

    p {
      margin: 0px;
    }
  }
`

export const Paragraph = styled.p`
  &&& {
    font-size: 16px;
    color: ${colors.grey4};
  }
`

export const MakedAContainer = styled.div`
  position: relative;
  min-width: 313px;
  align-self: flex-end;
`

export const MaskedA = styled.img`
  min-width: 313px;
  mask-image: url(${aMask});
  mask-repeat: no-repeat;
  mask-position: center;
`

const bubblesAnimation = keyframes`
  from {
    transform: translate(0px, 0px);
  }

  to {
    transform: translate(45px, 24px);
  }
`

const narrowBubblesAnimation = keyframes`
  from {
    transform: scale(0.8, 0.8) translate(0px, 0px);
  }

  to {
    transform: scale(0.8, 0.8) translate(45px, 24px);
  }
`;
export const Bubbles = styled.img`
  position: absolute;
  width: 61px;
  height: 55px;
  top: -50px;
  left: -50px;

  ${(props) => setBubblesAnimation(props)}

  @media (max-width: ${screens.xs}) {
    top: -35px;
    left: -40px;
    transform: scale(0.8, 0.8);
    ${(props) => setBubblesAnimation(props, true)}
  }
`

const soapAnimation = keyframes`
  from {
    transform: translate(0px, 0px);
  }

  to {
    transform: translate(-36.5px, -25.5px);
  }
`

const narrowSoapAnimation = keyframes`
  from {
    transform: scale(0.8, 0.8) translate(0px, 0px);
  }

  to {
    transform: scale(0.8, 0.8) translate(-36.5px, -25.5px);
  }
`;
export const Soap = styled.img`
  position: absolute;
  width: 169px;
  height: 99px;
  right: -125px;
  bottom: -90px;

  ${(props) => setSoapAnimation(props)}

  @media (max-width: ${screens.xs}) {
    right: -95px;
    bottom: -65px;
    transform: scale(0.8, 0.8);
    ${(props) => setSoapAnimation(props, true)}
  }
`

// ====== UTILS ======
const setBubblesAnimation = (props, narrow) => {
  return (
    props.startAnimation &&
    css`
      animation: ${narrow ? narrowBubblesAnimation : bubblesAnimation} 1s linear;
      animation-fill-mode: forwards;
    `
  )
}

const setSoapAnimation = (props, narrow) => {
  return (
    props.startAnimation &&
    css`
      animation: ${narrow ? narrowSoapAnimation : soapAnimation} 1s linear;
      animation-fill-mode: forwards;
    `
  )
}
