import React, { useEffect, useState } from 'react'
import {
  ExecutionStepsCss,
  ExecutionWrapperCss,
  StagesCss,
  StageDiagramCss,
  StageCss,
  StageNameCss
} from './execution.css'
import arrow from './assets/arrow.svg'
import sitemap from './assets/sitemap.svg';
import wireframes from './assets/wireframes.svg';
import visualConcepts from './assets/visual-concepts.svg';
import prototypes from './assets/prototypes.svg';
import development from './assets/development.svg';
import { useOnScreen } from '../../Internship/section5'


const Execution = () => {
  const [animateOnce, setAnimateOnce] = useState(false);
  const [ref, isVisible] = useOnScreen({ threshold: 0.8 });

  useEffect(() => {
    if (isVisible && !animateOnce) {
      setAnimateOnce(true);
    }
  }, [isVisible, animateOnce]);

  const images = [
    {
      src: sitemap,
      alt: "Sitemap"
    },
    {
      src: wireframes,
      alt: "Wireframes"
    },
    {
      src: visualConcepts,
      alt: "Visual concepts"
    },
    {
      src: prototypes,
      alt: "Prototypes"
    },
    {
      src: development,
      alt: "Development"
    }
  ];

  return (
    <ExecutionWrapperCss ref={ref}>
      <ExecutionStepsCss>
        <p>Sitemap</p>
        <img src={arrow} alt="Arrow" />
        <p>Wireframes</p>
        <img src={arrow} alt="Arrow" />
        <p>Visual concepts</p>
        <img src={arrow} alt="Arrow" />
        <p>Prototypes</p>
        <img src={arrow} alt="Arrow" />
        <p>Development</p>
      </ExecutionStepsCss>

      <StagesCss >
        {
          images.map((image, index) =>
          <StageCss key={index}
            index={index}
            startAnimation={animateOnce}>

            {/* Image */}
            <StageDiagramCss>
              <img src={image.src} alt={image.alt}/>
            </StageDiagramCss>

            <StageNameCss>{image.alt}</StageNameCss>
          </StageCss>
          )
        }
      </StagesCss>
    </ExecutionWrapperCss>
  )
}

export default Execution
