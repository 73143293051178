import styled from "styled-components";
import { screens, colors } from "../../../constants/theme";

export const SecondSectionWrapperCss = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h4 {
    margin-bottom: 100px;
    @media (max-width: ${screens.md}) {
      margin: 0px 0px 48px 20px;
    }
  }

  @media (max-width: ${screens.md}) {
    padding: 20px 0px 0px;
  }

  *:not(a),
  *:not(button) {
    cursor: default !important;
  }
`;

export const BlockCss = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 105px;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;

  section {
    margin: 40px 0px;
  }

  @media (max-width: ${screens.md}) {
    // flex-direction: column-reverse;
    section {
      margin: 0px;
    }
  }
`;
export const ItemsCss = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 25px 0px 25px;
  justify-content: space-between;
  width: 100%;
  max-width: 872px;
  @media (max-width: ${screens.md}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const InfoCss = styled.div`
  margin: 0 auto;
  div {
    max-width: 648px;
    h1 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 42px;
      color: ${colors.white};
      margin-bottom: 25px;
      @media (max-width: ${screens.md}) {
        font-size: 26px;
        line-height: 34px;
      }
    }
    h2 {
      margin-bottom: 25px;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 36px;
      color: ${colors.white};
      @media (max-width: ${screens.md}) {
        font-size: 24px;
        line-height: 31px;
      }
    }
    .mB {
      margin-bottom: 100px;
    }
    p {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      color: #9b9999;
      @media (max-width: ${screens.md}) {
        font-size: 16px;
        line-height: 27px;
      }
    }
  }
  @media (max-width: ${screens.md}) {
    margin: 0px 25px;
    width: auto;

    h2 {
      margin-bottom: 0px;
      font-family: "IBM Plex Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 34px;
    }

    a {
      margin-top: 30px;
    }
  }
`;

export const ImgAndVideoCss = styled.div`
  margin-top: 40px;
  width: 100%;
  margin-right: 260px;
  cursor: pointer;
  position: relative;
  @media (max-width: ${screens.xl}) {
    display: none;
  }

  img {
    width: 100%;
  }
  .headlights {
    position: absolute;
    right: -40%;
    top: -5%;
    border-radius: 25px;
    height: 120%;
  }
`;
export const ImgAndVideoCssMobile = styled.div`
  display: none;
  width: 100%;
  cursor: pointer;
  @media (max-width: ${screens.xl}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-top: 50px;
    }
  }

  img {
    width: 100vw;
  }

  .headlights {
    margin: 0 auto;
    border-radius: 25px;
    width: 70%;
    margin-top: -20px;
  }
`;
