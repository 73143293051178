import React, { useState, useEffect } from "react";
import {
  FifthSectionWrapperCss,
  BlockCss,
  InfoCss,
  SvgBlockCss,
  Row,
  IconWrapCss
} from "./index.css";

import { LazyLoadComponent } from "react-lazy-load-image-component"

import Svg1 from "./svgs/svg1/svg1";
import Svg2 from "./svgs/svg2/svg2";
import Svg3 from "./svgs/svg3/svg3";
import Svg4 from "./svgs/svg4/svg4";
import Svg5 from "./svgs/svg5/svg5";
import Svg6 from "./svgs/svg6/svg6";
import Svg7 from "./svgs/svg7/svg7";
import Svg8 from "./svgs/svg8/svg8";

const FifthSection = () => {

  const icons = () => {
    const delays = delayGenerator();

    const comps = [
      {
        delay: delays[0],
        comp: <Svg1 />
      },
      {
        delay: delays[1],
        comp: <Svg2 />
      },
      {
        delay: delays[2],
        comp: <Svg3 />
      },
      {
        delay: delays[3],
        comp: <Svg4 />
      },
      {
        delay: delays[4],
        comp: <Svg5 />
      },
      {
        delay: delays[5],
        comp: <Svg6 />
      },
      {
        delay: delays[6],
        comp: <Svg7 />
      },
      {
        delay: delays[7],
        comp: <Svg8 />
      },
    ]

    return comps;
  }


  return (
    <FifthSectionWrapperCss>
      <BlockCss>
        <InfoCss>
          <div>
            <h1>Iconography</h1>
            <p>
              Animated minimalist icons were the go-to technique for visualising
              pin-pointed technical concepts. These further enhanced each
              section’s appeal, making abstract details intelligible and
              attractive.
            </p>
          </div>
        </InfoCss>
        <SvgBlockCss id="svgs">
          <>
            <Row>
              {
                icons().map((icon, idx) => (
                <IconWrapCss key={`key-${idx}`} className={`icon-${idx+1}`}>
                  <LazyLoadComponent>
                    <Delayed waitBeforeShow={icon.delay}>
                      {icon.comp}
                    </Delayed>
                  </LazyLoadComponent>
                </IconWrapCss>
                ))
              }
            </Row>
          </>
        </SvgBlockCss>
      </BlockCss>
    </FifthSectionWrapperCss>
  );
};

export default FifthSection;

const Delayed = ({ children, waitBeforeShow = 500 }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
  }, [waitBeforeShow]);

  return isShown ? children : null;
};

const delayGenerator = () => {
  const delays = [0, 0, 1000, 1000, 2000, 2000, 3000, 3000];
  return delays.map((value) => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value);
}
