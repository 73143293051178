import styled, { css, keyframes } from 'styled-components/macro'
import { colors, screens } from '../../../constants/theme'

export const ExecutionWrapperCss = styled.div`
  width: 90vw;
  max-width: 1096px;
  position: relative;
  left: -25vw;

  @media only screen and (max-width: ${screens.xl}) {
    left: 0px;
  }
`

export const ExecutionStepsCss = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 50px;

  p {
    width: 150px;
    text-align: center;
  }

  @media screen and (max-width: ${screens.avg}) {
    display: none;
  }
`

export const StagesCss = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  scrollbar-width: none;
  margin-top: 50px;

  ::-webkit-scrollbar {
    width: 0px;
  }
`

export const StageCss = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  margin-right: 20px;
  opacity: 0;
  ${(props) => setAnimation(props)};
`

export const StageDiagramCss = styled.div`
  max-width: 200px;
  height: 351px;
  display: flex;
  align-items: center;
`

export const StageNameCss = styled.p`
  && {
    text-align: center;
    color: ${colors.white};
    font-style: italic;
    font-size: 16px;
    margin: 0px;
    margin-top: 30px;
  }

  @media screen and (min-width: ${screens.avg}) {
    display: none;
  }
`

// ====== UTILS ======
const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`
const setAnimation = (props) => {
  return (
    props.startAnimation &&
    css`
      animation: ${fadeIn} 0.5s linear;
      animation-delay: ${0.5 * props.index}s;
      animation-fill-mode: forwards;
    `
  )
}
