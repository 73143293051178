import React from 'react'
import { PrimaryLinkCss } from '../../../layout/index.css'
import { BannerCss } from './index.css'

const Section5 = () => {
  return (
    <BannerCss>
      <h3>Lets make something interesting</h3>

      <PrimaryLinkCss
        to="/contact-us">
        Lets chat
      </PrimaryLinkCss>
    </BannerCss>
  )
}

export default Section5
