import styled from 'styled-components/macro'
import { colors, screens } from '../../../constants/theme'

export const ContentWrapperCss = styled.div`
  max-width: 645px;

  &&& {
    span {
      color: ${colors.grey4};
      font-style: italic;
    }
  }
`

export const ImageWrapperCss = styled.div`
  width: 200px;
  height: 365px;

  margin-top: 70px;

  img {
    width: 100%;
    height: 100%;
  }
`

export const NumbersCss = styled.div`
  display: flex;

  @media screen and (max-width: ${screens.sm}) {
    flex-direction: column;
  }
`

export const NumberColumnCss = styled.div`
  flex: 3;

  h1 {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &&& {
    h4 {
      margin-top: 10px;
    }
  }

`