import React from "react";
import './svg7.scss'
const svg7 = () => {
  return (
    <>
      <svg
        width="100%"
        viewBox="0 0 136 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M134.944 78.2601C126.228 84.2821 115.951 87.6432 105.284 89.4638C101.511 90.164 97.9987 90.3041 94.096 90.3041C93.9659 90.3041 94.096 90.3041 93.9659 90.3041C72.6311 90.164 52.7272 81.6212 39.0677 66.2161C34.7747 61.3145 31.0021 55.8527 27.8799 49.9707"
          stroke="#EAEAEA"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="LYhugBaC_0"
        ></path>
        <path
          d="M47.3927 28.9639C42.9696 39.7474 47.783 53.6121 57.2796 61.3146C66.3859 68.597 85.119 64.2556 91.4934 81.4813"
          stroke="#4ABAF7"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="LYhugBaC_1"
        ></path>
        <path
          d="M88.6283 99.8273C81.7335 102.628 78.221 102.768 70.4156 102.768C36.9823 102.628 3.67911 73.0784 1.72775 37.5066C1.72775 36.3863 1.59766 35.1258 1.59766 34.0055C1.72775 24.7624 5.24019 16.3596 10.9642 10.1976C11.7447 9.35728 12.6554 8.517 13.4359 7.81677C14.997 6.4163 16.5581 5.29593 18.3793 4.31561C18.5094 4.31561 18.6395 4.17556 18.7696 4.17556C19.1599 4.03551 19.4201 3.75542 19.8103 3.61537C21.8918 2.495 24.3635 1.93481 26.8352 1.93481H26.9653C34.7708 1.93481 39.454 5.99616 41.4054 14.1189C42.9665 20.561 41.4054 26.583 38.023 30.9244"
          stroke="#4ABAF7"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="LYhugBaC_2"
        ></path>
      </svg>
    </>
  );
};

export default svg7;
