import styled from 'styled-components/macro'
import { colors } from '../../../constants/theme'

export const CardWrapperCss = styled.div`
  flex: 1;
  max-width: 312px;
  min-width: 230px;
  margin: 25px 11px 0px;

  background: #0e0e0e;
`

export const ImageWrapperCss = styled.div`
  max-width: 313px;
  max-height: 313px;

  img {
    width: 100%;
    height: 100%;
  }
`

export const PrimaryLinkCss = styled.a`
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-decoration: none;
  width: fit-content;
  color: ${colors.white};
  display: inline-block;

  padding: 0;
  margin: 1px 0 22px;

  text-align: center;

  position: relative;
  overflow: hidden;
  transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);

  &:after {
    position: absolute;
    transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    content: '';
    width: 100%;
    right: auto;
    left: 0;
    bottom: 0;
    height: 2px;
    background: ${colors.blue};
  }

  &:hover {
    cursor: pointer;
    &:after {
      width: 0%;
      right: 0;
      left: auto;
    }
  }
`

export const TextWrapperCss = styled.div`
  max-width: 312px;
  padding: 0 30px 30px 23px;

  h2 {
    padding-bottom: 3px;
    margin-bottom: 0px;
    margin-top: 30px;
  }

  &&& {
    p {
      max-width: 252px;
      line-height: 26px;
      color: ${colors.grey2};
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
`