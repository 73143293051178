import styled from 'styled-components'
import { screens } from '../../../constants/theme'

export const FourthSectionWrapperCss = styled.div`
  display: block;
  width: 100%;
  margin-top: 200px;
  margin-bottom: 100px;

  @media (max-width: ${screens.xl}) {
    margin-top: 120px;
  }

  @media (max-width: ${screens.avg}) {
    margin-top: 90px;
    margin-bottom: 30px;
  }
`

export const RightSideCss = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 650px;

  position: relative;
  left: 8.33vw;

  @media (max-width: ${screens.xl}) {
    padding: 0px;
    left: 0px;
    max-width: 100%;
    align-items: flex-start;
  }

  @media (max-width: ${screens.sm}) {
    width: 100%;
    align-items: center;
  }
`

export const ParagraphCss = styled.p`
  margin-top: 30px;
`
