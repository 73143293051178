import React from 'react'
import {
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  Section6,
} from '../components/Nova';
import PageTransition from '../components/PageTransition';


const Nova = () => {
  return (
    <PageTransition>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
    </PageTransition>
  )
}

export default Nova
