import React, { useEffect, useState } from 'react'
import { PrimaryLinkCss } from '../../../layout/index.css'
import {
  FirstSectionWrapperCss,
  ContentCss,
  TitleCss,
  LinksCss,
  TextContentCss,
  GifContainer,
  MobileGifContainer,
} from './index.css'
import gif from './assets/gif.gif'
import SectionLayout from '../section-layout/section-layout'
import Container from '../../Container'

const Content = () => {
  const [index, setIndex] = useState(0)

  const fields = [
    'presentational_websites',
    'e-commerce_websites',
    'software',
    'web_applications',
    'browser_extensions',
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = (index + 1) % fields.length
      setIndex(newIndex)
    }, 1000)

    return () => clearInterval(interval)
  })
  return (
    <ContentCss>
      <TextContentCss>
        <TitleCss>We work together to deliver great digital products.</TitleCss>

        <p>
          A growing community where people are given the freedom to explore and
          learn, while taking full ownership over their work.
        </p>

        <LinksCss>
          Let's create&nbsp;&nbsp;
          <PrimaryLinkCss to="/contact-us">{fields[index]}</PrimaryLinkCss>
        </LinksCss>
      </TextContentCss>

      <GifContainer>
        <img src={gif} alt="Fist bump" />
      </GifContainer>
    </ContentCss>
  )
}

const FirstSection = () => {
  return (
    <FirstSectionWrapperCss>
      <Container>
        <MobileGifContainer>
          <img src={gif} alt="Fist bump" />
        </MobileGifContainer>
      </Container>

      <SectionLayout title="Hello world">
        <Content />
      </SectionLayout>
    </FirstSectionWrapperCss>
  )
}

export default FirstSection
