import React from 'react'
import Container from '../../Container'
import kBud1 from './images/kBud1.png'
import kBud2 from './images/kBud2.png'
import kVr from './images/kVr.png'
import aCap from './images/aCap.png'
import aBook from './images/aBook.png'

import {
  Subsec31ContainerCss,
  Subsec32ContainerCss,
  Subsec33ContainerCss,
  LeftSide31Css,
  LeftSide32Css,
  LeftSide33Css,
  RightSide31Css,
  RightSide32Css,
  ImageWrapper31Css,
  ImageWrapper32Css,
  ContentWrapperCss,
  GifContainerCss,
  AnimationVr,
  AnimationBud1,
  AnimationBud2,
  AnimationCap,
  AnimationBook,
  DownAnimation,
  UpAnimation,
} from './index.css'
import { useOnScreen } from '../section5'


const InternshipSection3 = () => {
  const [kGifRef, visible] = useOnScreen({ threshold: 0.9 })
  const [aGifRef, visible2] = useOnScreen({ threshold: 0.9 })

  return (
    <Container>
      {/* -------------------- Subsection 3.1 -------------------------- */}
      <Subsec31ContainerCss>
          <LeftSide31Css>
            <ContentWrapperCss>
              <h4>
                <span className="symbol">|</span> This is the Kappa bootcamp{' '}
                <span className="symbol">&lt;</span>
              </h4>

              <h1>You are the future</h1>
              <p>
                We know how crucial the{' '}
                <span> next generation of developers</span> is for our own
                future. That’s why we invest in tomorrow’s engineers to nurture
                capable and indispensable professionals.
              </p>
            </ContentWrapperCss>
          </LeftSide31Css>

          <RightSide31Css>
            <GifContainerCss ref={kGifRef}>
              <ImageWrapper31Css></ImageWrapper31Css>

              {visible ? (
                <UpAnimation>
                  <AnimationBud1>
                    <img src={kBud1} alt="" />
                  </AnimationBud1>
                </UpAnimation>
              ) : (
                <AnimationBud1>
                  <img src={kBud1} alt="" />
                </AnimationBud1>
              )}

              {visible ? (
                <UpAnimation>
                  <AnimationBud2>
                    <img src={kBud2} alt="" />
                  </AnimationBud2>
                </UpAnimation>
              ) : (
                <AnimationBud2>
                  <img src={kBud2} alt="" />
                </AnimationBud2>
              )}

              {visible ? (
                <DownAnimation>
                  <AnimationVr>
                    <img src={kVr} alt="" />
                  </AnimationVr>
                </DownAnimation>
              ) : (
                <AnimationVr>
                  <img src={kVr} alt="" />
                </AnimationVr>
              )}
            </GifContainerCss>
          </RightSide31Css>
      </Subsec31ContainerCss>

      {/* -------------------- Subsection 3.2 -------------------------- */}
      <Subsec32ContainerCss>
          <LeftSide32Css>
            <GifContainerCss ref={aGifRef}>
              <ImageWrapper32Css></ImageWrapper32Css>

              {visible2 ? (
                <DownAnimation>
                  <AnimationCap>
                    <img src={aCap} alt="cap" />
                  </AnimationCap>
                </DownAnimation>
              ) : (
                <AnimationCap>
                  <img src={aCap} alt="cap" />
                </AnimationCap>
              )}

              {visible2 ? (
                <UpAnimation>
                  <AnimationBook>
                    <img src={aBook} alt="cap" />
                  </AnimationBook>
                </UpAnimation>
              ) : (
                <AnimationBook>
                  <img src={aBook} alt="cap" />
                </AnimationBook>
              )}
            </GifContainerCss>
          </LeftSide32Css>

          <RightSide32Css>
            <ContentWrapperCss>
              <h1>A bootcamp for the best</h1>
              <p>
                Performance doesn’t come about lightly, but with ownership over
                your own work and motivation to be the best. We have a system
                put in place to facilitate your growth like nowhere else.
              </p>
              <p>
                Our internship program has been refined over the years and
                became <span>a fulfilling experience</span> for students or
                graduates. From training to project tasks - everything’s
                cherrypicked and revised by our senior developers to ensure
                you’re getting the most out of it.
              </p>
            </ContentWrapperCss>
          </RightSide32Css>
      </Subsec32ContainerCss>

      {/* -------------------- Subsection 3.3 -------------------------- */}
      <Subsec33ContainerCss>
          <LeftSide33Css>
            <ContentWrapperCss>
              <h1>Intense and diverse training</h1>
              <p>
                We believe in diversity as a catalyst for rapid personal and
                professional growth. A young developer should experience as many
                different challenges as possible in order to acquire multiple
                skills during a shorter period of time. You’ll be working on
                various real-life projects that will intensely
                <span> test your limits.</span>
              </p>
            </ContentWrapperCss>
          </LeftSide33Css>
      </Subsec33ContainerCss>
    </Container>
  )
}

export default InternshipSection3
