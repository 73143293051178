import React, { useState } from 'react'
import {
  FooterWrapperCss,
  FooterBlockCss,
  FooterBlockTitleCss,
  FooterBlockInfoCss,
  FooterBlockLinksCss,
  BottomFooter,
  FooterMenuCss,
  FooterMenuImage,
  FooterLogoCss,
} from './index.css'
import { PrimaryLinkCss, SecondaryLinkCss } from '../../layout/index.css'
import SubscribeModal from '../Subscribe/SubscribeModal/index'
import Book from './images/book.svg'
// import TitleLink from '../TitleLink/index';
// import friends from './images/friends.gif';

const Footer1 = () => {
  return (
    <FooterBlockCss>
      <FooterBlockTitleCss>
        <span className="symbol">| Work with us &lt;</span>
      </FooterBlockTitleCss>
      <FooterBlockInfoCss>
        <h2>
          We're always on the lookout for great people. Tell us your story,
          we’re good listeners:{' '}
        </h2>
        <FooterBlockLinksCss>
          <SecondaryLinkCss as="a" to="" href="mailto:captain@kappa.work">
            captain@kappa.work
          </SecondaryLinkCss>
          <PrimaryLinkCss to="/contact-us">Contact us</PrimaryLinkCss>
        </FooterBlockLinksCss>
      </FooterBlockInfoCss>
    </FooterBlockCss>
  )
}

const Footer2 = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <FooterBlockCss>
        <FooterBlockTitleCss>
          <span className="symbol">| Send us GIFs &lt;</span>
        </FooterBlockTitleCss>
        <FooterBlockInfoCss>
          <FooterBlockLinksCss>
            <SecondaryLinkCss as="a" to="" href="mailto:captain@kappa.work">
              captain@kappa.work
            </SecondaryLinkCss>
            <SecondaryLinkCss
              as="a"
              to="https://wa.me/447754458495"
              href="https://wa.me/447754458495"
            >
              +44 7754 45 84 95
            </SecondaryLinkCss>
            <SecondaryLinkCss
              as="a"
              to=""
              href="https://www.linkedin.com/company/kappalondon/"
            >
              LinkedIn
            </SecondaryLinkCss>
            <SecondaryLinkCss
              as="a"
              to=""
              href="https://www.facebook.com/kappalondon/"
            >
              Facebook
            </SecondaryLinkCss>
          </FooterBlockLinksCss>
          <PrimaryLinkCss onClick={() => setIsOpen(true)}>
            Subscribe to our newsletter
          </PrimaryLinkCss>
        </FooterBlockInfoCss>
      </FooterBlockCss>
      <SubscribeModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  )
}
const Footer3 = () => {
  return (
    <BottomFooter>
      <FooterLogoCss>Kappa</FooterLogoCss>
      <FooterMenuCss>
        <PrimaryLinkCss as="a" to="" href="https://kappa.work/privacy">
          Privacy Policy
        </PrimaryLinkCss>
        <FooterMenuImage to="/blog">
          <img src={Book} alt="Blog" />
        </FooterMenuImage>
      </FooterMenuCss>
    </BottomFooter>
  )
}

const Footer = () => {
  return (
    <FooterWrapperCss>
      {/* <Footer1/> */}
      <Footer2 />
      <Footer3 />
    </FooterWrapperCss>
  )
}

export default Footer
