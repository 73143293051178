import styled from 'styled-components/macro'
import { colors, screens } from '../../../constants/theme'

export const FirstSectionWrapperCss = styled.div`
  margin-top: 230px;
  margin-bottom: 130px;

  @media (max-width: ${screens.xl}) {
    margin-top: 120px;
  }

  @media (max-width: ${screens.avg}) {
    margin-top: 90px;
    margin-bottom: 30px;
  }
`

export const ContentCss = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${screens.xl}) {
    max-width: 100%;
  }
`

export const TextContentCss = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 30vw;
  padding-right: 30px;

  && p {
    color: ${colors.grey};
  }

  @media (max-width: ${screens.xl}) {
    padding: 0px;
    max-width: unset;
  }

  @media screen and (max-width: ${screens.sm}) {
    max-width: 100%;
  }
`

export const LinksCss = styled.h4`
  display: flex;
  white-space: nowrap;
  align-self: flex-start;

  @media screen and (max-width: ${screens.lg}) {
    a {
      font-size: 16px;
    }
  }
`

export const TitleCss = styled.h1`
  margin-top: 9px;
`

export const GifContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  max-width: 32.5vw;
  position: relative;
  top: -25px;

  img {
    max-width: 425px;
    object-fit: contain;
  }

  @media (max-width: ${screens.xl}) {
    max-width: 50%;

    img {
      max-width: 80%;
    }
  }

  @media (max-width: ${screens.avg}) {
    display: none;
  }
`

export const MobileGifContainer = styled.div`
  display: none;

  @media screen and (max-width: ${screens.avg}) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    max-width: 100%;

    img {
      max-width: 420px;
      object-fit: contain;
    }
  }

  @media (max-width: ${screens.xs}) {
    img {
      max-width: 100%;
    }
  }
`
