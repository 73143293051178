import styled, { keyframes } from 'styled-components/macro';
import { colors, screens } from '../../../constants/theme'
import { Link } from 'react-router-dom'

const CARD_WIDTH = '240px';

export const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 412px;
  min-height: 160px;
	width: ${CARD_WIDTH};
	background: ${colors.darkGrey};
  position: relative;
  overflow: ${props => showOverflow(props)};
`;

export const ProfileImage = styled.img`
  object-fit: cover;
	height: 60%;
  filter: grayscale(100%);
  cursor: pointer;
`;

const videoAnimation = keyframes`
  from {
    width: ${CARD_WIDTH};
    height: 60%;
  }
  to {
    width: ${2.8 * parseInt(CARD_WIDTH)}px;
    height: ${2.8 * parseInt(CARD_WIDTH) / 16 * 9}px;
  }
`;

const overlayAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const mobileVideoAnimation = keyframes`
  from {
    width: ${CARD_WIDTH};
    height: 60%;
  }
  to {
    width: calc(100vw - 30px);
    height: calc(100vw * 9 / 16 - 9 / 16 * 30px);
  }
`;

export const TestimonialVideoBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const CloseButtonCss = styled.button`
  all: unset;
  position: absolute;
  top: 35px;
  right: 20px;
  color: ${colors.white};
  
  @media (min-width: ${screens.lg}) {
    top: 20px;
    cursor: pointer;
  }
`;

export const TestimonialVideoContent = styled.div`
  background-color: ${colors.black};
  padding: 20px;
  width: 100%;
  height: 100%;
  z-index: 101;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`

export const TestimonialVideo = styled.iframe`
  height: 60%;
  transform-origin: 100%;
  animation: ${videoAnimation} 1s normal;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  outline: none;
  border: none;

  @media screen and (max-width: ${screens.md}) {
    animation: ${mobileVideoAnimation} 1s normal;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
`;

export const TestimonialVideoButton = styled.button`
  color: white;
	background-color: ${colors.grey2};
  border-radius: 2px;
  padding: 2px 5px;
  padding-right: 2px;
  margin-top: 15px;
  font-style: italic;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 73px;
  width: 73px;
  position: absolute;
  top: 46%;
  left: 20px;
  cursor: pointer;

  span {
    font-size: 14px;
  }

  img {
    max-width: 22px;
  }
`;

export const Description = styled.div`
	display: flex;
	flex: 1;
	padding: 0px 20px 20px;
	flex-direction: column;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
`;

export const Name = styled.h4`
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-decoration: none;
  width: fit-content;
  color: ${colors.white};
  display: inline-block;

  padding: 0;
  margin: 20px;

  text-align: center;

  position: relative;
  transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);

  &:after {
    position: absolute;
    transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    content: '';
    width: 100%;
    right: auto;
    left: 0;
    bottom: -2px;
    height: 2px;
    background: ${colors.blue};
  }

  &:hover {
    cursor: pointer;
    &:after {
      width: 0%;
      right: 0;
      left: auto;
    }
  }
`

export const TechnologyStack = styled.div`
	display: flex;
	flex-flow: row wrap;
`;

export const Technology = styled.span`
	color: ${colors.grey2};
	margin-right: 10px;
  font-size: 14px;
`;

export const LiveTag = styled.span`
  color: white;
	background-color: ${colors.grey2};
  border-radius: 2px;
  padding: 2px 5px;
  margin-top: 15px;
  font-style: italic;
  max-width: 35px;
  box-sizing: border-box;
`;

const easeToLeft = keyframes`
  from {
    left: 100%;
  }
  to {
    left: 0px;
  }
`;

export const TestimonyUpper = styled.div`
	height: 60%;
	width: 100%;
  background-color: #414358;
  padding: 25px 20px;
  color: ${colors.white};
  box-sizing: border-box;
  position: absolute;
  animation: ${easeToLeft} 0.4s forwards;
  display: flex;
  align-items: center;
`;

export const TestimonyQuote = styled.q`
  font-style: italic;
  text-align: center;

  &:before {
    content: '"';
  }

  &:after {
    content: '"';
  }
`;

const pushToLeft = keyframes`
  from {
    left: 0px;
  }
  to {
    left: -100%;
  }
`;


export const PushedDescription = styled.div`
  display: flex;
  flex-direction: column;
	padding: 0px 20px 20px;
  position: relative;
  animation: ${pushToLeft} 0.4s forwards;
`;

export const TestimonyLower = styled(Description)`
  width: 100%;
	background: ${colors.darkGrey};
  animation: ${easeToLeft} 0.4s forwards;
  box-sizing: border-box;
  z-index: 10;
  bottom: 0px;
  transform: translateY(-100%);
`;

export const ApplyButton = styled(Link)`
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-decoration: none;
  width: fit-content;
  color: ${colors.white};
  display: inline-block;
  position: relative;

  transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);

  &:after {
    position: absolute;
    transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    content: '';
    width: 100%;
    right: auto;
    left: 0;
    bottom: -2px;
    height: 2px;
    background: ${colors.blue};
  }

  &:hover {
    cursor: pointer;
    &:after {
      width: 0%;
      right: 0;
      left: auto;
    }
  }
`;

// ====== UTILS ======
const showOverflow = (props) => props.showOverflow ? 'visible' : 'hidden';