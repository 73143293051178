import React from "react";
import "./svg8.scss";
const svg8 = () => {
  return (
    <>
      <svg
        width="100%"
        viewBox="0 0 113 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M109.556 109.869C106.074 103.486 103.173 97.2968 102.722 93.6218C101.626 84.8536 104.978 74.409 106.332 70.2183C107.686 66.0276 111.297 57.9041 111.683 52.424C112.199 45.5899 111.297 32.2441 100.336 18.447C89.3761 4.64996 74.4185 0.588198 61.9753 1.16845C49.5321 1.7487 30.3194 10.5814 24.9682 13.934C22.8406 15.2879 10.5264 23.7982 7.10932 34.823C3.69229 45.8478 6.07776 63.4487 6.40013 64.2869C6.72249 65.125 8.07641 67.8973 8.7856 70.3473C9.4948 72.7972 7.49616 76.9235 7.10932 78.2129C6.72249 79.5024 2.46731 88.3996 1.5647 90.6561C0.662085 92.9126 1.69364 94.7823 2.14495 95.3626C2.59626 95.9428 5.43304 97.3612 6.59354 97.3612C7.75404 97.3612 9.30138 97.7481 9.88163 99.2309C10.4619 100.714 9.68822 102.519 9.4948 102.906C9.30138 103.293 9.30138 104.904 10.5264 106.129C11.7513 107.419 14.0723 108.193 14.0723 108.193C14.0723 108.193 13.0408 109.289 13.1697 111.674C13.2987 114.06 15.4907 115.027 16.9736 115.865C18.4565 116.703 18.263 116.832 18.9078 121.023C19.5525 125.213 22.5182 128.308 23.6142 128.953C24.7103 129.597 29.6746 130.371 34.7035 128.243C39.7323 126.18 46.8188 124.633 48.694 124.633C50.5691 124.633 56.1728 126.89 59.5253 133.272C61.5885 137.205 62.9424 141.138 63.7161 143.588"
          stroke="#4ABAF7"
          stroke-width="2"
          class="CqAhZOYQ_0"
        ></path>
        <path
          d="M12 68.5L14.5 69.5714M14.5 69.5714L23.5 73L17.5 77.5C16.1667 76.381 13.7 73.2286 14.5 69.5714Z"
          stroke="#EAEAEA"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="CqAhZOYQ_1"
        ></path>
        <path
          d="M11 61.5001C12.3333 61.0001 15.5 60.1001 17.5 60.5001C20 61.0001 21.5 61.5 28.5 67.5"
          stroke="#4ABAF7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="CqAhZOYQ_2"
        ></path>
        <path
          d="M59.5 65.5C60.3333 63.3334 62.9 58.9 66.5 58.5C71 58 74 59.5 76 62.5C78 65.5 79.5004 68.5002 76.5 77C74.0289 84 73.5 81.5 68.5 88.5C65.6428 92.5 58.5002 88 59.5 84"
          stroke="#4ABAF7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="CqAhZOYQ_3"
        ></path>
      </svg>
    </>
  );
};

export default svg8;
