import styled from 'styled-components'
import { screens, colors } from '../../../constants/theme'

export const BenefitsSectionWrapperCss = styled.div`
  display: flex;
  padding-left: 8.33vw;
  padding-top: 200px;
  margin-bottom: 54px;

  align-items: flex-start;

  h4 {
    width: 16.66vw;
    margin-right: 8.33vw;
    @media (max-width: ${screens.lg}) {
      width: 100%;
      margin-right: 0px;
    }
  }

  @media (max-width: ${screens.lg}) {
    flex-direction: column;
    padding: 50px 25px 25px;
  }
`

export const InfoBlockCss = styled.div`
  width: 50vw;
  @media (max-width: ${screens.lg}) {
    width: 100%;
  }

  p {
    span {
      border-bottom: 2px solid ${colors.blue};
    }
  }

  ul {
		padding: 0px;
		list-style-position: inside;

		li {
			list-style: none;
			position: relative;
			padding-left: 20px;
			&:before {
				color: ${colors.grey};
				content: '■';
				position: absolute;
				left: 0px;
				top: 0px;
				font-size: 8px;
			}
		}
	}
`

export const GifContainer = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }

  @media (min-width: ${screens.xl}) {
    max-width: 50%;

    img {
      max-width: 80%;
    }
  }
`