import React from "react";
import {
  SixthSectionWrapperCss,
  BlockCss,
  InfoCss,
  VideoBlockCss,
} from "./index.css";
import vid1 from "./assets/vid1.mp4";
import vid2 from "./assets/vid2.mp4";

const SixthSection = () => {
  return (
    <SixthSectionWrapperCss>
      <BlockCss>
        <InfoCss>
          <div>
            <h1>Exquisite UI Animations</h1>
            <p className="mB">
              Each interaction was made from scratch. From the tiniest element
              to the larger and more complex visuals, every component was
              thought and created with constant reference to industry
              technicalities and standards.
            </p>
          </div>
        </InfoCss>
        <VideoBlockCss>
          <video
            className="vid1"
            controls={false}
            preload="auto"
            autoPlay={true}
            loop={true}
            muted="muted"
            playsInline
            webkit-playingInline
          >
            <source src={vid1} type="video/mp4" />
          </video>
          <video
            className="vid2"
            controls={false}
            preload="auto"
            autoPlay={true}
            loop={true}
            muted="muted"
            playsInline
            webkit-playingInline
          >
            <source src={vid2} type="video/mp4" />
          </video>
        </VideoBlockCss>
      </BlockCss>
    </SixthSectionWrapperCss>
  );
};

export default SixthSection;
