import React from "react";
import BlogSection from "../components/Blog";
import PageTransition from '../components/PageTransition'

const Blog = () => {
  return(
    <PageTransition>
      <BlogSection />
    </PageTransition>
  ) 
};

export default Blog;
