import React from 'react';
import {
  FirstSectionWrapperCss,
  BryantExtraCss,
  AcknowledgementsCss,
} from './index.css';
import img1 from './images/image1.png';
import img2 from './images/image2.png';
import bloomd from './images/bloomd.png';
import ProjectBlock from './project-block';

const BryantExtra = () => {
  return (
    <BryantExtraCss>
			<p>Acknowledgements:</p>

			<AcknowledgementsCss>
				<li>
					2019 Dental Industry Awards <span>Best Website</span>
				</li>
				<li>
					2018 Awwwards Honorable <span>Mention</span>
				</li>
			</AcknowledgementsCss>
		</BryantExtraCss>
  );
}

const SecondSection = () => {
  const projects = [
    {
      title: 'Bryant Dental',
      description: 'Bryant Dental is a UK-based dentistry equipment manufacturer and distributor.',
      image: img1,
      imgAlt: 'Bryant Dental',
      link: '/bryant-dental',
      extra: <BryantExtra />,
    },
    {
      title: 'Nova AI',
      description: 'Nova is a powerful AI assisted dental practice management and counter litigation system.',
      image: img2,
      imgAlt: 'Nova AI',
      link: '/nova',
    },
    {
      title: 'Bloomd',
      description: 'Bloomd helps senior citizens connect with the youth through positive life experience discussions.',
      image: bloomd,
      imgAlt: 'bloomd',
      link: '/bloomd',
    }
  ]

	return (
		<FirstSectionWrapperCss>
			<h4>
				<span className="symbol">|</span> Currently working on{' '}
				<span className="symbol">&lt;</span>
			</h4>

      {/** Projects */}
      {
        projects.map((project, key) =>
          <ProjectBlock key={key} {...project} />
        )
      }
		</FirstSectionWrapperCss>
	);
};
export default SecondSection;
