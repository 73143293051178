import styled from "styled-components";
import { screens } from "../../../constants/theme";

export const EighthSectionWrapperCss = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top:112px;
  @media (max-width: ${screens.md}) {}
  h4 {
    margin-bottom: 100px;
    @media (max-width: ${screens.md}) {
      margin: 0px 0px 48px 20px;
    }
  }

  @media (max-width: ${screens.md}) {
    padding: 20px 0px 0px;
  }
`;

export const BlockCss = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 105px;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;

  section {
    margin: 40px 0px;
  }


`;

export const InfoCss = styled.div`
  margin: 0 auto;
  div {
    max-width: 648px;
    h1 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 42px;
      color: #f5f5f5;
      margin:0;
      margin-bottom: 25px;
      @media (max-width: ${screens.md}) {
        font-size: 26px;
        line-height: 34px;
      }
    }
    h2 {
      margin-bottom: 25px;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 36px;
      color: #f5f5f5;
      @media (max-width: ${screens.md}) {
        font-size: 24px;
        line-height: 31px;
      }
    }
    .mB {
      margin-bottom: 100px;
    }
    p {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      color: #9b9999;
      @media (max-width: ${screens.md}) {
        font-size: 16px;
        line-height: 27px;
      }
    }
  }
  @media (max-width: ${screens.md}) {
    margin: 0px 25px;
    width: auto;

    h2 {
      margin-bottom: 0px;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 34px;
    }

    a {
      margin-top: 30px;
    }
  }
`;

export const ImgAndVideoCss = styled.div`
  width: 80%;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  @media (max-width: ${screens.xl}) {
      display: none;
  }

  video {
    border-radius:25px;
    width: 100%;
  }
  .headlights{
    position: absolute;
    right: -20%;
    bottom: -40%;
    border-radius: 25px;
    height: 120%;
  }
`;
export const ImgAndVideoCssMobile = styled.div`
  display: none;
  width: 100%;
  cursor: pointer;
  position:relative;
  overflow:hidden;
  @media (max-width: ${screens.xl}) {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    video {
      position: sticky;
      width: 158%;
      margin-top: 50px;
    }
  }

  img {
    width: 100%;
  }

  .headlights {
    margin: 0 auto;
    border-radius: 25px;
    width: 70%;
    margin-top: 50px;
  }
`;
