import React from "react";
import CardPost from './card/'
import { CardsWrapperCss, LinkCss, CardsGridCss, CardItemCss, LoadingWrapperCss, DualRingCss } from "./index.css";
import { useBlogs } from "../../utils/useBlogs"

const BlogComponent = () => {
  const { posts, status } = useBlogs();  

  if(status === 'loading' ){
    return (
      <LoadingWrapperCss>
        <DualRingCss />
      </LoadingWrapperCss>
    )
  }

  return (
    <CardsWrapperCss>
      <CardsGridCss>
        {posts.map(({ title, thumbnail, author, guid, description }, idx) => {
          
          const excerpt = description.replace(/<\/?[^>]+(>|$)/g, "").substr(0, 150);
          guid = guid.substr(guid.indexOf('p/')+2);
          
          return (
            <CardItemCss key={`${guid}-${idx}`}>
              <LinkCss to={`/blog/${guid}`}>
                <CardPost 
                  title={title}
                  author={author}
                  image={thumbnail}
                  description={`${excerpt}...`}
                  />
              </LinkCss>
            </CardItemCss>
          );
        })}
      </CardsGridCss>
    </CardsWrapperCss>
  );
};

export default BlogComponent;
