import React from 'react'

import {
  ThirdSectionWrapperCss,
  BlockCss,
  StickyContent,
  SliderImage,
  SliderImageMain,
  SliderImageInfo,
  SlideImageItem,
  SliderImageContent,
} from './index.css'
import image1 from './assets/image1.png'
import image1x2 from './assets/image1@2x.png'
import image2 from './assets/image2.png'
import image2x2 from './assets/image2@2x.png'
import image3 from './assets/image3.png'
import image3x2 from './assets/image3@2x.png'
import image4 from './assets/image4.png'
import image4x2 from './assets/image4@2x.png'
import image5 from './assets/image5.png'
import image5x2 from './assets/image5@2x.png'
import useWindowWidth from '../../../utils/useWindowWidth'
import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

const ThirdSection = () => {
  const width = useWindowWidth()
  const [swiper, setSwiper] = useState(null)

  const slideTo = (index) => {
    if (swiper) {
      swiper.slideToLoop(index)
    }
  }

  useEffect(() => {
    if (width > 1250) {
      window.addEventListener('scroll', handleScroll)
    } else {
      window.addEventListener('scroll', handleScrollMobile)
    }
  }, [width])

  const handleScroll = () => {
    let element = document.getElementById('scroll')
    if (element) {
      let rect = element.getBoundingClientRect()
      if (rect.top <= 0 && rect.top >= -200) {
        slideTo(0)
      } else if (rect.top <= -200 && rect.top >= -400) {
        slideTo(1)
      } else if (rect.top <= -600 && rect.top >= -800) {
        slideTo(2)
      } else if (rect.top <= -1000 && rect.top >= -1200) {
        slideTo(3)
      } else if (rect.top <= -1200 && rect.top >= -1400) {
        slideTo(4)
      }
    }
  }
  const handleScrollMobile = () => {
    let element = document.getElementById('scroll')

    if (element) {
      let rect = element.getBoundingClientRect()
      if (rect.top <= 0 && rect.top >= -200) {
        slideTo(0)
      } else if (rect.top <= -200 && rect.top >= -400) {
        slideTo(1)
      } else if (rect.top <= -600 && rect.top >= -800) {
        slideTo(2)
      } else if (rect.top <= -1000 && rect.top >= -1200) {
        slideTo(3)
      } else if (rect.top <= -1200 && rect.top >= -1400) {
        slideTo(4)
      }
    }
  }
  return (
    <ThirdSectionWrapperCss>
      <BlockCss id="scroll">
        <StickyContent>
          <div className="sticky">
            <h1>Long-Term Partnership</h1>
            <p className="mB">
              Bryant Dental expanded their product line throughout our
              collaboration. Each product required a distinct approach, but the
              design was tailored to fit the website’s style.
            </p>
          </div>
          <SliderImage>
            <SliderImageMain>
              <SliderImageContent>
                <Swiper
                  centeredSlides={true}
                  loop={true}
                  initialSlide={0}
                  onSwiper={setSwiper}
                  breakpoints={{
                    0: {
                      slidesPerView: 2,
                    },
                    1270: {
                      slidesPerView: 3.6,
                    },
                  }}
                >
                  <SwiperSlide>
                    {({ isActive }) => (
                      <SlideImageItem onClick={() => slideTo(0)}>
                        <div
                          className={`background ${isActive ? 'active' : ''}`}
                        >
                          <img
                            src={image4}
                            srcSet={`${image4} 1x, ${image4x2} 2x`}
                            alt=""
                          />
                        </div>
                        {isActive && (
                          <SliderImageInfo>
                            <h4>Dental Loupes</h4>
                          </SliderImageInfo>
                        )}
                      </SlideImageItem>
                    )}
                  </SwiperSlide>
                  <SwiperSlide>
                    {({ isActive }) => (
                      <SlideImageItem onClick={() => slideTo(1)}>
                        <div
                          className={`background ${isActive ? 'active' : ''}`}
                        >
                          <img
                            src={image5}
                            srcSet={`${image5} 1x, ${image5x2} 2x`}
                            alt=""
                          />
                        </div>
                        {isActive && (
                          <SliderImageInfo>
                            <h4>OZMO Dental Reverse Osmosis</h4>
                          </SliderImageInfo>
                        )}
                      </SlideImageItem>
                    )}
                  </SwiperSlide>
                  <SwiperSlide>
                    {({ isActive }) => (
                      <SlideImageItem onClick={() => slideTo(2)}>
                        <div
                          className={`background ${isActive ? 'active' : ''}`}
                        >
                          <img
                            src={image1}
                            srcSet={`${image1} 1x, ${image1x2} 2x`}
                            alt=""
                          />
                        </div>
                        {isActive && (
                          <SliderImageInfo>
                            <h4>iShields</h4>
                          </SliderImageInfo>
                        )}
                      </SlideImageItem>
                    )}
                  </SwiperSlide>
                  <SwiperSlide>
                    {({ isActive }) => (
                      <SlideImageItem onClick={() => slideTo(3)}>
                        <div
                          className={`background ${isActive ? 'active' : ''}`}
                        >
                          <img
                            src={image2}
                            srcSet={`${image2} 1x, ${image2x2} 2x`}
                            alt=""
                          />
                        </div>
                        {isActive && (
                          <SliderImageInfo>
                            <h4>C-Flo SmileFast Edition</h4>
                          </SliderImageInfo>
                        )}
                      </SlideImageItem>
                    )}
                  </SwiperSlide>
                  <SwiperSlide>
                    {({ isActive }) => (
                      <SlideImageItem onClick={() => slideTo(4)}>
                        <div
                          className={`background ${isActive ? 'active' : ''}`}
                        >
                          <img
                            src={image3}
                            srcSet={`${image3} 1x, ${image3x2} 2x`}
                            alt=""
                          />
                        </div>
                        {isActive && (
                          <SliderImageInfo>
                            <h4>C-Flo Material Heater</h4>
                          </SliderImageInfo>
                        )}
                      </SlideImageItem>
                    )}
                  </SwiperSlide>
                </Swiper>
              </SliderImageContent>
            </SliderImageMain>
          </SliderImage>
        </StickyContent>
      </BlockCss>
    </ThirdSectionWrapperCss>
  )
}

export default ThirdSection
