import styled, { keyframes } from 'styled-components/macro'
import { colors, screens } from '../../../constants/theme'
import kGif from './images/k.gif'
import kMask from './images/k.png'
import aGif from './images/a.gif'
import aMask from './images/a.png'

const downAnimation = keyframes`
  from {
    transform: translateY(-240px);
  }

  to {
    transform: translateY(-205px);
  }
`

export const DownAnimation = styled.div`
  animation: ${downAnimation} 1.5s forwards;
`

const upAnimation = keyframes`
  from{
    transform: translateY(-300px);
  }

  to{
    transform: translateY(-372px);
  }
`

export const UpAnimation = styled.div`
  animation: ${upAnimation} 1.9s forwards;
`

const Subsection = styled.div`
  display: flex;
  padding: 80px 0 50px;

  @media (max-width: ${screens.lg}) {
    padding: 30px 0;
  }
`

// -------------------  3.1 Subsection of the Section 3  ---------------------

export const Subsec31ContainerCss = styled(Subsection)`
  @media (max-width: ${screens.lg}) {
    flex-direction: column;
  }
`

export const LeftSide31Css = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 3;
  padding-left: 110px;
  padding-right: 15px;

  @media (max-width: ${screens.lg}) {
    padding-left: 0;
  }
`

export const ContentWrapperCss = styled.div`
  max-width: 535px;

  &&& {
    p {
      line-height: 32px;
      color: ${colors.grey4};
    }

    p span {
      line-height: 23.4px;
      font-style: italic;
      color: white;
    }
  }
`

export const RightSide31Css = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 110px;

  @media (max-width: ${screens.xl}) and (min-width: ${screens.lg}) {
    padding-right: 0px;
  }

  @media screen and (max-width: ${screens.lg}) {
    justify-content: flex-start;
    padding: 50px 15px 20px 110px;
  }

  @media (max-width: ${screens.md}) {
    padding: 0px;
    justify-content: center;
  }
`

export const GifContainerCss = styled.div`
  position: relative;

  @media screen and (max-width: ${screens.md}) {
    margin: 50px 0px 10px;
  }

  @media (max-width: ${screens.sm}) {
    margin: 0px;
    transform: scale(0.7, 0.7);
  }
`

export const ImageWrapper31Css = styled.div`
  width: 312px;
  height: 275px;
  position: relative;

  background-image: url(${kGif});
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;

  mask-image: url(${kMask});
  mask-repeat: no-repeat;
  mask-position: center;
`

export const AnimationVr = styled.div`
  position: absolute;
  top: -80px;
  left: 155px;
  transform: scale(0.7);
`

export const AnimationBud1 = styled.div`
  position: absolute;
  top: 250px;
  left: -85px;
  transform: scale(0.7);
`

export const AnimationBud2 = styled.div`
  position: absolute;
  top: 250px;
  left: 230px;
  transform: scale(0.7);
`

// -------------------  3.2 Subsection of the Section 3  ---------------------

export const Subsec32ContainerCss = styled(Subsection)`
  @media (max-width: ${screens.lg}) {
    flex-direction: column-reverse;
  }
`

export const LeftSide32Css = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding: 70px 15px 0px 110px;

  @media screen and (max-width: ${screens.md}) {
    padding: 0px;
    justify-content: center;
  }
`

export const RightSide32Css = styled.div`
  flex: 1;

  p {
    line-height: 32px;
    color: ${colors.grey4};
  }

  p span {
    line-height: 23.4px;
    font-style: italic;
    color: white;
  }
`

export const ImageWrapper32Css = styled.div`
  width: 312px;
  height: 275px;
  position: relative;

  background-image: url(${aGif});
  background-size: content;
  background-position: left;
  background-repeat: no-repeat;

  mask-image: url(${aMask});
  mask-repeat: no-repeat;
  mask-position: center;
`

export const AnimationCap = styled.div`
  position: absolute;
  top: -135px;
  left: -60px;
  transform: scale(0.7);
`

export const AnimationBook = styled.div`
  position: absolute;
  top: 315px;
  left: 265px;
  transform: scale(0.7);
`

// -------------------  3.3 Subsection of the Section 3  ---------------------
export const Subsec33ContainerCss = styled(Subsection)`
  display: block;
`

export const LeftSide33Css = styled.div`
  padding-left: 230px;

  p {
    line-height: 32px;
    color: ${colors.grey4};
  }

  p span {
    line-height: 23.4px;
    font-style: italic;
    color: white;
  }

  @media (max-width: ${screens.lg}) {
    padding-left: 0;
  }
`
