export const colors = {
    white: '#F5F5F5',
    black: '#0E0E0E',
    brown: '#CB8A7C',
    lightGrey1: '#E5E5E5',
    lightGrey2: '#EFEFEF',
    grey: '#9B9999',
    grey2: '#616A76',
    grey3: '#737589',
    grey4: '#9694A7',
    blue: '#7481F4',
    darkGrey: '#1F1F25',
}

export const screens = {
    xs: '478px',
    sm: '575px',
    md: '767px',
    avg: '860px',
    lg: '991px',
    xl: '1270px',
}

export const fonts = {
    primary: 'IBM Plex Sans',
}
