import React from 'react'
import BookVladSection from '../components/BookVlad'
import PageTransition from '../components/PageTransition'

const BookVlad = () => {
  return (
    <PageTransition>
      <BookVladSection />;
    </PageTransition>
  )
}

export default BookVlad
