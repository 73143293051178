import React, { Fragment, useEffect, useState } from 'react'
import {
  JoinUsFirstSectionWrapperCss,
  StoriesBlockOuterCss,
  StoriesBlockCss,
  StoriesInnerCss,
  StoriesItemCss,
  StoriesIndicators,
  StoriesIndicatorItem,
  NavigationBlockCss,
  StoriesTriggerCss,
  StoriesWrapperMobile,
  MobileNavigationBlockCss
} from './index.css'
import useWindowWidth from '../../../utils/useWindowWidth'
import { screens } from '../../../constants/theme'
import { PrimaryLinkCss } from '../../../layout/index.css'
import { AnimatePresence } from 'framer-motion'
import CarouselOpening from './assets/carousel-1.svg'
import CarouselAligned from './assets/carousel-aligned.svg'
import CarouselBe from './assets/carousel-be.svg'
import CarouselFe from './assets/carousel-fe.svg'
import CarouselNeedToKnow from './assets/carousel-need-to-know.svg'
import CarouselReady from './assets/carousel-ready.svg'
import CarouselTraining from './assets/carousel-training.svg'
import CarouselYouAlsoNeed from './assets/carousel-you-also-need.svg'
import CarouselReadyCover from './assets/carousel-ready.webp'
import CarouselAlignedCover from './assets/carousel-aligned.webp'
import MushroomIcon from './assets/mushroom.svg'
import ArrowBtn from './assets/arrowUp.svg'

const storiesData = [
  { img: CarouselOpening },
  { img: CarouselFe, applyLink: true },
  { img: CarouselBe, applyLink: true },
  { img: CarouselAligned, gif: CarouselAlignedCover, applyLink: true },
  { img: CarouselTraining },
  { img: CarouselNeedToKnow, applyLink: true },
  { img: CarouselYouAlsoNeed, applyLink: true },
  { img: CarouselReady, gif: CarouselReadyCover, applyLink: true },
]

const StoriesCarouselSection = () => {
  const [openMobileStory, setOpenMobileStory] = useState(false)
  const screenWidth = useWindowWidth()
  const isLarge = screenWidth > Number(screens.lg.substring(0, 3))

  const renderStoriesOnMobile = () => {
    return (
      <Fragment>
        <StoriesTrigger
          count={storiesData.length}
          onClick={() => setOpenMobileStory(true)}
        />
        <AnimatePresence exitBeforeEnter>
          {openMobileStory && (
            <StoriesWrapperMobile
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Stories isLarge={isLarge} closeStories={() => setOpenMobileStory(false)} screenWidth={screenWidth} />
            </StoriesWrapperMobile>
          )}
        </AnimatePresence>
      </Fragment>
    )
  }

  return (
    <JoinUsFirstSectionWrapperCss>
      {isLarge ? (
        <Stories isLarge={isLarge} screenWidth={screenWidth} />
      ) : (
        renderStoriesOnMobile()
      )}
    </JoinUsFirstSectionWrapperCss>
  )
}

const Stories = ({ isLarge, closeStories, screenWidth }) => {
  const vw = Math.max(screenWidth || 0, window.innerWidth || 0)
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

  const ITEM_HEIGHT = vh - 300;
  const ITEM_WIDTH = Math.round(isLarge ? (55/100) * ITEM_HEIGHT : vw)
  document.documentElement.style.setProperty("--story-w", ITEM_WIDTH + "px");
  document.documentElement.style.setProperty("--story-h", ITEM_HEIGHT + "px");
  document.documentElement.style.setProperty("--story-nav-w", (ITEM_WIDTH + 230) + "px");

  const ITEM_GAP = isLarge ? 35 : 0
  const ITEMS_COUNT = storiesData.length
  const CAROUSEL_WIDTH = ITEM_WIDTH * ITEMS_COUNT + ITEM_GAP * (ITEMS_COUNT - 1)

  const [active, setActive] = useState(0)
  const [xPos, setXpos] = useState(active * ITEM_WIDTH);
  const scaleSize = isLarge ? 1.35 : 1;

  const setOnNext = () => {
    if (active < ITEMS_COUNT - 1) {
      const nextActive = active + 1
      const nextXPos = nextActive * (ITEM_GAP + ITEM_WIDTH)
      setActive(nextActive)
      setXpos(-nextXPos)
    } else if (!isLarge) {
      closeStories();
    }
  }

  const setOnPrev = () => {
    // console.log({ active })
    if (active > 0) {
      const nextActive = active - 1
      const nextXPos = nextActive * (ITEM_GAP + ITEM_WIDTH)
      setActive(nextActive)
      setXpos(-nextXPos)
    } else if (!isLarge) {
      closeStories();
    }
  }

  const setActiveByIndex = (index) => {
    const nextXPos = index * (ITEM_GAP + ITEM_WIDTH)
    setActive(index)
    setXpos(-nextXPos)
  }

  const sendEmail = (ev, title) => {
    ev.preventDefault() // Prevent redirection to homepage
    let email = 'captain@kappa.work'
    let subject = 'Job Application for ' + title
    document.location = 'mailto:' + email + '?subject=' + subject
  }

  const onDrag = (e, { offset }) => {
    const threshold = 3;
 
    if (offset.x < -threshold) {
      setOnNext()
    } else if (offset.x > threshold) {
      setOnPrev()
    }
    
  }

  useEffect(() => {
    if(!isLarge){
      window.document.body.classList.add("scroll-disable");

      return () => {
        window.document.body.classList.remove("scroll-disable")
      }
    }
  }, [isLarge])
 
  return (
    <StoriesBlockOuterCss>
      <StoriesBlockCss>
        <Indicators onClick={setActiveByIndex} active={active} />
        {isLarge && (
          <Navigation
            onNext={setOnNext}
            onPrev={setOnPrev}
            active={active}
            length={ITEMS_COUNT}
          />
        )}
        <AnimatePresence>
          <StoriesInnerCss
            style={{ width: CAROUSEL_WIDTH }}
            initial={{ x: xPos }}
            animate={{ x: xPos }}
            key={isLarge}
            drag={!isLarge ? "x" : false}
            dragDirectionLock
            onDragEnd={onDrag}
          >
            {storiesData.map((item, idx) => {
              return (
                <StoriesItemCss
                  initial={{
                    scale: 1,
                    origin: 'center',
                    opacity: 0.3,
                    zIndex: 1,
                  }}
                  animate={{
                    scale: idx === active ? scaleSize : 1,
                    opacity: idx === active ? 1 : 0.3,
                    zIndex: idx === active ? 2 : 1,
                  }}
                  key={idx}
                >
                  <img src={item.img} alt="" className="content" />
                  {!!item.gif && (
                    <>
                      <img src={item.gif} alt="cover" className="gif-cover" />
                      <div className="gradient-bg" />
                    </>
                  )}
                  {!!item.applyLink && (
                    <footer className="footer">
                      <PrimaryLinkCss
                        href="mailto:captain@kappa.work"
                        onClick={(ev) => sendEmail(ev, 'Developer')}
                      >
                        Apply Now
                      </PrimaryLinkCss>
                    </footer>
                  )}
                  {
                    !isLarge && (
                      <MobileNavigation 
                        onNext={setOnNext}
                        onPrev={setOnPrev}
                      />
                    )
                  }
                </StoriesItemCss>
              )
            })}
          </StoriesInnerCss>
        </AnimatePresence>
      </StoriesBlockCss>
    </StoriesBlockOuterCss>
  )
}

const Indicators = ({ onClick, active }) => {
  return (
    <StoriesIndicators>
      {storiesData.map((item, idx) => (
        <span key={idx} onClick={() => onClick(idx)}>
          <StoriesIndicatorItem
            key={idx}
            initial={{ backgroundColor: '#52515A' }}
            animate={{
              backgroundColor: idx === active ? '#C7C7C7' : '#52515A',
            }}
          />
        </span>
      ))}
    </StoriesIndicators>
  )
}

const Navigation = ({ onNext, onPrev, active, length }) => {
  return (
    <NavigationBlockCss>
      <div>
        {active > 0 && (
          <button onClick={onPrev} className="prev">
            <img src={ArrowBtn} alt="prev" />
          </button>
        )}
        {active < length - 1 && (
          <button onClick={onNext} className="next">
            <img src={ArrowBtn} alt="next" />
          </button>
        )}
      </div>
    </NavigationBlockCss>
  )
}

const StoriesTrigger = ({ count, onClick }) => {
  return (
    <StoriesTriggerCss>
      <div className="outer" onClick={onClick}>
        <span className="count">{count}</span>
        <div className="outline">
          <img src={MushroomIcon} alt={`${count} vacancy open`} />
        </div>
      </div>
    </StoriesTriggerCss>
  )
}

const MobileNavigation = ({ onNext, onPrev }) => {

  return (
    <MobileNavigationBlockCss>
      <button onClick={onPrev}/>
      <button onClick={onNext}/>
    </MobileNavigationBlockCss>
  )
}

export default StoriesCarouselSection
