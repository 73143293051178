export const FAQs = [
  {
    id: 1,
    question: 'How long does the internship last?',
    answer: `The internship program spans over the course of six months.
      It starts with a few weeks of training after which you’ll start working
      on real life projects straight away.`,
  },
  {
    id: 2,
    question: 'Will I work on one single or multiple areas?',
    answer: `You will get the chance to experience multiple areas and projects throughout your internship.`
  },
  {
    id: 3,
    question: 'Is the schedule flexible',
    answer: `You are encouraged to work 9-6-8 in order to efficiently interact with the other team members, but we are flexible with regards to your schedule only at a daily level (e.g. rescheduling missed days to the weekend).`
  },
  {
    id: 4,
    question: 'How do I apply?',
    answer: `Send us an email at captain@kappa.work containing your resume and cover letter and we’ll get back to you. If we don't reply straight away, please don't take it personally as the amount of applicants is overwhelming sometimes.`
  },
  {
    id: 5,
    question: 'When should I apply?',
    answer: `Our internship periods are October - March and April - September. Please submit your applications during the months of September (for the winter semester) or March (for the summer semester). For the rest of the year, feel free to get in touch - we love getting coffee with interesting people.`
  },
  {
    id: 6,
    question: 'Will I be hired after my internship?',
    answer: `If things run smooth and the future’s bright, we’ll definitely want you to join our team on a full-time junior developer position. Alternatively, we could speak with our partner agencies and startups if you really enjoyed working with them.`
  },
  {
    id: 7,
    question: 'Who can apply?',
    answer: `If you’re a recent graduate holding a Computer Science (or other strictly engineering-related subjects) degree, you’re eligible to apply. `
  },
  {
    id: 8,
    question: 'I need more help',
    answer: `Any unanswered questions or lifelong dilemmas? Shoot us an email at captain@kappa.work and let’s talk about it :)`
  },
  {
    id: 9,
    question: 'What the hell?',
    answer: `I know...`
  }
]