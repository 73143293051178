import React, { useRef, useCallback, useState, useEffect } from 'react'
import {
  CareerLampSectionWrapperCss,
  SwingLampWrapperCss,
  QuotesBlockCss,
  DesktopSliderBlockCss,
  ButtonContinueCss,
} from './index.css'
import swingLamp from './assets/swinging_bulb.json'
import flashingLamp from './assets/flashing_bulb.json'
import Lottie from 'react-lottie'
import ArrowUp from "./assets/arrowUp.svg";
import { AnimatePresence } from 'framer-motion'
import { PrimaryLinkCss } from '../../../layout/index.css'

const swingLampOptions = {
  loop: false,
  autoplay: true,
  animationData: swingLamp,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const flashLampOptions = {
  loop: true,
  autoplay: true,
  animationData: flashingLamp,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const SectionVariants = {
  initial: { opacity: 0, y: "0%" },
  open: { opacity: 1, y: "0%" },
  exit: { opacity: 0, y: "-100%" }
}

const LampSection = ({ onFinish }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [lampPlaying, setLampPlaying] = useState('swing') // swing | flash
  const desktopSliderRef = useRef(null);
  const desktopSliderWrapperRef = useRef(null);
  const sliderClicked = useRef(false);

  React.useEffect(() => {
    if(!!desktopSliderRef.current && !!desktopSliderWrapperRef.current){
      const topPosition = desktopSliderWrapperRef.current.clientHeight - 44;
      desktopSliderRef.current.style.top = `${topPosition}px`
    }

    window.document.body.scrollTop = 0;
    window.document.body.classList.add("scroll-disable")

  }, [])

  const slide = useCallback((y) => {
    /*position the slider:*/
    if(!!desktopSliderRef.current && sliderClicked){
      desktopSliderRef.current.style.top = y + "px";
    }
  }, [])

  const getCursorPos = useCallback((e) => {
    let a;
    let y = 0;
    e = e.changedTouches ? e.changedTouches[0] : e;
    /*get the y positions of the image:*/
    a = desktopSliderWrapperRef.current.getBoundingClientRect();
    /*calculate the cursor's y coordinate, relative to the image:*/
    y = e.pageY - a.top;
    /*consider any page scrolling:*/
    y = y - window.pageYOffset;
    return y;
  }, [])

  const slideMove = useCallback((e) => {
    if(!sliderClicked.current) return;
    const containerHeight = desktopSliderWrapperRef.current.clientHeight - 44;
    let pos;
    /*if the slider is no longer clicked, exit this function:*/
    
    /*get the cursor's x position:*/
    pos = getCursorPos(e);
    /*prevent the slider from being positioned outside the image:*/
    if (pos < 0) pos = 0;
    if (pos > containerHeight) pos = containerHeight;
    /*execute a function that will resize the overlay image according to the cursor:*/
    slide(pos);
  }, [getCursorPos, slide, sliderClicked])

  const slideReady = useCallback((e) => {
    /*prevent any other actions that may occur when moving over the image:*/
    e.preventDefault();
    /*the slider is now clicked and ready to move:*/
    sliderClicked.current = true;
    /*execute a function when the slider is moved:*/
    if(sliderClicked.current){
      window.addEventListener("mousemove", slideMove);
      window.addEventListener("touchmove", slideMove);

      return () => {
        window.removeEventListener("mousemove", slideMove);
        window.removeEventListener("touchmove", slideMove);
      }
    }
  }, [slideMove])

  const slideFinish = useCallback(() => {
    sliderClicked.current = false
    if(!desktopSliderRef.current) return;
    
    let topPosition = desktopSliderWrapperRef.current.clientHeight - 44;
    if(desktopSliderRef.current.offsetTop < 44) {
      topPosition = 0;
      setIsOpen(false);
    }

    desktopSliderRef.current.style.top = `${topPosition}px`
  }, [])

  const initDragConfig = useCallback(() => {
    if(!!desktopSliderRef.current && !!window){
      /*execute a function when the mouse button is pressed:*/
      desktopSliderRef.current.addEventListener("mousedown", slideReady);
      desktopSliderRef.current.addEventListener("touchstart", slideReady);

      // On Mouse Up
      window.addEventListener("mouseup", slideFinish);
      window.addEventListener("touchend", slideFinish);
  

      return () => {
        desktopSliderRef.current.removeEventListener("mousedown", slideReady);
        desktopSliderRef.current.removeEventListener("touchstart", slideReady);

        window.removeEventListener("mouseup", slideFinish);
        window.removeEventListener("touchend", slideFinish);
      }
    }
  }, [slideReady, slideFinish])

  useEffect(() => {
    initDragConfig()
  }, [initDragConfig])


  return (
    <AnimatePresence>
      <CareerLampSectionWrapperCss
        variants={SectionVariants}
        animate={isOpen ? "open" : "exit"}
        transition={{ duration: 0.5 }}
        onAnimationComplete={() => {
          if(!isOpen){
            onFinish();
            window.document.body.classList.remove("scroll-disable")
          }
        }}
      >
        <div>
          <SwingLampWrapperCss> 
            {
              lampPlaying === "swing" && (
                <Lottie options={swingLampOptions} height="100%" width="unset" eventListeners={[
                  {
                    eventName: 'complete',
                    callback: () => setLampPlaying("flash")
                  }
                ]}/>
              )
            }
            {
              lampPlaying === "flash" && (
                <Lottie options={flashLampOptions} height="100%" width="unset" />
              )
            }
          </SwingLampWrapperCss>
        </div>
        <AnimatePresence>
          <QuotesBlockCss
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          >
            <ButtonContinueCss onClick={() => setIsOpen(false)}>
              Continue
            </ButtonContinueCss>
            <p>
              “There’s no shortage of remarkable ideas, what’s missing is the
              will to execute them.”
            </p>
            <span>Seth Godin</span>
          </QuotesBlockCss>
        </AnimatePresence>
        <AnimatePresence>
          <DesktopSliderBlockCss
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          >
            <div className='wrapper' ref={desktopSliderWrapperRef}>
              <span className='circle'/>
              <span className='line'/>
              <button ref={desktopSliderRef}>
                <img src={ArrowUp} alt="Slide Up"/>
                <span className='text-helper'>SWIPE UP</span>
              </button>
            </div>
          </DesktopSliderBlockCss>
        </AnimatePresence>
      </CareerLampSectionWrapperCss>
    </AnimatePresence>
  )
}

export default LampSection
