import React from "react";
import {
  SecondSectionWrapperCss,
  BlockCss,
  InfoCss,
  ImgAndVideoCss,
  ImgAndVideoCssMobile,
} from "./index.css";
import img1 from "./assets/image1.png";
import headlights from "./assets/Headlights.mp4";

const SecondSection = () => {
  return (
    <SecondSectionWrapperCss>
      <BlockCss>
        <InfoCss>
          <div>
            <h1>The Challenge</h1>
            <p className="mB">
              The dentistry market is very capricious, but strives for
              innovation. We helped Bryant Dental express and enhance their
              brand’s trustworthiness, by bringing forward their capability to
              innovate and design dental products.
            </p>
            <h1>Making Dental Loupes Look Cool</h1>
            <p>
              3D renders placed the product upfront in a spectacular light. The
              features are displayed in a cutting-edge interactive format, thus
              effectively presenting Bryant Dental’s top-of-the-line loupes.
            </p>
          </div>
        </InfoCss>
        <ImgAndVideoCss>
          <img src={img1} alt="" />
          <video
            className="headlights"
            controls={false}
            preload="auto"
            autoPlay={true}
            loop={true}
            muted="muted"
            playsInline
            webkit-playingInline
          >
            <source src={headlights} type="video/mp4" />
          </video>
        </ImgAndVideoCss>
        <ImgAndVideoCssMobile>
          <img src={img1} alt="" />
          <video
            className="headlights"
            controls={false}
            preload="auto"
            autoPlay={true}
            loop={true}
            muted="muted"
            playsInline
            webkit-playingInline
          >
            <source src={headlights} type="video/mp4" />
          </video>
        </ImgAndVideoCssMobile>
      </BlockCss>
    </SecondSectionWrapperCss>
  );
};

export default SecondSection;
