import styled, { css } from 'styled-components';
import { screens, colors } from '../../../constants/theme';

export const BloomdSectionWrapperCss = styled.div`
  padding: 130px 0;
  background: ${colors.lightGrey1};

  &&& h1,
  &&& h3 {
    color: ${colors.black};
  }

  h3 {
    font-style: italic;
  }

  &&& p {
    color: ${colors.grey2};
  }

  em {
    display: block;
    color: ${colors.grey};
    text-align: center;
    margin: 0;
    margin-top: 50px;
  }

  @media (max-width: ${screens.sm}) {
    padding: 50px 0;
  }
`;

export const PaddingLeftRightCss = styled.div`
  padding: 0 5%;
`;

export const DescriptionCss = styled.div`
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 30px;

  @media (max-width: ${screens.xs}) {
    margin: 0;
  }
`;

export const GroupWrapperCss = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    &:first-child {
      width: 50%;
      height: auto;
      max-width: 300px;
      margin-right: 10%;
      position: relative;
      z-index: 1;
    }

    &:last-child {
      max-width: 365px;
    }
  }

  @media (max-width: ${screens.lg}) {
    flex-direction: column;
    align-items: center;

    & > * {
      &:first-child {
        order: 2;
        margin-right: 0;
      }

      &:last-child {
        order: 1;
        margin-bottom: 50px;

        text-align: center;
        svg {
          margin: 0 auto;
        }
      }
    }
  }

  @media (max-width: ${screens.xs}) {
    & > *:first-child {
      width: 65%;
    }
  }

  ${({ right }) =>
    right &&
    css`
      & > * {
        &:first-child {
          order: 2;
          margin-right: 0;
          margin-left: 10%;
        }

        &:last-child {
          order: 1;
        }
      }

      @media (max-width: ${screens.lg}) {
        & > *:first-child {
          margin-left: 0;
        }
      }
    `}
`;

export const GroupDescriptionCss = styled.div`
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    right: 0;
    height: 220px;
    background: #fdfdfd;
    width: 5000px;

    border-radius: 12px;
    transform: translate(5vw, 30%);

    @media (max-width: ${screens.lg}) {
      transform: translate(15vw, 230%);
      z-index: 0;
    }

    @media (max-width: ${screens.sm}) {
      transform: translate(5vw, 230%);
    }

    @media (max-width: ${screens.xs}) {
      transform: translate(0, 200%);
    }

    ${({ right }) =>
      right &&
      css`
        left: 0;
        transform: translate(-6vw, 30%);

        @media (max-width: ${screens.lg}) {
          transform: translate(-15vw, 230%);
        }

        @media (max-width: ${screens.sm}) {
          transform: translate(-5vw, 230%);
        }

        @media (max-width: ${screens.xs}) {
          transform: translate(0, 200%);
        }
      `};
  }

  svg {
    display: block;
  }

  transform: translateY(35%);

  @media (max-width: ${screens.lg}) {
    transform: none;
  }
`;

export const SeparatorCss = styled.div`
  height: 80px;
`;
