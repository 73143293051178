import styled, { createGlobalStyle } from 'styled-components/macro'
import { screens, colors } from '../../constants/theme'
import { NavLink } from 'react-router-dom';


export const GlobalStyle = createGlobalStyle`
  @media screen and (max-width: ${screens.lg}) {
    body {
      overflow: ${(props) => (props.show ? 'hidden' : 'auto')};
  	  position: ${(props) => (props.show ? 'fixed' : 'relative')};
      width: ${(props) => (props.show ? '100vw' : 'unset')};
    }
  }
`
export const HeaderWrapperCss = styled.div`
  left: 0px;
  top: 0px;
  padding: 0px 60px;
  height: 80px;
  position: fixed;
  display: flex;
  width: 100%;
  z-index: 99;
  background: ${colors.black};
  box-sizing: border-box;

  @media (max-width: ${screens.lg}) {
    padding: 0px 25px;
  }

  @media screen and (max-width: ${screens.sm}) {
    width: 100vw;
  }

  justify-content: space-between;
  align-items: center;

  nav,
  a {
    color: ${colors.white};
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    z-index: 999;
  }

  li {
    list-style: none;
  }
`

export const MenuItemLink = styled(NavLink)`
  font-weight: normal;
  line-height: 23px;
  text-decoration: none;
  color: ${colors.white};
  text-align: center;
  width: fit-content;
  display: inline-block;
  padding: 0px 11px 8px;
  position: relative;
  top: 8px;
  overflow: hidden;
  transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);

  &:after {
    position: absolute;
    transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    content: '';
    width: 0%;
    right: auto;
    left: 0;
    bottom: 0;
    height: 2px;
    background: ${colors.blue};
  }

  &:hover {
    cursor: pointer;
    &:after {
      width: 100%;
    }
  }

  &.active {
    &:after {
      left: 0%;
      width: 100% !important;
    }
  }

  &:not(:hover) {
    &:after {
      width: 0%;
      right: 0%;
      left: unset;
    }
  }
`

export const DesktopMenuCss = styled.div`
  display: flex;

  ul {
    display: flex;
    align-items: center;

    li {
      margin: 0px 21px;
    }
  }

  @media (max-width: ${screens.lg}) {
    display: none;
  }
`

export const MobileMenuCss = styled.div`
  display: none;
  background: ${colors.black};

  @media (max-width: ${screens.lg}) {
    display: ${(props) => (props.show ? 'flex' : 'none')};
    position: fixed;
    top: 10vw;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    img {
      width: 60vw;
    }

    ul {
      display: flex;
      flex-direction: column;
      padding: 0px;
      margin-top: 5vh;

      li {
        text-align: center;
        margin: 17px 0px !important;
      }
    }
  }
  @media (max-width: ${screens.sm}) {
    top: 12vh;
  }
`

export const MobileToggleCss = styled.div`
  z-index: 9999;
  color: ${colors.white};

  &:hover {
    color: ${colors.grey};
  }

  @media (max-width: ${screens.lg}) {
    display: block;
  }

  display: none;
`

export const CTA = styled.a`
  font-style: italic;
  font-weight: normal;
  line-height: 23px;
  text-decoration: none;
  color: ${colors.white};
  text-align: center;

  width: fit-content;
  display: inline-block;
  padding: 0px 11px 8px;
  position: relative;
  top: 8px;
  overflow: hidden;
  transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);

  &:after {
    position: absolute;
    transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    content: '';
    width: 100%;
    right: auto;
    left: 0;
    bottom: 0;
    height: 2px;
    background: ${colors.blue};
  }

  &:hover {
    cursor: pointer;
    &:after {
      width: 0%;
      right: 0;
      left: auto;
    }
  }

  @media (max-width: ${screens.sm}) {
    padding-bottom: 8px;
  }
`
