import React from "react";
import { Section1, Section3 } from "../components/AboutUs";
import PageTransition from "../components/PageTransition";


const AboutUs = () => {
  return (
    <PageTransition>
      <Section1 />
      <Section3 />
    </PageTransition>
  );
};

export default AboutUs;
