import React from "react";
import { GotYourAttentionWrapperSectionCss } from "./index.css";
import { PrimaryLinkCss } from '../../../layout/index.css'


const GotYourAttentionSection = () => {
  return (
    <GotYourAttentionWrapperSectionCss>
      <div className="copy-wrapper">
        <p>
          If we got your attention or you just want to have a chat, hit us up
        </p>
        <PrimaryLinkCss to="/contact-us">
          Get in touch
        </PrimaryLinkCss>
      </div>
    </GotYourAttentionWrapperSectionCss>
  )
}

export default GotYourAttentionSection;