import React from 'react';
import { EmptySectionBlockCss } from './index.css';
import { AnimatePresence } from "framer-motion";

const AnimationVariant = {
	initial: { opacity: 1, height: "100vh" },
	enter: { opacity: 1, height: "100vh" },
	exit: { opacity: 0, height: "0vh" }
}

const EmptySection = ({ show }) => {
	return (
		<AnimatePresence>
			<EmptySectionBlockCss 
				variants={AnimationVariant}
				animate={ show ? "enter" : "exit" }
				transition={{ type: "just" }}
				/>
		</AnimatePresence>
	);
};

export default EmptySection;
