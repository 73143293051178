import styled from 'styled-components/macro'
import { screens } from '../../../constants/theme';

export const VideoCss = styled.video`
  position: relative;
  right: 13px;

  @media screen and (max-width: ${screens.sm}) {
    max-width: calc(100% - 20px);
  }
`;