import React from "react";
import {
  EighthSectionWrapperCss,
  BlockCss,
  InfoCss,
  ImgAndVideoCss,
  ImgAndVideoCssMobile,
} from "./index.css";
import img1 from "./assets/image1.png";
import headlights from "./assets/Headlights.mp4";

const EighthSection = () => {
  return (
    <EighthSectionWrapperCss>
      <BlockCss>
        <InfoCss>
          <div>
            <h1>Custom Checkout Process</h1>
            <p>
              Throughout our collaboration, we offered several style options,
              successfully worked through the checkout process, and put together
              an interactive prototype for further testing.
            </p>
          </div>
        </InfoCss>
        <ImgAndVideoCss>
          <video
            controls={false}
            preload="auto"
            autoPlay={true}
            loop={true}
            muted="muted"
            playsInline
            webkit-playingInline
          >
            <source src={headlights} type="video/mp4" />
          </video>
          <img className="headlights" src={img1} alt="" />
        </ImgAndVideoCss>
        <ImgAndVideoCssMobile>
          <video
            controls={false}
            preload="auto"
            autoPlay={true}
            loop={true}
            muted="muted"
            playsInline
            webkit-playingInline
          >
            <source src={headlights} type="video/mp4" />
          </video>
          <img className="headlights" src={img1} alt="" />
        </ImgAndVideoCssMobile>
      </BlockCss>
    </EighthSectionWrapperCss>
  );
};

export default EighthSection;
