import React from 'react'
import styled from 'styled-components/macro'
import { screens } from '../../constants/theme'

export const ContainerWrapperCss = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 60px;

  @media (max-width: ${screens.lg}) {
    padding: 0 25px;
  }
`

const Container = ({ children }) => {
  return <ContainerWrapperCss>{children}</ContainerWrapperCss>
}

export default Container
