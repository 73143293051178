import styled from 'styled-components'
import { screens, colors } from '../../../constants/theme'
export const FirstSectionWrapperCss = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${screens.md}) {
    padding: 20px 0px 0px;
  }
`

export const BlockCss = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 105px;
  width: 100%;
  section {
    margin: 40px 0px;
  }

  @media (max-width: ${screens.md}) {
    section {
      margin: 0px;
    }
  }
`

export const InfoCss = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  width: 100%;
  max-width: 60vw;

  h2 {
    width: 60vw;
    max-width: 648px;

    @media (max-width: ${screens.lg}) {
      width: 100%;
      max-width: unset;
    }
  }

  p {
    max-width: 648px;
    margin-bottom: 50px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #9b9999;
    @media (max-width: ${screens.md}) {
      font-size: 16px;
      line-height: 27px;
    }
  }
  

  @media (max-width: ${screens.md}) {
    margin: 0px 25px;
    width: auto;
    max-width: unset;


    h2 {
      margin-bottom: 0px;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 34px;
    }

    a {
      margin-top: 30px;
    }
  }

  ul {
    padding-left: 0px;
    list-style-position: inside;

    li {
      color: ${colors.grey};
      margin-bottom: 13px;
      list-style: none;
      position: relative;
      padding-left: 12px;
      &:before {
        content: '■';
        position: absolute;
        left: 0px;
        top: 0px;
        font-size: 7px;
        color: ${colors.white};
      }

      span {
        color: ${colors.white};
      }
    }
  }
`

export const ItemsCss = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 25px 0px 25px;
  justify-content: space-between;
  width: 100%;
  max-width: 820px;
  @media (max-width: ${screens.md}) {
    flex-direction: column;
    width: 100%;
  }
`
export const ImageCss = styled.div`
  cursor: pointer;
  @media (max-width: ${screens.md}) {
    width: 100vw;
    display: none;
  }

  img {
    width: 100%;
  }
`
export const ImageCssMobile = styled.div`
  display: none;
  width: 100%;
  cursor: pointer;
  @media (max-width: ${screens.md}) {
    width: 100vw;
    display: block;
  }

  img {
    width: 100vw;
  }
`

export const InfoBlockItemCss = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 300px;
  @media (max-width: ${screens.md}) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 50px;
  }

  h4 {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 14px;
    @media (max-width: ${screens.md}) {
      width: auto;
      margin-bottom: 0px;
    }
  }

  a {
    padding: 0px 5px 8px;
    margin: 0px;
    color: ${colors.grey};

    @media (max-width: ${screens.md}) {
      margin-top: 16px;
    }
  }

  p {
    color: ${colors.grey}!important;
    word-spacing: 14px;
    margin: 0px;
    width: 100%;
    span {
      font-style: normal;
      word-spacing: 5px;
    }

    @media (max-width: ${screens.md}) {
      margin: 16px 0px 0px;
    }
  }
`
