import React, { useState, useEffect } from 'react'
import {
  CardBlogCss,
  CardFigureCss,
  CardImageCss,
  AuthorWrapperCss,
  AuthorText,
  AuthorLineSeparator,
  CardPostTitleCss,
  CardPostDescriptionCss,
} from './index.css'

const CardPost = ({ title, image, author, description }) => {
  return (
    <CardBlogCss>
      <CardFigureCss>
        <CardImageCss src={image} alt={title} />
      </CardFigureCss>
      <AuthorWrapperCss>
        <AuthorText>{author}</AuthorText>
        <AuthorLineSeparator />
      </AuthorWrapperCss>
      <CardPostTitleCss>{title}</CardPostTitleCss>
      <CardPostDescriptionCss>{description}</CardPostDescriptionCss>
    </CardBlogCss>
  )
}

export default CardPost
