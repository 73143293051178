import firebase from 'firebase/app';
import 'firebase/firestore';

const config = {
    apiKey: 'AIzaSyB2gufXKs0yY6xHG6RPOuo5hztFads21cc',
    authDomain: 'subscribers-kappa-website.firebaseapp.com',
    projectId: 'subscribers-kappa-website',
    storageBucket: 'subscribers-kappa-website.appspot.com',
    messagingSenderId: '511965511052',
    appId: '1:511965511052:web:af02520880cb518734aaa9',
};

export const createSubscriber = async (subscriber, collection) => {
    if (!subscriber) {
        return Promise.reject('Email is required');
    }

    const subscriberRef = firestore.doc(`${collection}/${subscriber}`);
    const snapShot = await subscriberRef.get();

    if (!snapShot.exists) {
        const email = subscriber;
        const createdAt = new Date();

        return await subscriberRef.set({
            email,
            createdAt,
        });
    } else {
        return Promise.reject('Already subscribed!');
    }
};

firebase.initializeApp(config);

export const firestore = firebase.firestore();

export default firebase;
