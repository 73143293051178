import React, { createRef, useEffect } from 'react'
import {
  SecondSectionContainerCss,
  VideoCss,
  VideoOverlayCss,
  ContentWrapperCss,
  RightSideCss,
  TitleCss,
} from './index.css'
import checkout from './assets/checkout.mp4'
import { PrimaryLinkCss } from '../../../layout/index.css'
import SectionLayout from '../section-layout/section-layout'

const SecondSection = () => {
  const videoRef = createRef()

  useEffect(() => {
    /**
     * Checks if the video is in the viewport
     * @param {IntersectionObserverEntryInit[]} entries
     * @param {IntersectionObserver} observer
     */
    function intersectionCallback(entries, observer) {
      if (videoRef.current) {
        const isPlaying = !videoRef.current.paused && !videoRef.current.ended

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Play/Pause on subsequent intersections
            if (!isPlaying) {
              videoRef.current.play()
            }
          } else {
            if (isPlaying) {
              videoRef.current.pause()
            }
          }
        })
      }
    }

    let observer = new IntersectionObserver(intersectionCallback, {
      threshold: 0.4,
    })

    observer.observe(videoRef.current)
  }, [videoRef])

  return (
    <SecondSectionContainerCss>
      <VideoCss playsInline={true}
        src={checkout}
        ref={videoRef}
        autoPlay={true}
        loop={true}
        // For autoplay to work on refresh, this has to be set to true
        // Refer here for details https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
        muted={true}
      />

      <VideoOverlayCss>
        <ContentWrapperCss>
          <SectionLayout title="Partnerships">
            <RightSideCss>
              <TitleCss>Dive deeper into past projects</TitleCss>

              <PrimaryLinkCss to="/projects">Explore</PrimaryLinkCss>
            </RightSideCss>
          </SectionLayout>
        </ContentWrapperCss>
      </VideoOverlayCss>
    </SecondSectionContainerCss>
  )
}
export default SecondSection
