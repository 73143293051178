import styled, { css } from 'styled-components/macro'
import { colors, screens } from '../../../constants/theme'

// ====== CONST ======

export const DIRECTIONS = {
  toLeft: 'toLeft',
  toRight: 'toRight'
}
Object.freeze(DIRECTIONS);

export const TECH_LEFT_RIGHT_MARGIN = 10;

// ====== STYLES ======
export const ThirdSectionWrapperCss = styled.div`
  display: block;
  width: 100%;
  margin-top: 200px;

  @media (max-width: ${screens.xl}) {
    margin-top: 120px;
  }

  @media (max-width: ${screens.avg}) {
    margin-top: 90px;
  }
`

export const TitleCss = styled.h1`
  margin-top: 9px;
`

export const TechnologiesWrapperCss = styled.div`
  overflow: hidden;

  @media only screen and (min-width: ${screens.avg}) {
    margin-top: 90px;
  }
`

export const RollOnScroll = styled.div`
  display: flex;
  flex-wrap: nowrap;
  ${props => setOffset(props)};
  transition: margin 0.5s ease-out;
`

export const TechCss = styled.h1`
  margin: 15px ${TECH_LEFT_RIGHT_MARGIN}px;
  white-space: nowrap;

  && {
    font-size: 30px;
    line-height: 40px;
    color: ${colors.grey2};
  }

  :nth-of-type(2n) {
    && {
      color: ${colors.grey};
    }
  }

  ::before {
    content: '/';
    color: ${colors.blue};
  }

  ::after {
    content: '<';
    color: ${colors.blue};
  }

  @media only screen and (min-width: ${screens.avg}) {
    && {
      margin: 20px 15px;
      font-size: 54px;
      line-height: 54px;
      color: ${colors.grey2};
    }
  }
`

export const BannerCss = styled.div`
  background-color: ${colors.darkGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 110px;
  padding: 90px 60px;

  && h3 {
    font-style: normal;
    text-align: center;
  }

  a {
    margin: 0px;
  }
`

// ====== UTILS ======

const setOffset = (props) => {
  if (props.moveDirection === DIRECTIONS.toLeft) {
    return css`
      margin-left: 50vw;
    `;
  }

  if (props.moveDirection === DIRECTIONS.toRight) {
    return css`
      margin-left: calc(${props.rowWidth}px - 50vw);
    `;
  }
}
