import styled from 'styled-components/macro'
import { screens } from '../../../constants/theme'

export const SectionLayoutCss = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 83.33vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: ${screens.xl}) {
    flex-direction: column;
    max-width: unset;
  }
`

export const LeftSideCss = styled.h4`
  min-width: 16.66vw;
  max-width: 16.66vw;

  @media only screen and (max-width: ${screens.xl}) {
    max-width: unset;
    min-width: unset;
  }
`

export const RightSideCss = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;

  @media (max-width: ${screens.xl}) {
    max-width: 100%;
  }
`