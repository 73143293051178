import React, { useEffect, useState } from 'react'
import { useOnScreen } from '../../Internship/section5'
import { ParagraphCss } from './index.css'
import * as css from './discovery.css'
import middleBalloon from './assets/middle-balloon.svg'
import leftBalloon from './assets/left-balloon.svg'
import rightBalloon from './assets/right-balloon.svg'
import longString from './assets/long-string.svg'
import shortString from './assets/short-string.svg'

const Discovery = () => {
  const [ref, isVisible] = useOnScreen({ threshold: 0.5 })
  const [animationFinished, setAnimationFinished] = useState(false)
  const [animateOnce, setAnimateOnce] = useState(false);
  /**
   * Show the content of a balloon at the end of an animation
   * @param {AnimationEvent} ev event that fires at the end of an animation
   */
  function showContent(ev) {
    const checkAnimationName = ev.animationName === css.middleBalloonAnimation.getName() ||
                              ev.animationName === css.middleBalloonTabletAnimation.getName() ||
                              ev.animationName === css.middleBalloonMobileAnimation.getName()

    if (checkAnimationName) {
      setAnimationFinished(true)
    }
  }

  useEffect(() => {
    if (ref.current) {
      const balloon = ref.current.firstElementChild

      balloon.addEventListener('animationend', showContent)
    }
  }, [ref])

  useEffect(() => {
    if (isVisible && !animateOnce) {
      setAnimateOnce(true);
    }
  }, [isVisible, animateOnce]);

  return (
    <>
      <ParagraphCss>
        We want to meet you and understand your every need and preference. To
        start, we want to get to know you, your values, your brand's values and style,
        your hopes and dreams.
      </ParagraphCss>

      <css.BalloonContainerCss ref={ref}>
        <css.MiddleBalloonCss startAnimation={animateOnce}>
          <img src={middleBalloon} alt="" />
          <img src={longString} alt="" />

          <css.MiddleBalloonTextCss>
            Team,
            <br />
            Internal Processes
          </css.MiddleBalloonTextCss>

          {
            animationFinished &&
              <css.IntersectionCss>Research</css.IntersectionCss>
          }
        </css.MiddleBalloonCss>

        <css.LeftBalloonCss startAnimation={animateOnce}>
          <img src={leftBalloon} alt="" />
          <img src={shortString} alt="" />

          <css.LeftBalloonTextCss>
            Values,
            <br />
            Branding,
            <br />
            Style
          </css.LeftBalloonTextCss>
        </css.LeftBalloonCss>

        <css.RightBalloonCss startAnimation={animateOnce}>
          <img src={rightBalloon} alt="" />
          <img src={shortString} alt="" />

          <css.RightBalloonTextCss>
            Market Audit,
            <br />
            Comms
            <br />
            Strategy
          </css.RightBalloonTextCss>
        </css.RightBalloonCss>
      </css.BalloonContainerCss>
    </>
  )
}

export default Discovery
