import React, { useEffect, useRef, useState } from 'react'
import {
  ThirdSectionWrapperCss,
  TitleCss,
  RollOnScroll,
  TechnologiesWrapperCss,
  TechCss,
  BannerCss,
  DIRECTIONS,
  TECH_LEFT_RIGHT_MARGIN,
} from './index.css'
import { firstTechRow, secondTechRow, thirdTechRow } from './section3.utils'
import { PrimaryLinkCss } from '../../../layout/index.css'
import SectionLayout from '../section-layout/section-layout'

/**
 * Sets a flag that tells whether the stripes move based on the scroll
 * so it doesn't go offscreen when scrolling past it.
 * @param {React.Ref<HTMLDivElement>} ref - ref of the row element
 * @returns {boolean}
 */
function useAnimationWhileVisible(ref) {
  const [matchScroll, setMatchScroll] = useState(false)

  // Check if the section is in the viewport
  useEffect(() => {
    /**
     * Checks if the section is in the viewport
     * @param {IntersectionObserverEntryInit[]} entries
     * @param {IntersectionObserver} observer
     */
    function intersectionCallback(entries, observer) {
      if (ref.current) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setMatchScroll(true)
          } else {
            setMatchScroll(false)
          }
        })
      }
    }

    let observer = new IntersectionObserver(intersectionCallback, {
      threshold: .2,
    })

    observer.observe(ref.current)
  }, [ref])

  return matchScroll
}

const ThirdSection = () => {
  const [firstRowWidth, setFirstRowWidth] = useState(0);
  const [secondRowWidth, setSecondRowWidth] = useState(0);
  const [thirdRowWidth, setThirdRowWidth] = useState(0);

  const firstRowRef = useRef()
  const secondRowRef = useRef()
  const thirdRowRef = useRef()

  // Ref needed so we can access the updated value inside the 'handleScroll' event handler
  const matchScroll = useRef({
    firstRow: false,
    secondRow: false,
    thirdRow: false,
  })

  // Ref needed so we can access the updated value inside the 'handleScroll' event handler
  const movementIdexes = useRef({
    firstMovement: 0,
    secondMovement: 0,
    thirdMovement: 0,
    firstRowWidth,
    secondRowWidth,
    thirdRowWidth
  })

  matchScroll.current.firstRow = useAnimationWhileVisible(firstRowRef)
  matchScroll.current.secondRow = useAnimationWhileVisible(secondRowRef)
  matchScroll.current.thirdRow = useAnimationWhileVisible(thirdRowRef)

  // Scroll effect
  useEffect(() => {
    /**
     * Move the stripes based on the scroll position
     */
    function handleScroll() {
      const {firstMovement, secondMovement, thirdMovement, firstRowWidth, thirdRowWidth} = movementIdexes.current;

      const scroll = window.scrollY;

      if (matchScroll.current.firstRow) {
        firstRowRef.current.style.marginLeft = `calc(${firstMovement * scroll - 2 * firstRowWidth}px - 50vw)`
      }

      if (matchScroll.current.secondRow) {
        secondRowRef.current.style.marginLeft = `calc(50vw - ${secondMovement * scroll}px)`
      }

      if (matchScroll.current.thirdRow) {
        thirdRowRef.current.style.marginLeft = `calc(${thirdMovement * scroll - 2 * thirdRowWidth}px - 50vw)`
      }
    }

    document.addEventListener('scroll', handleScroll)
    return () => document.removeEventListener('scroll', handleScroll)
  }, [])

  // Calculate the approximate width for each row
  useEffect(() => {
    setFirstRowWidth(firstTechRow.reduce((acc, tech) =>  acc + tech.length * 17 + 2 * TECH_LEFT_RIGHT_MARGIN, 0))
    setSecondRowWidth(secondTechRow.reduce((acc, tech) =>  acc + tech.length * 17 + 2 * TECH_LEFT_RIGHT_MARGIN, 0))
    setThirdRowWidth(thirdTechRow.reduce((acc, tech) =>  acc + tech.length * 17 + 2 * TECH_LEFT_RIGHT_MARGIN, 0))
  }, [])

  // Calculate the index by which the row moves in relation with the scroll.
  useEffect(() => {
    const { y: firstY } = firstRowRef.current.getBoundingClientRect();
    const { y: secondY } = secondRowRef.current.getBoundingClientRect();
    const { y: thirdY } = thirdRowRef.current.getBoundingClientRect();

    const height = document.documentElement.clientHeight;

    let firstMovementBottom = firstRowWidth / firstY;
    let secondMovementBottom = secondRowWidth / secondY;
    let thirdMovementBottom = thirdRowWidth / thirdY;

    let firstMovementTop = firstRowWidth / (firstY - height - 10);
    let secondMovementTop = secondRowWidth / (secondY - height);
    let thirdMovementTop = thirdRowWidth / (thirdY - height - 80);

    /**
     * Get the average between the 2 values
     */
    let firstMovement = (firstMovementBottom + firstMovementTop) / 2;
    let secondMovement = (secondMovementBottom + secondMovementTop) / 2;
    let thirdMovement = (thirdMovementBottom + thirdMovementTop) / 2;

    movementIdexes.current.firstMovement = +(Math.ceil((firstMovement + Number.EPSILON) * 100) / 100).toPrecision(2);
    movementIdexes.current.secondMovement = +(Math.ceil((secondMovement + Number.EPSILON) * 100) / 100).toPrecision(2);
    movementIdexes.current.thirdMovement = +(Math.ceil((thirdMovement + Number.EPSILON) * 100) / 100).toPrecision(2);

    movementIdexes.current.firstRowWidth = firstRowWidth;
    movementIdexes.current.secondRowWidth = secondRowWidth;
    movementIdexes.current.thirdRowWidth = thirdRowWidth;
  }, [firstRowRef, firstRowWidth, secondRowRef, secondRowWidth, thirdRowRef, thirdRowWidth]);

  return (
    <ThirdSectionWrapperCss>
      <SectionLayout title="Technologies">
        <TitleCss>It's easier to name what we can't do</TitleCss>
      </SectionLayout>

      <TechnologiesWrapperCss>
        {/** First row */}
        <RollOnScroll ref={firstRowRef}
          moveDir={DIRECTIONS.toRight}
          rowWidth={2 * firstRowWidth}>

          {
            firstTechRow.map((tech, index) =>
              <TechCss key={index}>
                {tech}
              </TechCss>
            )
          }

          {
            firstTechRow.map((tech, index) =>
              <TechCss key={index}>
                {tech}
              </TechCss>
            )
          }
        </RollOnScroll>

        {/** Second row */}
        <RollOnScroll ref={secondRowRef}
          moveDir={DIRECTIONS.toLeft}
          rowWidth={2 * secondRowWidth}>

          {
            secondTechRow.map((tech, index) =>
              <TechCss key={index}>
                {tech}
              </TechCss>
            )
          }

          {
            secondTechRow.map((tech, index) =>
              <TechCss key={index}>
                {tech}
              </TechCss>
            )
          }
        </RollOnScroll>

        {/** Third row */}
        <RollOnScroll ref={thirdRowRef}
          moveDir={DIRECTIONS.toRight}
          rowWidth={2 * thirdRowWidth}>

          {
            thirdTechRow.map((tech, index) =>
              <TechCss key={index}>
                {tech}
              </TechCss>
            )
          }

          {
            thirdTechRow.map((tech, index) =>
              <TechCss key={index}>
                {tech}
              </TechCss>
            )
          }
        </RollOnScroll>
      </TechnologiesWrapperCss>

      <BannerCss>
        <h3>Lets make something interesting together</h3>

        <PrimaryLinkCss
          to="/contact-us"
        >
          Get in touch
        </PrimaryLinkCss>
      </BannerCss>
    </ThirdSectionWrapperCss>
  )
}

export default ThirdSection
