import styled from 'styled-components/macro'
import { screens } from '../../../constants/theme'

// ====== CONSTANTS ======
const HEADER_BASE = '42px'

// ====== STYLES ======

export const SectionLayoutCss = styled.div`
  /* display: flex;

  @media (max-width: ${screens.lg}) {
    flex-direction: column;
  } */
  display: flex;
  align-items: flex-start;
  max-width: 83.33vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: ${screens.xl}) {
    flex-direction: column;
    max-width: unset;
  }
`

export const LeftSideCss = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  align-items: flex-start;

  @media only screen and (max-width: ${screens.xl}) {
    max-width: unset;
    min-width: unset;
  }

  span.empty,
  h4 {
    display: inline-block;
    width: 16.66vw;
		margin-right: 8.33vw;

		@media (max-width: ${screens.lg}) {
			width: 100%;
			margin-right: 0px;
		}
  }
`

export const LeftHeader = styled.h4`
	display: flex;
	height: ${HEADER_BASE};
	align-items: flex-end;
	margin: 0px;
  white-space: nowrap;
`;

export const RightSideCss = styled.div`
  overflow: visible;
  display: flex;
  flex: 5;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: ${screens.xl}) {
    max-width: 100%;
    width: 100%;
  }
`

export const RightHeaderCss = styled.h1`
  margin-top: 10px;
  margin-bottom: 40px;
  height: ${HEADER_BASE};

  @media (max-width: ${screens.xs}) {
    margin-bottom: 30px;
  }
`
