import styled, { css, keyframes } from 'styled-components/macro'
import { colors, screens } from '../../../constants/theme'

// ====== CONSTANTS ======

const SCREENS = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop'
}
Object.freeze(SCREENS);

const BALLOONS = {
  MIDDLE: 'middle',
  LEFT: 'left',
  RIGHT: 'right',
}
Object.freeze(BALLOONS)

// ====== STYLES ======

export const BalloonContainerCss = styled.div`
  width: 100%;
  height: 600px;
  position: relative;
  margin-top: 60px;
  margin-bottom: 90px;
  overflow: visible;

  @media screen and (max-width: ${screens.md}) {
    height: 550px;
    margin-top: 50px;
    margin-bottom: 80px;
  }

  @media screen and (max-width: ${screens.xs}) {
    transform: scale(0.8, 0.8);
    margin-top: 5px;
    height: 330px;
  }
`

const BalloonCss = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  position: absolute;

  img:last-of-type {
    position: relative;
    top: -5px;
  }

  @media screen and (max-width: ${screens.md}) {
    img {
      transform: scale(0.9, 0.9);
    }

    img:last-of-type {
      position: relative;
      top: -28px;
    }
  }

  @media screen and (max-width: ${screens.sm}) {
    img {
      transform: scale(0.8, 0.8);
    }

    img:last-of-type {
      position: relative;
      top: -50px;
    }
  }
`

const BalloonContentCss = styled.span`
  color: ${colors.white};
  text-align: center;
  position: absolute;
  font-size: 18px;

  @media screen and (max-width: ${screens.md}) {
    font-size: 16px;
  }
`



export const middleBalloonAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate(0px, 0px);
  }

  to {
    opacity: 1;
    transform: translate(-23px, -150px);
  }
`

export const middleBalloonTabletAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -170px);
  }
`

export const middleBalloonMobileAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -180px);
  }
`
export const MiddleBalloonCss = styled(BalloonCss)`
  left: 105px;
  top: 150px;

  ${(props) => setAnimation(props, BALLOONS.MIDDLE)};

  @media (max-width: ${screens.avg}) {
    /* left: 125px; */
    left: 50%;
    transform: translateX(-50%);

    ${(props) => setAnimation(props, BALLOONS.MIDDLE, SCREENS.TABLET)};
  }

  @media screen and (max-width: ${screens.sm}) {
    left: 50%;
    transform: translateX(-50%);

    img:last-of-type {
      position: relative;
      top: -57px;
    }

    ${(props) => setAnimation(props, BALLOONS.MIDDLE, SCREENS.MOBILE)};
  }
`

export const MiddleBalloonTextCss = styled(BalloonContentCss)`
  top: 50px;

  @media screen and (max-width: ${screens.md}) {
    top: 60px;
  }

  @media screen and (max-width: ${screens.sm}) {
    top: 70px;
  }
`

const IntersectionAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const IntersectionCss = styled.span`
  font-size: 18px;
  color: ${colors.blue};
  position: absolute;
  bottom: 270px;
  opacity: 0;
  animation: ${IntersectionAnimation} 0.5s linear;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;

  @media screen and (max-width: ${screens.md}) {
    bottom: 275px;
  }
`

const leftBalloonAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate(0px, 0px);
  }

  to {
    opacity: 1;
    transform: translate(150px, -255px);
  }
`

const leftBalloonTabletAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    opacity: 1;
    transform: translate(-80%, -290px);
  }
`

const leftBalloonMobileAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    opacity: 1;
    transform: translate(-75%, -310px);
  }
`
export const LeftBalloonCss = styled(BalloonCss)`
  left: -150px;
  top: 400px;

  ${(props) => setAnimation(props, BALLOONS.LEFT)};

  @media (max-width: ${screens.avg}) {
    /* left: -130px; */
    /* left: 0px; */
    left: 50%;
    transform: translateX(-50%);

    ${(props) => setAnimation(props, BALLOONS.LEFT, SCREENS.TABLET)};
  }

  @media screen and (max-width: ${screens.sm}) {
    left: 50%;
    transform: translateX(-50%);

    ${(props) => setAnimation(props, BALLOONS.LEFT, SCREENS.MOBILE)};
  }
`

export const LeftBalloonTextCss = styled(BalloonContentCss)`
  bottom: 200px;
  left: 70px;

  @media screen and (max-width: ${screens.md}) {
    bottom: 220px;
    left: 75px;
  }

  @media screen and (max-width: ${screens.sm}) {
    bottom: 225px;
  }
`

const rightBalloonAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate(0px, 0px);
  }

  to {
    opacity: 1;
    transform: translate(-280px, -105px);
  }
`

const rightBalloonTabletAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    opacity: 1;
    transform: translate(-20%,  -140px);
  }
`

const rightBalloonMobileAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    opacity: 1;
    transform: translate(-25%, -160px);
  }
`
export const RightBalloonCss = styled(BalloonCss)`
  left: 460px;
  top: 250px;

  ${(props) => setAnimation(props, BALLOONS.RIGHT)};

  @media (max-width: ${screens.avg}) {
    left: 50%;
    transform: translateX(-50%);

    ${(props) => setAnimation(props, BALLOONS.RIGHT, SCREENS.TABLET)};
  }

  @media screen and (max-width: ${screens.sm}) {
    left: 50%;
    transform: translateX(-50%);

    ${(props) => setAnimation(props, BALLOONS.RIGHT, SCREENS.MOBILE)};
  }
`

export const RightBalloonTextCss = styled(BalloonContentCss)`
  bottom: 200px;
  right: 50px;

  @media screen and (max-width: ${screens.md}) {
    bottom: 220px;
    right: 55px;
  }

  @media screen and (max-width: ${screens.sm}) {
    bottom: 225px;
    right: 73px;
    max-width: 70px;
  }
`

// ====== UTILS ======

const setAnimation = (props, balloon, screen) => {
  const animations = {
    middle: null,
    left: null,
    right: null,
  };

  // Select which animation to play depending on screen size
  switch (screen) {
    // Mobile
    case SCREENS.MOBILE: {
      animations.middle = middleBalloonMobileAnimation;
      animations.left = leftBalloonMobileAnimation;
      animations.right = rightBalloonMobileAnimation;
      break;
    }
    // Tablet
    case SCREENS.TABLET: {
      animations.middle = middleBalloonTabletAnimation;
      animations.left = leftBalloonTabletAnimation;
      animations.right = rightBalloonTabletAnimation;
      break;
    }
    // Desktop
    case SCREENS.DESKTOP:
    default: {
      animations.middle = middleBalloonAnimation;
      animations.left = leftBalloonAnimation;
      animations.right = rightBalloonAnimation;
    }
  }

  // Select which animation to play based on balloon
  switch (balloon) {
    case BALLOONS.MIDDLE:
      return (
        props.startAnimation &&
        css`
          animation: ${animations.middle} 0.5s linear;
          animation-fill-mode: forwards;
        `
      )

    case BALLOONS.LEFT:
      return (
        props.startAnimation &&
        css`
          animation: ${animations.left} 0.5s linear;
          animation-fill-mode: forwards;
        `
      )

    case BALLOONS.RIGHT:
      return (
        props.startAnimation &&
        css`
          animation: ${animations.right} 0.5s linear;
          animation-fill-mode: forwards;
        `
      )
    default:
      return
  }
}
