import React from 'react'
import SubscribeSection from '../components/Subscribe/Thanks/index'
import PageTransition from '../components/PageTransition'

const Subscribe = () => {
  return (
    <PageTransition>
      <SubscribeSection />
    </PageTransition>
  )
}

export default Subscribe
