import React from "react";
import './svg3.scss'
const svg3 = () => {
  return (
    <>
      <svg
        version="1.1"
        id="el_FTCV8kTo3"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 195 194"
        style={{enableVackground:'new 0 0 195 194'}}
        xmlSpace="preserve"
      >
        <g
          id="el_otztlCRqoN_an_Iom32KocD"
          data-animator-group="true"
          data-animator-type="2"
        >
          <path
            d="M98.5,56.1c5.7,0,24.4-15.3,39.2-0.7c14.8,14.6-2.1,30.8-2.1,43.3c0,12.5-2.7,46.1-12.5,46.1s-11.2-37-24.4-37&#10;&#9;c-13.2,0-14.6,37-24.4,37s-12.5-33.5-12.5-46.1c0-12.5-16.9-28.7-2.1-43.3S92.8,56.1,98.5,56.1z"
            id="el_otztlCRqoN"
          />
        </g>
        <g
          id="el_M1iolMtFYm_an_yMS-3z5h-"
          data-animator-group="true"
          data-animator-type="2"
        >
          <g id="el_M1iolMtFYm">
            <path d="M97.5,24.4V0" id="el_ARU7slo6JD" />
            <path d="M97.5,193.9v-24.4" id="el_6H4na0kv5G" />
            <path d="M25,97H0.6" id="el_ZasmGalhpW" />
            <path d="M194.5,97h-24.4" id="el_GHZSZyQWht" />
            <path d="M148.9,45.6l17.3-17.3" id="el_BK_U73yT-U" />
            <path d="M28.9,165.6l17.3-17.3" id="el_hk_t1lXxSq" />
            <path d="M46.2,45.6L28.9,28.3" id="el_Vj3yWh-13G" />
            <path d="M166.2,165.6l-17.3-17.3" id="el_4xOFe42wRk" />
          </g>
        </g>
      </svg>
    </>
  );
};

export default svg3;
