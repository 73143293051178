import styled from 'styled-components'
import { colors, screens } from '../../../constants/theme'

export const CardBlogCss = styled.div`
  display: block;
`

export const CardFigureCss = styled.figure`
  height: 60vh;

  max-height: 500px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${screens.lg}) {
    height: 80vh;
  }
`

export const CardImageCss = styled.img`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
`

export const AuthorWrapperCss = styled.div`
  padding-top: 60px;
  display: flex;
`

export const AuthorText = styled.span`
  padding-right: 20px;
  white-space: nowrap;
  opacity: 0.7;
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 400;
`

export const AuthorLineSeparator = styled.span`
  height: 1px;
  width: 100%;
  margin-top: 5px;
  background: ${colors.blue};
  width: 35%;
`

export const CardPostTitleCss = styled.h2`
  margin-top: 30px;
  font-size: 1.4rem;
  line-height: 1.6;
  margin-bottom: 30px;
  max-width: 400px;
`

export const CardPostDescriptionCss = styled.p`
  max-width: 300px;
`
