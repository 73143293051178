import React from 'react'
import { Section1 } from '../components/Bryant'
import { Section2 } from '../components/Bryant'
import { Section3 } from '../components/Bryant'
import { Section4 } from '../components/Bryant'
import { Section5 } from '../components/Bryant'
import { Section6 } from '../components/Bryant'
import { Section7 } from '../components/Bryant'
import { Section8 } from '../components/Bryant'
import { Section9 } from '../components/Bryant'
import { Section10 } from '../components/Bryant'
import { Section11 } from '../components/Bryant'
import PageTransition from '../components/PageTransition';


const Bryant = () => {
  return (
    <PageTransition>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Section11 nextProjectTitle='Nova AI' link='/nova' />
    </PageTransition>
  )
}

export default Bryant
