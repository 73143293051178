import React from 'react';
import PageTransition from '../components/PageTransition';
import { Section1 } from "../components/ContactUs"

const ContactUs = () => {
  return (
    <PageTransition>
      <Section1/>
    </PageTransition>
  )
}

export default ContactUs
