import React, { useState, useEffect } from "react";
import { 
  SubscribeModalBackdropCss, 
  SubscribeModalContent, 
  CloseButtonCss,
  TitleCss,
  InputWrapperCss,
  InputLabelCss,
  InputEmailCss,
  NotifyButton,
  InputErrorCss
} from "./index.css.js";
import { createSubscriber } from '../../../firebase/firebase.utils';
import { useHistory } from 'react-router-dom';



const SubscribeModal = ({ isOpen = false, onClose }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  useEffect(() => {
    if(isOpen){
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.removeProperty("overflow");
    }
  }, [isOpen])

  if(!isOpen) {
    return null
  };

  const handleOnChange = (e) => {
    setEmail(e.target.value);
  }

  const handleOnClick = () => {
    createSubscriber(email, 'subscribers' )
      .then(() => {
        setEmail('');
        onClose();
        history.push(`subscribe?email=${email}`);
      })
      .catch(err => setError(err));
  }

  return (

    <SubscribeModalBackdropCss>
      <SubscribeModalContent>
        <CloseButtonCss onClick={onClose}>
          &#10005;
        </CloseButtonCss>
        <TitleCss>
          <span className="symbol">|</span>
          &nbsp; Catch up &nbsp;
          <span className="symbol">&lt;</span>
        </TitleCss>
        <h2>
          Subscribe to our newsletter and never miss the latest updates!
				</h2>
        <p>Get exclusive insights into our projects and the process behind them, find out the first about vacancies and stay updated with the latest news from Kappa. </p>
        <InputWrapperCss>
          <InputLabelCss>Email</InputLabelCss>
          <InputEmailCss placeholder="...your email address" value={email} onChange={(e) => handleOnChange(e)}/>
          <NotifyButton onClick={handleOnClick} disabled={email.length <= 5}>Notify me</NotifyButton>
        </InputWrapperCss>
        {error.length > 0 && <InputErrorCss>error coy</InputErrorCss> }
      </SubscribeModalContent>
    </SubscribeModalBackdropCss>
  )
}

export default SubscribeModal;