import { useEffect, useState } from 'react'
import htmlCss from './images/htmlCss.svg'
import designTools from './images/designTools.svg'
import javascript from './images/JavaScript.svg'
import advancedMarkup from './images/markup.svg'
import components from './images/components.svg'
import environments from './images/environments.svg'
import react from './images/react.svg'
import redux from './images/redux.svg'
import electron from './images/electron.svg'

// Custom hook to get the window size. Refer to https://usehooks.com/useWindowSize/
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    scrolled: 0,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
        scrolled: window.scrollY
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

export const cards = [
  {
    image: htmlCss,
    title: 'HTML & CSS',
    text1: '• Most Up-To-Date HTML & CSS practices',
    text2: '• Flexbox, Tailwind, Bootstrap',
    text3: '• CSS Animations',
  },
  {
    image: designTools,
    title: 'Design Tools',
    text1: '• Figma, Webflow & Related Tools, WP Themes Builders',
    text2: '• Prototyping, Mock and Information Architecture',
    text3: '• Notion, Self-Documenting, WIP, PH, MKLOG',
  },
  {
    image: javascript,
    title: 'Javascript Basics & Javascript Algorithms ',
    text1: '• ES5, ES6, Data Structures and the nitty-gritty ',
    text2: '• Document, Events, Interfaces',
  },
  {
    image: advancedMarkup,
    title: 'Advanced Markup',
    text1: '• Dom Manipulation, Traverse, Events',
    text2: '• Pop-ups, Methods, Cross-Window, Click-Jacking',
    text3: '• Compilers, Pre-Processors',
  },
  {
    image: components,
    title: 'Components Pt 1.',
    text1: '• Native Web Components & tricks ',
    text2: '• lit-html, lit-element & tricks',
  },
  {
    image: environments,
    title: 'Browser Tools, APIs & Chrome Extensions',
    text1: '• Node, NPM, Yarn, Webpack & Brunch',
    text2: '• Chrome Browser Tools a la carté ',
    text3: '• Chrome Extensions & Chrome APIs',
  },
  {
    image: react,
    title: 'Beginner & Advanced React',
    text1: '• Tooling, JSX, State, Router ',
    text2: '• Sockets, Modules, Netlify',
  },
  {
    image: redux,
    title: 'Redux & Mobx State Management ',
    text1: '• Patterns, GraphQL, Appollo',
    text2: '• Next, Gatsby, Styled',
    text3: '• Testing',
  },
  {
    image: electron,
    title: 'Electron ',
  },
]
