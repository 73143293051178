import styled from "styled-components";
import { screens } from "../../../constants/theme";

export const SixthSectionWrapperCss = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h4 {
    margin-bottom: 100px;
    @media (max-width: ${screens.md}) {
      margin: 0px 0px 48px 20px;
    }
  }

  @media (max-width: ${screens.md}) {
    padding: 20px 0px 0px;
  }
`;

export const BlockCss = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 105px;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;

  section {
    margin: 40px 0px;
  }

  @media (max-width: ${screens.md}) {
    // flex-direction: column-reverse;
    section {
      margin: 0px;
    }
  }
`;

export const InfoCss = styled.div`
  margin: 0 auto;
  div {
    max-width: 648px;
    h1 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 42px;
      color: #f5f5f5;
      margin-bottom: 25px;
      @media (max-width: ${screens.md}) {
        font-size: 26px;
        line-height: 34px;
      }
    }
    h2 {
      margin-bottom: 25px;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 36px;
      color: #f5f5f5;
      @media (max-width: ${screens.md}) {
        font-size: 24px;
        line-height: 31px;
      }
    }
    .mB {
      margin-bottom: 100px;
      @media (max-width: ${screens.md}) {
        margin-bottom: 50px;

      }
    }
    p {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      color: #9b9999;
      @media (max-width: ${screens.md}) {
        font-size: 16px;
        line-height: 27px;
      }
    }
  }
  @media (max-width: ${screens.md}) {
    margin: 0px 25px;
    width: auto;

    h2 {
      margin-bottom: 0px;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 34px;
    }

    a {
      margin-top: 30px;
    }
  }
`;

export const VideoBlockCss = styled.div`
  max-width: 872px;
  margin: 0 auto;

  @media (max-width: ${screens.md}) {
  	margin:0 25px;
  }
  .vid1 {
  	width: 100%;
  	border-radius: 25px;
    margin-bottom: 100px;
    margin-right: 50px;
  	border-radius: 25px;

    @media (max-width: ${screens.md}) {
      margin-right: 0px;
      margin-bottom: 50px;
    }
  }

  .vid2{
  	width:100%;
  	border-radius:25px;
    margin-left:50px;
  	border-radius:25px;
    @media (max-width: ${screens.md}) {
      margin-left:0px;
    }
  }
`;
