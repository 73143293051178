import styled from 'styled-components'

export const SubscribePageWrapperCss = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 700px;
  min-height: calc(100vh - 80px);
  padding: 15px;

  h1 {
    color: #0e0e0e;
    text-align: center;
    cursor: initial !important;
    margin-bottom: 40px;
  }
  h1 span {
    font-size: 1.4rem;
  }
`
