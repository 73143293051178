import React from "react";
import {
  EleventhSectionWrapperCss,
  BlockCss,
  InfoCss,
  ImgBlockCss,
} from "./index.css";
import img1 from "./assets/image1.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PrimaryLinkCss } from "../../../layout/index.css";

const EleventhSection = ({ nextProjectTitle, link }) => {
  return (
    <EleventhSectionWrapperCss>
      <BlockCss>
        <ImgBlockCss>
          <LazyLoadImage
            src={img1}
            alt=""
            threshold={-100}
            beforeLoad={() => console.log("load")}
          />
        </ImgBlockCss>
        <InfoCss>
          <div>
            <h1>Thank you for scrolling this far</h1>
            <PrimaryLinkCss to="/contact-us">
              Start a project with us
            </PrimaryLinkCss>
          </div>
        </InfoCss>
        <InfoCss>
          <div>
            <h1>{nextProjectTitle}</h1>
            <PrimaryLinkCss as="a" to="" href={link}>
              Go to project
            </PrimaryLinkCss>
          </div>
        </InfoCss>
      </BlockCss>
    </EleventhSectionWrapperCss>
  );
};

export default EleventhSection;
