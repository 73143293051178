import styled from 'styled-components'
import { screens, colors } from '../../../constants/theme'

export const FooterBlockInfoCss = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;

  h2 {
    margin-top: 13px;
  }
`

export const FooterBlockLinksCss = styled.div`
  display: flex;
  width: 70%;
  flex-wrap: wrap;

  p {
    border-bottom: 2px solid ${colors.grey};

    height: fit-content;
    padding: 0px 0px 4px;
    margin-top: -2px;
    margin-right: 50px;
    margin-bottom: 20px;
  }

  &&& a {
    padding: 7px 0px;
  }

  @media (max-width: ${screens.lg}) {
    margin: 0px;
    width: 90%;
  }

  @media screen and (max-width: ${screens.md}) {
    flex-direction: column;
    height: 150px;
    justify-content: space-evenly;
  }
`
