import React, { useEffect, useRef, useState } from 'react'
import {
  FAQWrapper,
  IconCss,
  Answer,
  ToggleAccordion,
  Question,
} from './index.css'
import { FAQs } from './data'
import SectionLayout from '../section-layout/section-layout'

const InfoBlock = (props) => {
  const [show, setShow] = useState(false)
  const childElement = useRef(null)
  const headerElement = useRef(null)
  useEffect(() => {
    if (props.opened !== props.id && show) {
      setShow(false)
    }
  }, [props.opened, props.id, show])

  const clickHandle = () => {
    if (props.opened === props.id) {
      setShow(!show)
    } else {
      props.setOpened(props.id)
      setShow(true)
      // setTimeout(
      //   () =>
      //     window.scrollTo({
      //       top:
      //         headerElement.current.offsetTop -
      //         headerElement.current.offsetHeight / 2,
      //     }),
      //   500
      // )
    }
  }

  return (
    <FAQWrapper>
      <ToggleAccordion ref={headerElement}>
        <IconCss show={show} onClick={clickHandle} />
        <Question>
          <h3 onClick={clickHandle}>{props.question}</h3>
        </Question>

      </ToggleAccordion>

      <Answer
          show={show}
          childrenHeight={
            childElement.current && childElement.current.scrollHeight
          }
        >
          <div ref={childElement}>{props.children}</div>
        </Answer>
    </FAQWrapper>
  )
}

const Content = () => {
  const [opened, setOpened] = useState(0)
  return (
    <>
      {/* Questions */}
      {FAQs.map((FAQ) => (
        <InfoBlock
          key={FAQ.id}
          question={FAQ.question}
          setOpened={setOpened}
          opened={opened}
          id={FAQ.id}
        >
          <p>{FAQ.answer}</p>
        </InfoBlock>
      ))}
    </>
  )
}

const InternshipSection13 = () => {
  return (
    <SectionLayout
      leftTitle="FAQs"
      rightTitle="Got questions?"
      RightComponent={Content}
    />
  )
}

export default InternshipSection13
