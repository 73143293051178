import styled, { keyframes } from 'styled-components'
import { colors, screens } from '../../../constants/theme'

const SCREEN_PADDING = '40px'

export const Paragraph = styled.p`
  &&& {
    font-size: 16px;
    color: ${colors.grey4};
  }
`

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${SCREEN_PADDING} 0px;
  max-width: 640px;

  @media screen and (max-width: ${screens.sm}) {
    width: 100%;
  }
`

const topBarAnimation = keyframes`
    from {
        top: -20px;
        transform: rotate(-2deg);
    }
    to {
        top: 0px;
        transform: rotate(0deg);
    }
`

export const TopBar = styled.img`
  position: relative;
  top: -20px;
  transform: rotate(-2deg);
  animation: ${topBarAnimation} 0.5s forwards;
`

const microphoneAnimation = keyframes`
  from {
    transform: rotate(30deg);
    bottom: calc(${SCREEN_PADDING} + 35px);
    left: -10px;
  }

  to {
    transform: rotate(0deg);
    bottom: calc(${SCREEN_PADDING} + 20px);
    left: 20px;
  }
`

export const Microphone = styled.div`
  width: 16px;
  height: 24px;

  // Positioning - just so it keeps the same aspect when scrolling past them.
  position: absolute;
  transform: rotate(30deg);
  bottom: calc(${SCREEN_PADDING} + 35px);
  left: -10px;

  img {
    @media screen and (max-width: ${screens.xs}) {
      transform: scale(0.8, 0.8);
    }
  }
`

export const AnimatedMicrophone = styled.div`
  position: absolute;
  transform: rotate(30deg);
  bottom: calc(${SCREEN_PADDING} + 35px);
  left: -10px;
  animation: ${microphoneAnimation} forwards;
  animation-delay: 0.5s;
  animation-duration: 1.5s;

  // Overwrite the properties for positioning
  div {
    position: unset;
    transform: unset;
    bottom: unset;
    left: unset;
  }
`

const webCamAnimation = keyframes`
  from {
    bottom: 20px;
    left: 35px;
  }

  to {
    bottom: calc(${SCREEN_PADDING} + 20px);
    left: 50px;
  }
`

export const WebCam = styled.div`
  width: 27px;
  height: 18px;

  // Positioning - just so it keeps the same aspect when scrolling past them.
  position: absolute;
  bottom: 20px;
  left: 35px;

  @media screen and (max-width: ${screens.xs}) {
    transform: scale(0.8, 0.8);
  }
`

export const AnimatedWebCam = styled.div`
  position: absolute;
  bottom: 20px;
  left: 35px;
  animation: ${webCamAnimation} forwards;
  animation-delay: 0.5s;
  animation-duration: 1.5s;

  // Overwrite the properties for positioning
  div {
    position: unset;
    bottom: unset;
    left: unset;
  }
`

const camViewAnimation = keyframes`
  from {
    right: -20px;
    transform: rotate(27deg);
  }

  to {
    right: 0px;
    transform: rotate(0deg);
  }
`

const narrowScreenCamViewAnimation = keyframes`
  from {
    transform: scale(0.7, 0.7) rotate(27deg);
  }

  to {
    transform: scale(0.7, 0.7) rotate(0deg);
  }
`

const CAM_VIEW_WIDTH = 136
const CAM_VIEW_HEIGHT = 94

export const CamView = styled.div`
  max-width: ${CAM_VIEW_WIDTH}px;
  max-height: ${CAM_VIEW_HEIGHT}px;

  // Positioning - just so it keeps the same aspect when scrolling past them.
  position: absolute;
  bottom: ${SCREEN_PADDING};
  right: -20px;
  transform: rotate(27deg);

  @media screen and (max-width: ${screens.xs}) {
    transform: scale(0.7, 0.7) rotate(27deg);
  }

  img {
    max-width: ${CAM_VIEW_WIDTH}px;
    max-height: ${CAM_VIEW_HEIGHT}px;
  }
`

export const AnimatedCamView = styled.div`
  position: absolute;
  bottom: ${SCREEN_PADDING};
  right: -20px;
  transform: rotate(27deg);
  animation: ${camViewAnimation} forwards;
  animation-delay: 0.5s;
  animation-duration: 1.5s;

  div {
    position: relative;
    bottom: unset;
    right: unset;
    transform: rotate(0deg);
  }

  @media screen and (max-width: ${screens.xs}) {
    transform: scale(0.7, 0.7) rotate(27deg);
    bottom: calc(
      ${SCREEN_PADDING} - ${(CAM_VIEW_HEIGHT - CAM_VIEW_HEIGHT * 0.7) / 2}px
    );
    right: -${(CAM_VIEW_WIDTH - CAM_VIEW_WIDTH * 0.7) / 2}px;
    animation: ${narrowScreenCamViewAnimation} forwards;
    animation-delay: 0.5s;
    animation-duration: 1.5s;
  }
`
