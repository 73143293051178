import React from "react";
import {
  Section1,
  Section2,
  Section3,
  Section4,
  Section5
} from "../components/Homepage";
import PageTransition from "../components/PageTransition";

const Homepage = () => {
  return (
    <PageTransition>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </PageTransition>
  )
}

export default Homepage
