import styled, { keyframes } from 'styled-components/macro'
import { screens, colors } from '../../../constants/theme'
import { motion } from "framer-motion"

const blink = keyframes`
  0% {
      opacity: 1
  }

  25% {
      opacity: 0
  }

  50% {
      opacity: 0
  }

  to {
      opacity: 1
  }
`

export const Section1ContainerCss = styled.div`
  margin: 180px 0 50px;

  @media (max-width: ${screens.lg}) {
    margin: 100px 0 25px;
  }
`

export const FormWrapperCss = styled.div`
  display: grid;
  row-gap: 2rem;
  width: 100%;
  font-size: 18px;
`

export const LabelCss = styled(motion.p)`
  color: ${colors.white};
  margin: 0;

  span {
    &.default {
      color: #68d391;

      svg {
        fill: #68d391;
      }
    }

    &.loading {
      color: #63b3ed;
    }

    &.error {
      color: #fc8181;
    }
  }
`

export const LabelMarkCss = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
`

export const InputWrapperCss = styled(motion.div)`
  display: flex;
`;

export const InputValuWrapperCss = styled.div``;

export const RealInputCss = styled.input`
  caret-color: transparent;
  opacity: 0;
  width: 2px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  background-color: transparent;
`

export const FakeInputCss = styled.span`
  color: ${colors.blue};
`

export const DollarSignCss = styled.span`
  color: ${colors.grey3};
`

export const CursorCss = styled.span`
  background-color: ${colors.blue};
  text-shadow: 0 0.2rem 1rem #5f0acb;
  animation: ${blink} 1s linear infinite;
`







