import React from 'react';
import {
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  Section6,
  Section7,
  Section8,
} from '../components/Bloomd';
import { Section11 as NovaLinkFooter } from '../components/Bryant';
import PageTransition from '../components/PageTransition'


const Bloomd = () => {
  return (
    <PageTransition>
      <div style={{ overflow: 'hidden' }}>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <NovaLinkFooter nextProjectTitle='Bryant Dental' link='/bryant' />
      </div>
    </PageTransition>
  );
};

export default Bloomd;
