import React from 'react'
import SectionLayout from '../section-layout/section-layout'
import gif from './assets/tenor.gif'
import * as css from './index.css';
import camView from './assets/cam-view.svg';
import microphone from './assets/microphone.svg';
import webCam from './assets/web-cam.svg';
import topBar from './assets/top-bar.svg';
import { useOnScreen } from '../section5';

const Content = () => {
  const [ref, visible] = useOnScreen({ threshold: 1 })

  return (
    <>
      <css.Paragraph>
        Our team has been working remotely for more than six years. During these
        tough times, it feels like the world is catching up with us. Pilots are
        flying paper planes in their backyard and firefighters are putting out
        candles over dinner.
      </css.Paragraph>

      <css.Screen ref={ref}>
        {
          visible ?
            <>
              {/* Animated ones */}
              <css.TopBar src={topBar} alt="Window top bar" />
              <img src={gif} alt="Remote work gif" />

              {/* Microphone */}
              <css.AnimatedMicrophone>
                <css.Microphone>
                  <img src={microphone} alt="Microphone"/>
                </css.Microphone>
              </css.AnimatedMicrophone>

              {/* Web Cam */}
              <css.AnimatedWebCam>
                <css.WebCam>
                  <img src={webCam} alt="Web cam"/>
                </css.WebCam>
              </css.AnimatedWebCam>

              {/* Cam View */}
              <css.AnimatedCamView>
                <css.CamView>
                  <img src={camView} alt="Camera view"/>
                </css.CamView>
              </css.AnimatedCamView>
            </>
            :
            <>
              <css.TopBar src={topBar} alt="Window top bar" />
              <img src={gif} alt="Remote work gif" />

              {/* Microphone */}
              <css.Microphone>
                <img src={microphone} alt="Microphone"/>
              </css.Microphone>

              {/* Web Cam */}
              <css.WebCam>
                <img src={webCam} alt="Web cam"/>
              </css.WebCam>

              {/* Cam View */}
              <css.CamView>
                <img src={camView} alt="Camera view"/>
              </css.CamView>
            </>
        }
      </css.Screen>

      <css.Paragraph>
        Kappa is a fully COVID compliant company because… well… we’re all
        pretty far apart from each other.
      </css.Paragraph>
    </>
  )
}

const InternshipSection9 = () => {
  return (
    <SectionLayout
      leftTitle="COVID-proof"
      rightTitle="Remote from day 0"
      RightComponent={Content}
    />
  )
}

export default InternshipSection9
