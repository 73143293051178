import React, { useState, useEffect } from 'react'
import * as css from './intern-card.css'
import eyeSVG from './images/eye.svg';
import { useComponentVisible } from '../section6/card';

const InternCard = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showTestimony, setShowTestimony] = useState(false);

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  useEffect(() => {
    document.body.style.overflow = isComponentVisible ? 'hidden' : 'unset';
  }, [isComponentVisible]);

  // Pre-Load Hover Image
  useEffect(() => {
    const newImage = new Image();
    newImage.src = imageHover;
    window[imageHover] = newImage;
  })

  function showVideo(isVisible, video, image, imageHover) {
    if (isVisible) {
      return (
        <>
          <css.TestimonialVideoBackdrop>
            <css.TestimonialVideoContent>
              <css.CloseButtonCss onClick={() => setIsComponentVisible(false)}>
                &#10005;
              </css.CloseButtonCss>
              <css.TestimonialVideo src={video} autoPlay={false}></css.TestimonialVideo>
            </css.TestimonialVideoContent>
          </css.TestimonialVideoBackdrop>
          <css.ProfileImage src={isHovered ? imageHover :image}
            alt={`Profile image for ${name}`}
            onMouseEnter={() => {setIsHovered(true)}}
            onMouseLeave={() => {setIsHovered(false)}}
            onClick={() => {
              setIsComponentVisible(true);
              setIsHovered(false);
            }}  
          />
        </>
      );
    }

    return (
      <css.ProfileImage src={isHovered ? imageHover :image}
          alt={`Profile image for ${name}`}
          onMouseEnter={() => {setIsHovered(true)}}
          onMouseLeave={() => {setIsHovered(false)}}
          onClick={() => {
            setIsComponentVisible(true);
            setIsHovered(false);
          }}
        />
    )
  }

  function toggleTestimony(ev) {
    ev.stopPropagation();

    testimony && setShowTestimony(!showTestimony)
  }

  const {
    image,
    imageHover = image,
    name,
    technologyStack,
    isLive,
    video,
    testimony
  } = props;

  return (
    <css.CardWrapper onClick={() => testimony && setShowTestimony(false)}
      showOverflow={isComponentVisible}>
      {/* Image */}
      {image && showVideo(isComponentVisible, video, image, imageHover)}

      {/* Testimonial Video Button */}
      {
        video &&
        <css.TestimonialVideoButton ref={ref} onClick={() => setIsComponentVisible(true)}>
          <span>Watch</span>
          <img src={eyeSVG} alt="" />
        </css.TestimonialVideoButton>
      }

      {/* Testimony */}
      {
        showTestimony &&
          <css.TestimonyUpper>
            <css.TestimonyQuote>
              {testimony}
            </css.TestimonyQuote>
          </css.TestimonyUpper>
      }

      {/* Name */}
      <css.Name>{name}</css.Name>

      {/* Description content - it has a slide to left animation when testimony is shown */}
      {
        !showTestimony ?
          <css.Description onClick={toggleTestimony}>
            {/* Technology stack */}
            <css.TechnologyStack>
              {technologyStack.map((tech, key) => (
                <css.Technology key={key}>{tech}</css.Technology>
              ))}
            </css.TechnologyStack>

            {/* Live tag */}
            {isLive && <css.LiveTag>live</css.LiveTag>}
          </css.Description>
          :
          <css.PushedDescription>
            {/* Technology stack */}
            <css.TechnologyStack>
              {technologyStack.map((tech, key) => (
                <css.Technology key={key}>{tech}</css.Technology>
              ))}
            </css.TechnologyStack>

            {/* Live tag */}
            {isLive && <css.LiveTag>live</css.LiveTag>}
          </css.PushedDescription>
      }

      {/* Apply button */}
      {
        showTestimony &&
        <css.TestimonyLower>
          <css.ApplyButton as="a" to="" href="mailto:captain@kappa.work">Apply</css.ApplyButton>
        </css.TestimonyLower>
      }
    </css.CardWrapper>
  )
}

export default InternCard
