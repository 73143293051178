import styled, { css } from 'styled-components'

export const STAND_WIDTH = '483px';
const WHEEL_DIMENSIONS = '198px';
export const SPACING = '67px';

export const HamsterMechanismCss = styled.div`
  position: relative;
  max-width: ${STAND_WIDTH};
  margin-top: 80px;

  @media screen and (max-width: calc(${STAND_WIDTH} + ${SPACING})) {
    width: 100%;
  }
`

export const StandCss = styled.img`
  @media screen and (max-width: calc(${STAND_WIDTH} + ${SPACING})) {
    max-width: 100%;
  }
`

const HamsterWheelCss = styled.img`
  width: ${WHEEL_DIMENSIONS};
  height: ${WHEEL_DIMENSIONS};
  border-radius: 50%;
  position: absolute;
`

export const LeftHamsterCss = styled(HamsterWheelCss)`
  top: 2px;
  left: 2px;
  ${props => scaleDown(props)}
`

export const RightHamsterCss = styled(HamsterWheelCss)`
  top: 2px;
  right: 2px;
  ${props => scaleDown(props, true)}
`

// ====== UTILS ======
const scaleDown = (props, isLast) => {
  return props.ratio && css`
    transform-origin: ${isLast ? '100% 0%' : '0% 0%'};
    transform: scale(${props.ratio}, ${props.ratio});
  `;
}
