import React from "react";
import BlogPageSection from "../components/BlogPage";
import PageTransition from '../components/PageTransition'

const BlogPage = () => {
  return (
    <PageTransition>
      <BlogPageSection />;
    </PageTransition>
  )
};

export default BlogPage;
