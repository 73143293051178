import React from 'react'
import * as css from './index.css'
import gif from './assets/p1.gif'
import Container from '../../Container/index'
import Lottie from 'react-lottie'
import ladder from './assets/ladder.json'
import plotter from './assets/plotter.json'

const InternshipSection10 = () => {
  const banner = [
    'self_discipline',
    'time_management',
    'self_organisation',
    'team_work',
    'efficient_communication',
    'fast_learning_techniques',
    'taking_initiative',
  ]

  /**
   * Add a Ladder or Plotter animation - depending on
   * @param {number} index
   */
  function addAnimation(index) {
    // Plotter
    if (index === 3) {
      const plotterOptions = {
        loop: true,
        autoplay: true,
        animationData: plotter,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }

      return (
        <css.LottieAnimation>
          <Lottie options={plotterOptions} height={67} width={67} />
        </css.LottieAnimation>
      )
    }

    // Ladder
    if (index === 6) {
      const ladderOptions = {
        loop: true,
        autoplay: true,
        animationData: ladder,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }

      return (
        <css.LottieAnimation>
          <Lottie options={ladderOptions} height={67} width={67} />
        </css.LottieAnimation>
      )
    }
  }

  return (
    <css.Background>
      <Container>
        <css.AlignContent>
          <css.GifWrapper>
            <css.MaskedGif src={gif} alt="Work from anywhere gif" />
          </css.GifWrapper>

          <css.Content>
            <h3>Working remotely has its perks but…</h3>

            <p>
              … it also has its challenges. We’ll teach you the best practices
              so you can be as efficient and productive as possible.
            </p>
          </css.Content>
        </css.AlignContent>
      </Container>

      {/* Rolling banner */}
      <css.BannerWrapper>
        <css.RollingBanner>
          {banner.map((trait, index) => (
            <css.BannerElement isFirst={index === 0} key={index}>
              {/* Ladder or Plotter animation - depending on index */}
              {addAnimation(index)}

              {/* Content */}
              <span className="symbol">|&nbsp;</span>
                {trait}&nbsp;
              <span>&lt;</span>
            </css.BannerElement>
          ))}
        </css.RollingBanner>

        {/* Second banner to give the continuity effect */}
        <css.RollingBanner>
          {banner.map((trait, index) => (
            <css.BannerElement isFirst={false} key={index}>
              {/* Ladder or Plotter animation - depending on index */}
              {addAnimation(index)}

              {/* Content */}
              <span className="symbol">|&nbsp;</span>
                {trait}&nbsp;
              <span>&lt;</span>
            </css.BannerElement>
          ))}
        </css.RollingBanner>
      </css.BannerWrapper>
    </css.Background>
  )
}

export default InternshipSection10
