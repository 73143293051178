import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PrimaryLinkCss } from '../../../layout/index.css';
import { SubscribePageWrapperCss } from './index.css';
import { createSubscriber } from '../../../firebase/firebase.utils';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SubscribeSection = () => {
    const [subscriber, setSubscriber] = useState(useQuery().get('email'));
    const pathName = window.location.pathname === '/subscribe' ? true : false;
    useEffect(() => {
        createSubscriber(subscriber, pathName ? 'subscribers' : 'unsubscribers');
    }, [subscriber, pathName]);
    return (
        <SubscribePageWrapperCss>
            {pathName ? (
                <h1>Thank you for subscribing to our newsletter.</h1>
            ) : (
                <h1>
                    Sorry to see you go.
                    <br />
                    <span>You have successfully unsubscribed to our newsletter.</span>
                </h1>
            )}

            <PrimaryLinkCss
                as="a"
                target="_blank"
                href="https://kappa.work/projects"
                to="https://kappa.work/projects"
            >
                Check our projects
            </PrimaryLinkCss>
        </SubscribePageWrapperCss>
    );
};

export default SubscribeSection;
