import React, { useState, useEffect, useRef } from 'react'
import { screens } from '../../../constants/theme';
import {
  CardWrapperCss,
  ExpandCss,
  ImageWrapperCss,
  TitleWrapperCss,
  TextWrapperCss,
  PrimaryLinkCss,
  LinkWrapperCss,
  CardCss,
} from './card.css'

// Define the positions enum:
export const OVERLAY_POSITIONS = {
  'TOP_LEFT': 'top-left',
  'TOP_RIGHT': 'top-right',
  'BOTTOM_LEFT': 'bottom-left',
  'BOTTOM_RIGHT': 'bottom-right',
  'CARD_CENTERED': 'card-centered',
  'PAGE_CENTERED': 'page-centered'
}
Object.freeze(OVERLAY_POSITIONS);

// Hook for closing when clicking outside the overlay element
export function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false)
    }
  }

  // Add the close listener
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [initialIsVisible])

  return { ref, isComponentVisible, setIsComponentVisible }
}

// Sets the position of the overlay so it doesn't overflow
function useOverlayPosition(ref, windowDimensions) {
  const [position, setPosition] = useState(OVERLAY_POSITIONS.CARD_CENTERED)

  useEffect(() => {
    const { width: screenWidth, height: screenHeight /*, scrolled*/ } = windowDimensions
    // console.log('+++ ref.current', ref.current)
    // console.log('+++ windowDimensions', windowDimensions)
    // // console.log('+++ scrolled', scrolled);
    // console.log(
    //   '+++ ref.current.getBoundingClientRect()',
    //   ref.current.getBoundingClientRect()
    // )
    const { left, right, y, height: cardHeight } = ref.current.getBoundingClientRect()

    const smallScreenWidth = parseInt(screens.sm);
    if (screenWidth <= smallScreenWidth) {
      // console.log('+++ set centered for', ref.current);
      setPosition(OVERLAY_POSITIONS.PAGE_CENTERED);
      return;
    }

    // Check if we have enough space on the LEFT side
    if (left < 125) {
      // Check if we have enough space BELOW
      if (screenHeight - (y + cardHeight) < 200) {
        // console.log('+++ settting BOTTOM LEFT for', ref.current);
        setPosition(OVERLAY_POSITIONS.BOTTOM_LEFT)
        return;
      }
      // console.log('+++ settting TOP LEFT for', ref.current);
      setPosition(OVERLAY_POSITIONS.TOP_LEFT)
      return;
    }

    // Check if we have enough space on the RIGHT side
    if (screenWidth - right < 125) {
      // Check if we have enough space BELOW
      if (screenHeight - (y + cardHeight) < 200) {
        // console.log('+++ settting BOTTOM RIGHT for', ref.current);
        setPosition(OVERLAY_POSITIONS.BOTTOM_RIGHT)
        return;
      }
      // console.log('+++ settting TOP RIGHT for', ref.current);
      setPosition(OVERLAY_POSITIONS.TOP_RIGHT)
      return;
    }
  }, [ref, windowDimensions, windowDimensions.width])

  return { position }
}

const Card = (props) => {
  const { cardInfo, windowDimensions } = props
  const { image, title, text1, text2, text3 } = cardInfo
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false)

  const { position } = useOverlayPosition(ref, windowDimensions)
  // console.log('+++ position', position)

  return (
    <CardWrapperCss ref={ref} onClick={() => setIsComponentVisible(true)}>
      <CardCss>
        <ImageWrapperCss>
          <img src={image} alt={title} />
        </ImageWrapperCss>
        <h4> {title} </h4>

        {isComponentVisible && (
          <ExpandCss isOpen={isComponentVisible} position={position}>
            <ImageWrapperCss>
              <img src={image} alt={title} />
            </ImageWrapperCss>

            <TitleWrapperCss>
              <h4> {title} </h4>
            </TitleWrapperCss>

            <TextWrapperCss>
              <p>{text1}</p>
              <p>{text2}</p>
              <p>{text3}</p>
            </TextWrapperCss>
            <LinkWrapperCss>
              <PrimaryLinkCss href="mailto:captain@kappa.work">
                Learn now
              </PrimaryLinkCss>
            </LinkWrapperCss>
          </ExpandCss>
        )}
      </CardCss>
    </CardWrapperCss>
  )
}

export default Card
