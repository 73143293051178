import {useEffect, useState} from "react";
const FEED = "https://medium.com/feed/kappa-london";

export const useBlogs = (id = null) => {
  const [posts, setPosts] = useState([]);
  const [status, setStatus] = useState();
  const [detail, setDetail] = useState(null);

  useEffect(() => {
    setStatus('loading');
    fetch(`https://api.rss2json.com/v1/api.json?rss_url=${FEED}`)
      .then((response) => {
        if (!response.ok) {
          debugger;
          setStatus('error');
          throw Error("Network request failed");
        }
        return response;
      })
      .then((data) => data.json())
      .then((data) => {
        const dataItems = data.items;
        setPosts(dataItems);
        if(!!id){
          setDetail(dataItems.find((item) => item.guid.includes(id)))
        }
        setStatus('success');
      });
  }, []);
  
  return { posts, status, detail } 
}
