import React from 'react'
import {
  SectionLayoutCss,
  LeftSideCss,
  RightSideCss,
} from './section-layout.css'
import Container from '../../Container/index'

const SectionLayout = (props) => {
  const { title } = props
  return (
    <Container>
      <SectionLayoutCss>
        <LeftSideCss>
          <span className="symbol">|</span>
          &nbsp;{title}&nbsp;
          <span className="symbol">&lt;</span>
        </LeftSideCss>

        <RightSideCss>{props.children}</RightSideCss>
      </SectionLayoutCss>
    </Container>
  )
}

export default SectionLayout
