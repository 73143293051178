import React from 'react'
import {
  CardsContainerCss,
} from './index.css'
import InternCard from './intern-card'
import Dan from './images/dan_bizu.jpg';
import DanGif from './images/dan.gif';
import Albert from './images/albert_luta.jpg';
import AlbertGif from './images/albert.gif';
import SectionLayout from '../section-layout/section-layout';

const Content = () => {
  const interns = [
    {
      image: Dan,
      imageHover: DanGif,
      name: 'Dan Bizu',
      technologyStack: ['HTML', 'CSS', 'JavaScript', 'ReactJS'],
      isLive: false,
      video: '//www.youtube.com/embed/HIHG3i91D4o?autoplay=1&cc_load_policy=1&controls=1&disablekb=0&enablejsapi=0&fs=1&iv_load_policy=1&loop=0&rel=0&showinfo=1&start=0&wmode=transparent&theme=dark&mute=0'
    },
    {
      image: Albert,
      imageHover: AlbertGif,
      name: 'Albert Luta',
      technologyStack: ['TypeScript', 'HTML', 'CSS', 'JavaScript', 'ReactJS'],
      isLive: false,
      video: '//www.youtube.com/embed/Knw1iUxHwhk?autoplay=1&cc_load_policy=1&controls=1&disablekb=0&enablejsapi=0&fs=1&iv_load_policy=1&loop=0&rel=0&showinfo=1&start=0&wmode=transparent&theme=dark&mute=0',
      testimony: 'I enjoyed that you pushed me. It really helped me perform.'
    },
  ]

  return (
    <CardsContainerCss>
      {
		    interns.map((intern, key) =>
      	  <InternCard key={key} {...intern} />
      	)
		  }
    </CardsContainerCss>
  )
}

const InternshipSection8 = () => {
  return (
    <SectionLayout rightTitle='Meet our interns'
      RightComponent={Content}
    />
  )
}

export default InternshipSection8
