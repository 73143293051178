import styled, { css } from 'styled-components';
import { colors } from '../../constants/theme';

export const BookVladPageWrapperCss = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 700px;
  min-height: calc(100vh - 80px);
  padding: 15px;

  h1 {
    color: #0e0e0e;
    text-align: center;
    cursor: initial !important;
    margin-bottom: 40px;
  }
  h1 span {
    font-size: 1.4rem;
  }
`;
const baseInputStyles = css`
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.03em;
  color: ${colors.white};
  border: none;
  background: none;
`;

export const BookVladInputText = styled.input`
  margin-top: 20px;
  ${baseInputStyles}
  border-bottom: 2px solid ${colors.blue};
  min-width: 300px;

  &:focus {
    outline: none;
  }
`;

export const BookVladInputSubmit = styled.input`
  cursor: pointer;
  ${baseInputStyles}
  font-style: italic;
  font-weight: normal;
  margin-left: 25px;
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid ${colors.blue};
  }

  @media (max-width: 440px) {
    margin-left: 0;
    margin-top: 20px;
    display: block;
    width: 100%;
    text-align: center;
  }
`;
export const BookVladAcknowledge = styled.span`
  text-align: center;
  color: ${colors.white};
  margin-top: 50px;
  opacity: 0.5;
`;
