import styled from 'styled-components'
import { screens, colors } from '../../constants/theme'
import { PrimaryLinkCss } from '../../layout/index.css'

export const FooterWrapperCss = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;

  h4 {
    white-space: nowrap;
    color: ${colors.white};

    &.grey {
      color: ${colors.grey};
    }

    span.symbol {
      color: ${colors.blue};
      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  h2 {
    color: ${colors.white};
    margin-top: 0px;
    margin-bottom: 72px;
    i {
      border-bottom: 2px solid ${colors.grey};
    }
    @media (max-width: ${screens.lg}) {
      margin-bottom: 50px;
    }
  }
`

export const BottomFooter = styled.div`
  background-color: #1f1f26;
  display: flex;
  justify-content: space-between;
  color: white;
  font-style: italic;
  padding: 25px 8.33vw 0;
  @media (max-width: ${screens.lg}) {
    padding: 25px 40px 0;
  }
  @media (max-width: ${screens.sm}) {
    padding: 25px 20px 0;
  }
  div {
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.03em;
    text-decoration: none;
    width: fit-content;
    color: ${colors.white};
    display: inline-block;

    @media (max-width: ${screens.sm}) {
      font-size: 14px;
    }
  }
`

export const FooterBlockCss = styled.div`
  display: flex;
  margin-top: 3%;
  margin-bottom: 7%;

  @media (max-width: ${screens.sm}) {
    margin-top: 1%;
    margin-bottom: 1%;
  }

  @media (max-width: ${screens.lg}) {
    flex-direction: column;
    margin: 0px 25px 40px;
  }
`

export const FooterBlockTitleCss = styled.h4`
  padding-top: 20px;
  display: flex;
  width: 100%;
  max-width: 16.66vw;

  margin: 0px 8.33vw;

  @media (max-width: ${screens.lg}) {
    margin: 0px 0px 50px;
    max-width: 100%;
  }
`

export const FooterBlockInfoCss = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;
  gap: 10px;
  @media (max-width: ${screens.sm}) {
    gap: 2px;
  }
`

export const FooterBlockLinksCss = styled.div`
  display: flex;
  width: 70%;
  flex-wrap: wrap;
  gap: 10px;
  @media (max-width: ${screens.sm}) {
    gap: 2px;
  }

  div,
  p {
    border-bottom: 2px solid ${colors.grey};

    height: fit-content;
    padding: 0px 0px 4px;
    margin-top: -2px;
    margin-right: 50px;
    margin-bottom: 20px;

    @media (max-width: ${screens.lg}) {
      font-size: 14px !important;
    }
  }

  @media (max-width: ${screens.lg}) {
    margin: 0px;
    width: 90%;
  }
`

export const FooterMenuCss = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${screens.lg}) {
    a {
      font-size: 14px !important;
    }
  }
`

export const FooterMenuImage = styled(PrimaryLinkCss)`
  padding: 0px;
  margin-right: 0px;
`

export const FooterLogoCss = styled.div`
  @media (max-width: ${screens.lg}) {
    margin-top: 10px;
  }
`
