import React from 'react'
import Container from '../../Container'
import * as css from './section-layout.css'

const SectionLayout = ({
  LeftComponent,
  leftTitle,
  leftProps,
  RightComponent,
  rightTitle,
  rightProps,
}) => {
  return (
    <Container>
      <css.SectionLayoutCss>
        <css.LeftSideCss>
          {/** Title */}
          {leftTitle ? (
            <css.LeftHeader>
              <span className="symbol">|&nbsp;</span>
              {leftTitle}&nbsp;
              <span className="symbol">&lt;</span>
            </css.LeftHeader>
          ): (
            <span className="empty"/>
          )}

          {/** Left content */}
          {LeftComponent && <LeftComponent {...leftProps} />}
        </css.LeftSideCss>

        <css.RightSideCss>
          {/** Title */}
          {rightTitle && <css.RightHeaderCss>{rightTitle}</css.RightHeaderCss>}

          {/** Right content */}
          {RightComponent && <RightComponent {...rightProps} />}
        </css.RightSideCss>
      </css.SectionLayoutCss>
    </Container>
  )
}

export default SectionLayout
