import React, { memo } from 'react';
import { WrapperCss } from './index.css';

const BloomdSection8 = memo(function BloomdSection8() {
  return (
    <WrapperCss>
      <div></div>
      <div></div>
    </WrapperCss>
  );
});

export default BloomdSection8;
