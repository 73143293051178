import styled from 'styled-components'
import { screens, colors } from '../../../constants/theme'

export const GotYourAttentionWrapperSectionCss = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.darkGrey};
  margin: 50px 0;

  @media (min-width: ${screens.lg}) {
    margin: 100px 0;
  }


  .copy-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 25px;
    width: 455px;

    @media (min-width: ${screens.lg}) {
      padding: 109px 0 103px;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 29px;
      line-height: 42px;
      text-align: center;
      margin: 0;
    }

    a {
        margin: 0;
        margin-top: 57px;
    }
  }
`
