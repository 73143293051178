import styled, { css } from 'styled-components';
import { screens, colors } from '../../../constants/theme';

export const BloomdSectionWrapperCss = styled.div`
  padding: 130px 0;
  background: ${colors.lightGrey2};

  &&& h1,
  &&& h3 {
    color: ${colors.black};
    color: ${colors.black};
  }

  h3 {
    font-style: italic;
    margin: 18px 0;
  }

  &&& p {
    color: ${colors.grey2};
    margin-top: 0;
  }

  em {
    display: block;
    color: ${colors.grey};
    text-align: center;
    margin: 0;
    margin-top: 50px;
  }

  @media (max-width: ${screens.sm}) {
    padding: 50px 0;
  }
`;

export const PaddingLeftRightCss = styled.div`
  padding: 0 5%;
`;

export const TitleWrapperCss = styled.div`
  margin: 0 auto;
  max-width: 648px;

  ${({ description }) =>
    description &&
    css`
      p {
        max-width: 475px;
      }
    `}
`;

export const DescriptionCss = styled.div`
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 30px;

  @media (max-width: ${screens.xs}) {
    margin: 0;
  }
`;

export const SectionContainerCss = styled.div`
  padding-top: 65px;
  padding-bottom: 65px;
  ${({ first }) => first && 'padding-top: 130px'};

  @media (max-width: ${screens.lg}) {
    padding-top: 25px;
    padding-bottom: 25px;
    ${({ first }) => first && 'padding-top: 50px'};
  }

  ${({ last }) => last && 'padding-bottom: 0'};
`;

export const DescriptiveNavigationWrapperCss = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    flex: 1;
    &:first-child {
      max-width: 300px;
      margin-right: 50px;
    }

    &:last-child {
      max-width: 400px;

      transform: translateY(35%);

      img {
        width: 100%;
        max-width: 375px;
        height: 100%;
        filter: drop-shadow(0 -1px 7px rgba(0, 0, 0, 0.15));
      }
    }
  }

  @media (max-width: ${screens.lg}) {
    flex-direction: column;
    align-items: center;

    & > * {
      &:first-child {
        width: 65%;
        margin-right: 0;
      }

      &:last-child {
        transform: none;
        width: 100%;
        margin-top: 40px;
      }
    }
  }

  @media (max-width: ${screens.xs}) {
    & > *:last-child {
      align-self: flex-start;
    }
  }
`;
