import styled from 'styled-components/macro'
import { screens } from '../../../constants/theme'

export const Section1ContainerCss = styled.div`
  margin: 180px 0 50px;

  @media (max-width: ${screens.lg}) {
    margin: 100px 0 25px;
  }
`

export const ImageWrapperCss = styled.div`
  width: 420px;
  height: 290px;
  position: relative;

  margin-top: 60px;

  img {
    width: 100%;
    height: 100%;
  }

  img[alt~="mask"] {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
  }

  @media (max-width: ${screens.lg}) {
    width: 400px;
    height: 300px;
  }

  @media screen and (max-width: ${screens.sm}) {
    width: 100%;
    height: auto;
  }
`