import React, { useEffect, useState } from 'react'
import { ParagraphCss } from './index.css'
import {
  SPACING,
  STAND_WIDTH,
  LeftHamsterCss,
  RightHamsterCss,
  HamsterMechanismCss,
  StandCss,
} from './testing.css'
import leftHamster from './assets/left-hamster.gif'
import rightHamster from './assets/right-hamster.gif'
import stand from './assets/stand.svg';
import { useWindowSize } from '../../Internship/section6/section6.utils'

const Testing = () => {
  const [ratio, setRatio] = useState(1);
  const { width } = useWindowSize();

  useEffect(() => {
    const standWidth = parseInt(STAND_WIDTH);
    const spacing = parseInt(SPACING);

    if (standWidth + spacing > width) {
      const newRatio = (width - spacing + 10) / standWidth;
      setRatio(newRatio);
    } else {
      setRatio(1);
    }
  }, [width]);

  return (
    <>
      <ParagraphCss>
        Before we publish any section or page, we thoroughly test it internally
        to identify and solve any issues that may appear. Simulating every
        possible use case will enable us to deliver a robust end product.
      </ParagraphCss>

      <HamsterMechanismCss>
        <LeftHamsterCss src={leftHamster} alt="Left hamster on wheel" ratio={ratio}/>

        <RightHamsterCss src={rightHamster} alt="Right hamster on wheel" ratio={ratio}/>

        <StandCss src={stand} alt="Mechanism stand" />
      </HamsterMechanismCss>
    </>
  )
}

export default Testing
