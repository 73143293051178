import React from 'react';
import { PrimaryLinkCss } from '../../../layout/index.css';
import { BlockCss, InfoCss, ImageCss } from './project-block.css';
import { Link } from 'react-router-dom';

const ProjectBlock = (props) => {
  const { title, description, image, imgAlt, link, extra } = props;

  return (
    <BlockCss>
      <InfoCss>
        {/** Title */}
        <h2><Link to={link}>{title}</Link></h2>

        {/** Description */}
        <p>
          {description}
        </p>

        {extra}
        <PrimaryLinkCss to={link}>View project</PrimaryLinkCss>
      </InfoCss>

      {/** Image */}
      <ImageCss>
        <Link to={link}>
          <img src={image} alt={imgAlt} />
        </Link>
      </ImageCss>
    </BlockCss>
  );
}

export default ProjectBlock;