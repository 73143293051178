import styled from 'styled-components/macro'
import { screens } from '../../../constants/theme'

export const CardsContainerCss = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 240px);
	grid-gap: 15px 20px;
  margin-bottom: 50px;

  @media (max-width: ${screens.md}) {
    grid-template-columns: 1fr;
    justify-items: center;
    width: 100%;
  }
`;