import React from 'react'
import {
  BenefitsSectionWrapperCss,
  InfoBlockCss,
  GifContainer,
} from './index.css'
import gif from './assets/gif.gif'

const BenefitsSection = () => {
  return (
    <BenefitsSectionWrapperCss>
      <h4>
        <span className="symbol">|</span> Benefits{' '}
        <span className="symbol">&lt;</span>
      </h4>
      <InfoBlockCss>
        <h1>We have some goodies to offer you</h1>
        <ul>
          <li>
            Competitive monthly salary based on your qualification and
            experience
          </li>
          <li>Remote Working</li>
          <li>Excellent Learning</li>
          <li>Opportunities</li>
          <li>Killer Letter of Recommendation</li>
          <li>Blessings and Goodwill</li>
          <li>Resume Tailoring</li>
          <li>Further Career Guidance</li>
          <li>
            Super Informal Dress Code (you can wear your jammies all day for
            what we care)
          </li>
        </ul>
				<GifContainer>
					<img src={gif} alt="Fist Bump" />
				</GifContainer>
      </InfoBlockCss>
    </BenefitsSectionWrapperCss>
  )
}

export default BenefitsSection
