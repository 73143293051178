import styled from 'styled-components';
import { colors, screens } from '../../../constants/theme';

export const FirstSectionWrapperCss = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-left: 8.33vw;

	h4 {
		margin-bottom: 100px;
		@media (max-width: ${screens.lg}) {
			margin: 0px 0px 48px 20px;
		}
	}

	@media (max-width: ${screens.lg}) {
		padding: 20px 0px 0px;
	}
`;

export const BryantExtraCss = styled.section`
	margin-top: 10px;

	@media (max-width: ${screens.lg}) {
    margin: 0px;
  }
`;

export const AcknowledgementsCss = styled.ul`
  padding-left: 0px;
	list-style-position: inside;

  &&& li {
		list-style: none;
		color: ${colors.grey};
		margin-bottom: 13px;
		position: relative;
		padding-left: 12px;

		&:before {
			content: '■';
			position: absolute;
			left: 0px;
			top: 0px;
			font-size: 7px;
			color: ${colors.white};
		}

		span {
			color: ${colors.white};
		}
	}
`;

