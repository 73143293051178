import React from 'react'
import { CardsContainerCss } from './index.css'
import Container from '../../Container'
import Card from './card'
import { cards, useWindowSize } from './section6.utils'
import SectionLayout from '../section-layout/section-layout'

const InternshipSection6 = () => {
  const windowDimensions = useWindowSize()

  return (
    <>
      <SectionLayout leftTitle="Curriculum"
        rightTitle="Things you will learn"
      />

      <Container>
        <CardsContainerCss>
          {
            cards.map((card) =>
              <Card cardInfo={card}
                key={card.title}
                windowDimensions={windowDimensions}
              />
            )
          }
        </CardsContainerCss>
      </Container>
    </>
  )
}

export default InternshipSection6
