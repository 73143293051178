import React from 'react'
import {
    CardWrapperCss,
    ImageWrapperCss,
    TextWrapperCss,
    PrimaryLinkCss,
} from './card.css'

const Card = ({ cardInfo }) => {
    const { image, title, redirect, text} = cardInfo
    return (
      <CardWrapperCss>
      {image && (
        <ImageWrapperCss>
          <img src={image} alt=""></img>
        </ImageWrapperCss>
      )}

        <TextWrapperCss>
          <PrimaryLinkCss href={redirect}>
            <h2>{title}</h2>
          </PrimaryLinkCss>
          <p>{text}</p>
        </TextWrapperCss>
      </CardWrapperCss>
    )
}

export default Card
