import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { screens, colors} from '../../constants/theme';

const RingAnimation =  keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const CardsWrapperCss = styled.div`
  margin: 100px auto;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: ${screens.lg}) {
    max-width: 1200px;
    padding: 60px;
  }
`;

export const CardsGridCss = styled.div`
  width: 100%;
  display: grid;

  @media (min-width: ${screens.lg}) {
    grid-template-columns: 1fr 1fr;
  }
`

export const CardItemCss = styled.div`
  padding: 0 8%;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 80px;

  @media (min-width: ${screens.lg}) {
    margin-bottom: 0px;

    &:nth-child(even){
      padding-top: 120px;
    } 
  }
`

export const LinkCss = styled(Link)`
  text-decoration: none;
  display: inline-block;
  width: 100%;
`;

export const LoadingWrapperCss = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vw;
  width: 100%;
`

export const DualRingCss = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;

  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid ${colors.blue};
    border-color: ${colors.blue} transparent ${colors.blue} transparent;
    animation: ${RingAnimation} 1.2s linear infinite;
  }
`
