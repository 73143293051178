import React from "react";
import {
  NinthSectionWrapperCss,
  BlockCss,
  InfoCss,
  ImgAndVideoCss,
  ImgAndVideoCssMobile,
  DownImgAndVideoBlockCss,
} from "./index.css";
import Lottie from "react-lottie";
// import img1 from "./assets/image1.png";
import vid1 from "./assets/vid1.mp4";
import vid2 from "./assets/vid2.mp4";
import animationData from "./assets/data.json";
const NinthSection = () => {
  const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <NinthSectionWrapperCss>
      <BlockCss>
        <InfoCss>
          <div>
            <h1>Technical Illustrations</h1>
            <p>
              The dentistry equipment we presented required lengthy research in
              order to fully comprehend its components and functionalities. The
              technical drawings we produced are precise to the tiniest detail.
              They often necessitated creative solutions in order to clearly
              bring across certain concepts.
            </p>
          </div>
        </InfoCss>
        <ImgAndVideoCss>
          <video
            controls={false}
            preload="auto"
            autoPlay={true}
            loop={true}
            muted="muted"
            playsInline
            webkit-playingInline
          >
            <source src={vid1} type="video/mp4" />
          </video>
          <DownImgAndVideoBlockCss>
            <video
              controls={false}
              preload="auto"
              autoPlay={true}
              loop={true}
              muted="muted"
              playsInline
              webkit-playingInline
            >
              <source src={vid2} type="video/mp4" />
            </video>
            <div className="lottieAnim">
              <div className="lottieAnimWrap">
                <Lottie options={defaultOptions} />
              </div>
              <h4>click to change the filter</h4>
            </div>
          </DownImgAndVideoBlockCss>
        </ImgAndVideoCss>
        <ImgAndVideoCssMobile>
          <video
            controls={false}
            preload="auto"
            autoPlay={true}
            loop={true}
            muted="muted"
            playsInline
            webkit-playingInline
          >
            <source src={vid1} type="video/mp4" />
          </video>
          <video
            controls={false}
            preload="auto"
            autoPlay={true}
            loop={true}
            muted="muted"
            playsInline
            webkit-playingInline
          >
            <source src={vid2} type="video/mp4" />
          </video>
          <div className="lottieAnim">
            <Lottie options={defaultOptions} />
            <h4>tap to change the filter</h4>
          </div>
        </ImgAndVideoCssMobile>
      </BlockCss>
    </NinthSectionWrapperCss>
  );
};

export default NinthSection;
