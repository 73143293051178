import React from 'react'
import {
  ProcessWrapperCss,
  StepCss,
  IndexCss,
  TitleCss,
} from './process-step.css'

const ProcessStep = (props) => {
  const { index, title, } = props
  return (
    <ProcessWrapperCss>
      <StepCss>
        <IndexCss><span>{index}</span></IndexCss>
        <TitleCss>{title}</TitleCss>
      </StepCss>

      {props.children}
    </ProcessWrapperCss>
  )
}

export default ProcessStep
