import React from "react";
import {
  TenthSectionWrapperCss,
  BlockCss,
  InfoCss,
  ImgAndVideoCss,
  InfoForImage,
  InfoForImageMobile,
  ImgAndVideoCssMobile,
  FirstRowMobile,
  SecondRowMobile,
} from "./index.css";
import svg1 from "./assets/svg1.svg";
// import svg2 from "./assets/svg2.json";
import svg3 from "./assets/svg3.svg";
import Lottie from "react-lottie";
import animationData from "./assets/svg2.json";
import { LazyLoadComponent,LazyLoadImage } from "react-lazy-load-image-component";

const TenthSection = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <TenthSectionWrapperCss>
      <BlockCss>
        <InfoCss>
          <div>
            <h1>Achievements</h1>
          </div>
        </InfoCss>
        <ImgAndVideoCss>
          <div className="anim">
            <LazyLoadImage src={svg1} alt=""></LazyLoadImage>
          </div>
          <LazyLoadComponent>
            <div className="anim">
              <Lottie options={defaultOptions} width={150} height={160} />
            </div>
          </LazyLoadComponent>
          <div className="anim">
            <LazyLoadImage src={svg3} alt=""></LazyLoadImage>
          </div>
        </ImgAndVideoCss>
        <InfoForImage>
          <p>Website of the Year Dental Industry Awards 2019</p>
          <p>Redesign facilitated a rise in conversion rates</p>
          <p>Honorable mention on AWWWARDS</p>
        </InfoForImage>
        <ImgAndVideoCssMobile>
          <FirstRowMobile>
            <div className="anim">
              <LazyLoadImage src={svg1} alt=""></LazyLoadImage>
            </div>
            <div className="anim">
            <LazyLoadImage src={svg3} alt=""></LazyLoadImage>
          </div>
          </FirstRowMobile>
          <InfoForImageMobile>
          <p>Website of the Year Dental Industry Awards 2019</p>
          <p>Honorable mention on AWWWARDS</p>
        </InfoForImageMobile>
          <SecondRowMobile>
          <LazyLoadComponent>
            <div className="anim">
              <Lottie options={defaultOptions} width={130} height={140} />
            </div>
          </LazyLoadComponent>
          <p>Redesign facilitated a rise in conversion rates</p>

          </SecondRowMobile>
        </ImgAndVideoCssMobile>
      </BlockCss>
    </TenthSectionWrapperCss>
  );
};

export default TenthSection;
