import styled from 'styled-components'
import { screens } from '../../../constants/theme'

export const CalendarsCss = styled.div`
  position: relative;
  left: -46px;
  width: 400px;
  height: 400px;

  div {
    margin: 0px;
  }

  @media screen and (max-width: ${screens.sm}) {
    max-width: 100%;
    height: auto;
    left: -14px;
  }
`
