import React, { useEffect } from 'react'
import {
  BlogPageWrapperCss,
  BlogPageTitleCss,
  BlogFigureCss,
  AuthorWrapperCss,
  AuthorTextCss,
  AuthorLineSeparatorCss,
} from './index.css'
import { useParams } from 'react-router-dom'
import { useBlogs } from '../../utils/useBlogs'

const BlogPage = () => {
  let { id } = useParams()
  const { detail } = useBlogs(id)

  useEffect(() => {
    if (!!detail) {
      document.title = `${document.title} | ${detail.title}`
    }
  }, [detail])

  if (!detail) return null

  return (
    <BlogPageWrapperCss>
      <BlogPageTitleCss>{detail.title}</BlogPageTitleCss>
      <AuthorWrapperCss>
        <AuthorTextCss>{detail.author}</AuthorTextCss>
        <AuthorLineSeparatorCss />
      </AuthorWrapperCss>
      <BlogFigureCss>
        <img src={detail.thumbnail} alt={detail.title} />
      </BlogFigureCss>{' '}
      <div dangerouslySetInnerHTML={{ __html: detail.content }} />
    </BlogPageWrapperCss>
  )
}

export default BlogPage
