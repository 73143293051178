import styled from 'styled-components'
import { screens } from '../../../constants/theme';

export const ThirdSectionWrapperCss = styled.div``;
export const BlockCss = styled.div`
    display: flex;
    flex-direction:column;
    margin-bottom: 105px;
    width:100%;
    height: 260vh;
    @media (max-width: ${screens.md}) {
      section {
        margin: 0px;
      }
    }
`

export const StickyContent = styled.div`
    margin:0 auto;
    width:100%;
    position: sticky;
    top: 0;
    padding: 100px 0 0 0;

 .sticky {
    max-width: 648px;
    margin: 0 auto;
    @media (max-width: ${screens.md}) {
      margin: 0px 25px;
      width: auto;

      h2 {
        margin-bottom: 0px;
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 34px;
      }

      a {
        margin-top: 30px;
      }
    }
  }
  div {
    h1{
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 42px;
      color: #F5F5F5;
      margin-bottom:25px;
      @media (max-width: ${screens.md}) {
        font-size: 26px;
        line-height: 34px;
      }
    }
    h2{
      margin-bottom:25px;
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 36px;
      color: #F5F5F5;
      @media (max-width: ${screens.md}) {
        font-size: 24px;
        line-height: 31px;
      }
    }
    .mB{
      margin-bottom:50px;
    }
    p{
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      color: #9b9999;
      @media (max-width: ${screens.md}) {
        font-size: 16px;
        line-height: 27px;
      }
    }
  }
`;

export const SliderImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const SliderImageInfo = styled.div`
  width:100%;
  text-align:center;
  margin-top:20px;

  @media (max-width: ${screens.md}) {
    margin-top:15px;
    max-width: 150px;  
  }

  h4 {
    font-family: IBM Plex Sans;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #9694A7 !important;
  }
`;
export const SliderImageMain =styled.div`
  display:flex;
  justify-content:center;
  z-index:5;
  width:100%;
  overflow: hidden;
  top:50px;

  .background{
    border-radius:1000px;
    width: 250px;
    height: 250px;
    background-color: rgba(0, 0, 0, 0);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-image: linear-gradient(0deg, rgb(14, 14, 14) 0%, rgb(27, 27, 27) 100%);
    background-size: auto;
    background-origin: padding-box;
    background-clip: border-box;
    transition: all 0.5s 0s ease;

    @media (max-width: ${screens.md}) {
      width:150px;
      height:150px;
    }

    img{
      display: block;
      margin: 0 auto;
      width:auto;
      height:250px;

      @media (max-width: ${screens.md}) {
        width:auto;
        height:150px;
      }
    }

    &.active {
      transform: scale(1.3);
      transition: all 0.5s 0s ease;
    }
  }

  .center {
    width: 330px;
    height: 330px;

    img {
      width: 330px;
      height: 330px;
    }
  }
  .r {
    margin: 0px;

    img {
      width: 250px;
      height: 250px;
    }
  }
  .l {
    margin: 0px;

    img {
        width: 300px;
        height: 300px;
    }
  }
`;

export const SliderImageContent =styled.div`
  width: 100%;
`

export const SlideImageItem = styled.div`
  width: 100%;
  display: grid;
  place-content: center;
  padding: 40px 0px;
`

export const SliderImageMobile = styled.div`
  display: none;
  flex-direction: column;

  @media (max-width: ${screens.xl}) {
      display: flex;
  }
`;
export const SliderImageMobileMain = styled.div`
  display: none;
  justify-content: center;
  width: 100vw;
  align-items: center;
  align-self: center;
  overflow: hidden;
  position: sticky;
  z-index: 5;
  top: 50px;
  @media (max-width: ${screens.xl}) {
    display: flex;
  }
  img {
    width: 150px;
    height: 150px;
  }
  .background{
    border-radius:1000px;
    width: 150px;
    height: 150px;
    background: linear-gradient(180deg, #1B1B1B 0%, rgba(27, 27, 27, 0) 100%);
  }
  .center{
    width:250px;
    height:250px;
    img{
      width:250px;
      height:250px;
    }
  }

  .r {
    margin: 0px;
    position: absolute;
    left: -100px;
  }
  .l {
    margin: 0px;
    position: absolute;
    right: -100px;
  }
`;
export const SliderImageMobileInfo = styled.div`
  h4 {
    margin-top:15px;
    font-family: IBM Plex Sans;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #9694A7 !important;
  }
`;