import React, { useState } from 'react';
import { createSubscriber } from '../../firebase/firebase.utils';
import { InlineWidget } from 'react-calendly';
import {
  BookVladPageWrapperCss,
  BookVladInputText,
  BookVladInputSubmit,
  BookVladAcknowledge,
} from './index.css';

const myStyles = {
  marginTop: '80px',
  height: 'calc(100vh - 80px)',
  width: '100vw',
  backgroundColor: window.innerWidth < 650 ? 'white' : '',
};

const BookVladSection = () => {
  const [submitted, setSubmitted] = useState(false);
  const [user, setUser] = useState({
    email: '',
  });

  const handleInputChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (user.email.length) {
      createSubscriber(user.email, 'subscribers');
      setSubmitted(true);
    }
  };
  return !submitted ? (
    <BookVladPageWrapperCss>
      <h1>
        Hi! To book your interview please type in your e-mail address below:
      </h1>

      <form onSubmit={handleSubmit}>
        <BookVladInputText
          name="email"
          type="email"
          value={user.email}
          onChange={handleInputChange}
        />

        <BookVladInputSubmit type="submit" value="Submit" />
      </form>
      <BookVladAcknowledge>
        By clicking “Submit” you acknowledge Kappa’s{' '}
        <a href="https://kappa.work/privacy">Privacy Policy</a> and agree with
        our internal data processing.
      </BookVladAcknowledge>
    </BookVladPageWrapperCss>
  ) : (
    <InlineWidget
      url="https://calendly.com/vladkappa"
      styles={myStyles}
      prefill={{
        email: `${user.email}`,
      }}
    />
  );
};

export default BookVladSection;
