import styled from "styled-components";
import { screens } from "../../../constants/theme";

export const TenthSectionWrapperCss = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
margin-top:332px;
@media (max-width: ${screens.md}) {
margin-top:120px;

}
h4 {
  margin-bottom: 100px;
  @media (max-width: ${screens.md}) {
    margin: 0px 0px 48px 20px;
  }
}

@media (max-width: ${screens.md}) {
  padding: 20px 0px 0px;
}

*:not(a),
*:not(button) {
  cursor: default !important;
}
`;

export const BlockCss = styled.div`
display: flex;
flex-wrap: wrap;
margin-bottom: 105px;
align-items: center;
max-width: 1000px;
margin: 0 auto;

section {
  margin: 40px 0px;
}


`;

export const InfoCss = styled.div`
margin: 0 auto;
div {
  width: 648px;
  @media (max-width: ${screens.md}) {
    width: 100%;

  }
  h1 {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 42px;
    color: #f5f5f5;
    margin:0;
    margin-bottom: 100px;
    @media (max-width: ${screens.md}) {
      font-size: 26px;
    margin-bottom: 60px;
    line-height: 34px;
    }
  }
  h2 {
    margin-bottom: 25px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 36px;
    color: #f5f5f5;
    @media (max-width: ${screens.md}) {
      font-size: 24px;
      line-height: 31px;
    }
  }
  .mB {
    margin-bottom: 100px;
    
  }
  p {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #9b9999;
    @media (max-width: ${screens.md}) {
      font-size: 16px;
      line-height: 27px;
    }
  }
}
@media (max-width: ${screens.md}) {
  margin: 0px 25px;
  width: 100%;

  h2 {
    margin-bottom: 0px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 34px;
  }

  a {
    margin-top: 30px;
  }
}

`;

export const ImgAndVideoCss = styled.div`
display:flex;
justify-content:space-between;
width: 90%;
margin:0 auto;
cursor:pointer;
@media (max-width: ${screens.md}) {
    display:none;
}
.anim {
  display:flex;
  align-items:center;
  justify-content:center;
  width:277px;
  height:328px;
  background: #1B1B1B;
border-radius: 25px;
}
img{
  width:226px;
  height:226px;
}

`;
export const InfoForImage = styled.div`
display:flex;
justify-content:space-between;
width:90%;
margin:0 auto;
margin-top:70px;
@media (max-width: ${screens.md}) {
  display:none;
}

    p{
      margin:0;
      width:277px;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      text-align: center;
      color: #F5F5F5;
    }
`;
export const InfoForImageMobile = styled.div`
display:none;
justify-content:space-between;
width:100%;
@media (max-width: ${screens.md}) {
  display:flex;
}
    p{
      width:150px;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
      text-align: center;
      color: #F5F5F5;

    }
`;
export const ImgAndVideoCssMobile = styled.div`
  display: none;
  display: none;
  width: 100%;
  cursor: pointer;
  position:relative;
  overflow:hidden;
  margin:0 25px;
  border-radius:25px;
  @media (max-width: ${screens.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
   
  }

  h4{
    margin:0;
  }

  
`;
export const FirstRowMobile = styled.div`
display:flex;
justify-content:space-between;
width:100%;
  .anim{
    display:flex;
  align-items:center;
  justify-content:center;
    width:150px;
    height:200px;
    background: #1B1B1B;
border-radius: 25px;
  }
  img{
    width:124px;
    height:124px;
  }
`;

export const SecondRowMobile = styled.div`
width:100%;
    p{
      width:70%;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
      text-align: center;
      color: #F5F5F5;
      margin:16px auto;
    }
  .anim{
    display:flex;
    align-items:center;
    justify-content:center;
    height:180px;
    background: #1B1B1B;
    border-radius: 25px;
  }
`;