import styled from 'styled-components';
import { colors } from '../../../constants/theme';

export const BannerCss = styled.div`
  background-color: ${colors.darkGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 110px;
  padding: 220px 60px;

  && h3 {
    font-style: normal;
    text-align: center;
  }

  a {
    margin: 0px;
  }
`;