import styled from 'styled-components'
import { screens, colors } from '../../../constants/theme'

export const BryantSectionWrapperCss = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #111111;

  h4 {
    margin-bottom: 100px;
    @media (max-width: ${screens.md}) {
      margin: 0px 0px 48px 20px;
    }
  }
`
export const FourthSectionWrapperCss = styled.div``

export const BlockCss = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 105px;
  margin-top: 112px;
  @media (max-width: ${screens.md}) {
    margin-top: 67px;
  }

  width: 100%;
  align-items: center;

  section {
    margin: 40px 0px;
  }

  @media (max-width: ${screens.md}) {
    // flex-direction: column-reverse;
    section {
      margin: 0px;
    }
  }
`

export const InfoCss = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
  width: 100%;
  max-width: 648px;

  // margin-right: 73px;
  p {
    max-width: 648px;
    margin: 0;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #9b9999;
    @media (max-width: ${screens.md}) {
      font-size: 16px;
      line-height: 27px;
    }
  }

  @media (max-width: ${screens.md}) {
    margin: 0px 25px;
    width: auto;

    h2 {
      margin: 0px;
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 34px;
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 40px;
    }
  }

  ul {
    padding-left: 0px;
    list-style-position: inside;

    li {
      color: ${colors.grey};
      margin-bottom: 13px;
      list-style: none;
      position: relative;
      padding-left: 12px;
      &:before {
        content: '■';
        position: absolute;
        left: 0px;
        top: 0px;
        font-size: 7px;
        color: ${colors.white};
      }

      span {
        color: ${colors.white};
      }
    }
  }
`
export const GifCss = styled.div`
  max-width: 872px;
  @media (max-width: ${screens.xl}) {
    margin: 0 25px;
  }
  .gif {
    width: 100%;
    border-radius: 25px;
  }
`

export const ImageCss = styled.div`
  display: grid;
  justify-content: center;
  margin: 0 auto;

  @media (min-width: ${screens.xl}) {
    max-width: 872px;
    margin-bottom: 112px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  img {
    width: 100%;
    max-width: 537px;
    @media (max-width: ${screens.xl}) {
      margin: 0 25px;
      width: calc(100% - 50px);
    }
  }
  @media (max-width: ${screens.xl}) {
    width: 100%;
    row-gap: 16px;
    margin-bottom: 60px;
  }
`
export const VideoAndImageBlockCss = styled.div`
  max-width: 648px;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @media (max-width: ${screens.md}) {
    margin: 0 25px;
    flex-direction: column;
    height: auto;
  }

  .left {
    margin-right: 50px;

    .vid1 {
      border-radius: 25px;

      @media (max-width: ${screens.md}) {
        width: 100%;
      }
    }
    @media (max-width: ${screens.md}) {
      width: 100%;
      margin-right: 0px;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: ${screens.md}) {
      justify-content: unset;
    }
    img {
      width: 242px;
      height: 272px;
      @media (max-width: ${screens.md}) {
        width: 100%;
        height: auto;
        margin-top: 50px;
      }
    }
  }
`
