import React from 'react'

import {
  FirstSectionWrapperCss,
  BlockCss,
  InfoCss,
  ImageCss,
  InfoBlockItemCss,
  ItemsCss,
  ImageCssMobile,
} from './index.css'
import { SecondaryLinkCss } from '../../../layout/index.css'

import img1 from './images/image1.png'
import imgM from './images/image1.2.jpg'

import { Link } from 'react-router-dom'

const FirstSection = () => {
  return (
    <FirstSectionWrapperCss>
      <BlockCss>
        <InfoCss>
          <h2>Bryant Dental</h2>
          <p>
            Bryant Dental is a UK-based manufacturer and distributor for
            dentistry equipment. The company's main focus are dental loupes, but
            they also sell headlights, cameras and other high-end products for
            dentists.
          </p>

          <ItemsCss>
            <InfoBlockItemCss>
              <h4>
                <span className="symbol">|</span> Responsibilities{' '}
                <span className="symbol">&lt;</span>
              </h4>
              <p>Design Interaction Front-End SEO Copywriting</p>
            </InfoBlockItemCss>

            <InfoBlockItemCss>
              <h4>
                <span className="symbol">|</span> Technologies used{' '}
                <span className="symbol">&lt;</span>
              </h4>
              <p>HTML CSS JavaScript ReactJS GatsbyJS OpenCart</p>
            </InfoBlockItemCss>

            <InfoBlockItemCss>
              <h4>
                <span className="symbol">|</span> Live website{' '}
                <span className="symbol">&lt;</span>
              </h4>
              <SecondaryLinkCss
                as="a"
                to=""
                href="https://bryant.dental"
                target="_blank"
              >
                bryant.dental
              </SecondaryLinkCss>
            </InfoBlockItemCss>
          </ItemsCss>
        </InfoCss>
        <ImageCss>
          <Link to="/bryant-dental">
            <img src={img1} alt="Bryant Dental" />
          </Link>
        </ImageCss>
        <ImageCssMobile>
          <Link to="/bryant-dental">
            <img src={imgM} alt="Bryant Dental" />
          </Link>
        </ImageCssMobile>
      </BlockCss>
    </FirstSectionWrapperCss>
  )
}
export default FirstSection
