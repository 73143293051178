import React from 'react'
import { ParagraphCss } from './index.css'
import launchGif from './assets/launch.gif'
import hand from './assets/hand.svg'
import { GifWrapperCss, HandCss, LaunchGifCss } from './launch.css'

const Launch = () => {
  return (
    <>
      <ParagraphCss>
        When the website is ready to go, we push the magic button and pop a
        bottle of champagne. We’ll hold your hand during take-off and help you
        have a safe flight.
      </ParagraphCss>

      <GifWrapperCss>
        <LaunchGifCss src={launchGif} alt="Earth space view" />
        <HandCss src={hand} alt="Astronaut hand" />
      </GifWrapperCss>
    </>
  )
}

export default Launch
