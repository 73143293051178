import styled from 'styled-components/macro';
import { screens } from '../../../constants/theme';

export const SecondSectionContainerCss = styled.div`
  width: 100%;
  height: 500px;
  position: relative;

  @media (max-width: ${screens.md}) {
    height: 350px;
  }

  @media (max-width: ${screens.sm}) {
    height: 290px;
  }
`;

export const VideoCss = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const VideoOverlayCss = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
`;

export const ContentWrapperCss = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 70px;

  @media (max-width: ${screens.sm}) {
    margin-bottom: 25px;
  }
`;

export const RightSideCss = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${screens.sm}) {
    width: 100%;
    align-items: center;
  }
`

export const TitleCss = styled.h1`
  margin-top: 9px;
  margin-bottom: 20px;
`