import styled from 'styled-components';
import { screens, colors } from '../../../constants/theme';

export const BloomdSectionWrapperCss = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 200px;

  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }

  @media (max-width: ${screens.lg}) {
    flex-direction: column;
    padding: 50px 0px 0px;
    width: auto;
  }
`;

export const InfoBlockCss = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60vw;

  p,
  h1 {
    width: 50vw;
    max-width: 648px;
  }

  a.website {
    text-decoration: none;
    border-bottom: 1px solid;
    font-style: italic;
  }

  @media (max-width: ${screens.lg}) {
    width: 100%;

    p,
    h4 {
      padding: 0px 25px;
      width: auto;
    }

    h1 {
      width: calc(100% - 50px);
      max-width: 648px;
      padding: 0px 25px;
    }
  }
`;

export const ItemsCss = styled.div`
  display: flex;
  margin: 25px 0px 25px;
  justify-content: space-between;
  width: 100%;
  max-width: 820px;
  @media (max-width: ${screens.lg}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const InfoBlockItemCss = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 300px;

  @media (max-width: ${screens.lg}) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 50px;
  }

  &&& h4 {
    color: ${colors.grey};
    width: 100%;

    @media (max-width: ${screens.lg}) {
      width: auto;
      margin-bottom: 0px;
    }
  }

  &&& a {
    padding: 0px 5px 8px;
    margin: 0px;
    color: ${colors.grey};

    @media (max-width: ${screens.lg}) {
      margin: 16px 25px 0px;
    }
  }

  p {
    color: ${colors.grey}!important;
    word-spacing: 14px;
    margin: 0px;
    width: 100%;
    span {
      font-style: normal;
      word-spacing: 5px;
    }

    @media (max-width: ${screens.lg}) {
      margin: 16px 0px 0px;
    }
  }
`;

export const ImageCss = styled.div`
  overflow: hidden;
  width: 100%;
  max-height: 47vw;
  margin: 80px 0px 0px;

  img {
    display: block;
    width: 100%;
  }

  @media (max-width: ${screens.lg}) {
    margin: 0px;
    max-height: 350px;

    img {
      width: 250%;
    }
  }
`;
