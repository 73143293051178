import styled from "styled-components"
import { screens } from "../../../constants/theme";

export const SectionWrapperCss = styled.div`
  padding: 50px 0;
  width: 100%;
  
  h4 {
    margin: 0;
    margin-left: 25px;

    @media (min-width: ${screens.lg}) {
      margin-left: 8.33vw;
    }
	}

  @media (min-width: ${screens.lg}) {
    padding: 75px 0;
  }
`

export const TimelineBlockCss = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 88px;

  @media (min-width: ${screens.lg}) {
    margin-top: 136px;
  }

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 120px;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, #0E0E0E 55.31%, rgba(14, 14, 14, 0) 94.27%);

    @media (min-width: ${screens.lg}) {
      width: 200px;
    }
  }
`

export const ImgKaprioCss = styled.img`
  width: 86px;
  height: auto;
  position: absolute;
  left: 20px;
  top: 40px;


  @media (min-width: ${screens.lg}) {
    width: 118px;
    top: 50px;
    left: 70px;
  }
`

export const ImgDragCss = styled.img`
  opacity: 1;
  position: absolute;
  height: auto;
  width: 30px;
  top: 169.5px;
  left: 50px;
  transition: opacity .2s ease-in-out;

  @media (min-width: ${screens.lg}) {
    width: 39px;
    top: 219.5px;
    left: 110px;
  }

  &.hide {
    opacity: 0;
  }
`

export const TimelineScrollableOuter = styled.div`
  height: 340px;
  width: 100%;
  overflow: hidden;
  @media (min-width: ${screens.lg}) {
    height: 440px;
  }
`

export const TimelineScrollableCss = styled.div`
  display: flex;
  overflow-x: scroll;
  padding-left: 143px;
  cursor: grab;

  @media (min-width: ${screens.lg}) {
    padding-left: 282px;
  }
`

export const ImgTimelineCss = styled.img`
  width: auto;
  height: 340px;
  flex-shrink: 0;
  pointer-events: none;

  @media (min-width: ${screens.lg}) {
    height: 440px;
  }
`

export const TellUsBlockCss = styled.div`
  width: 100vw;
  height: 340px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  margin-left: -44vw;
  padding-top: 6px;
  
  
  @media (min-width: ${screens.lg}) {
    margin-left: -22vw;
    padding-top: 110px;
    height: 440px;
  }


  h1 {
    color: #0e0e0e;
    text-align: center;
    cursor: initial !important;
    margin-bottom: 10px;
    max-width: 125px;

    @media (min-width: ${screens.lg}) {
      max-width: unset;
    }
  }

  h1 span {
    font-size: 1.4rem;
  }

  a {
    margin: 0;
  }
`
