import styled from 'styled-components/macro'
import { colors, screens } from '../constants/theme'
import { Link } from 'react-router-dom'


export const PrimaryLinkCss = styled(Link)`
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.03em;
    text-decoration: none;
    width: fit-content;
    color: ${colors.white};
    display: inline-block;

    padding: 7px 5px 8px;
    margin: -8px 25px 20px 0px;

    text-align: center;

    position: relative;
    overflow: hidden;
    transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);

    &:after {
        position: absolute;
        transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        content: '';
        width: 100%;
        right: auto;
        left: 0;
        bottom: 0;
        height: 2px;
        background: ${colors.blue};
    }

    &:hover {
        cursor: pointer;
        &:after {
            width: 0%;
            right: 0;
            left: auto;
        }
    }

    @media (max-width: ${screens.sm}) {
        padding: 7px 0px 8px;
    }
`

export const SecondaryLinkCss = styled(PrimaryLinkCss)`
    &:after {
        background: ${colors.grey};
    }
`

export const PrimaryLinkBgCss = styled(PrimaryLinkCss)`
    &:after {
        width: 100%;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
        height: 0px;
        background: ${colors.blue};
        z-index: -1;
    }

    &:before {
        content: '';
        width: 100%;
        right: 0;
        left: 0;
        bottom: 0;
        top: auto;
        height: 2px;
        background: ${colors.blue};
        position: absolute;

        transition: all 0.3s 0.5s linear;
    }

    &:hover {
        cursor: pointer;
        color: ${colors.black};
        &:after {
            width: 100%;
            height: 100%;
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &:before {
            height: 0px;
            opacity: 0.5;
            transition: all 0.1s 0.3s linear;
        }
    }
`

export const SecondaryLinkBgCss = styled(PrimaryLinkBgCss)`
    &:before,
    &:after {
        background: ${colors.grey};
    }
`
