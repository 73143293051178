import styled from 'styled-components/macro'
import { screens, colors } from '../../../constants/theme'

export const BackgroundCss = styled.div`
  background: ${colors.darkGrey};
  margin-bottom:100px;
`

export const WrapperCss =styled.div`
  max-width: 1020px;
  margin-left: 110px;
  padding: 50px 70px 50px 120px;


  @media (max-width: 1239px) and (min-width: 769px) {
    margin-left: 0;
    padding: 50px 0px 50px 40px;
  } 

  @media (max-width: ${screens.lg}) {
    margin: 0 auto;
    padding: 25px 0 25px;
  }
`

export const CardsContainerWrapperCss = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media (max-width: ${screens.lg}) {
    flex-direction: column;
    align-items: center;
  }
`

export const ImageWrapperCss = styled.div`
  max-width: 313px;
  max-height: 313px;

  img {
    width: 100%;
    height: 100%;
  }
`

export const PrimaryLinkCss = styled.a`
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-decoration: none;
  width: fit-content;
  color: ${colors.white};
  display: inline-block;

  padding: 0;
  margin: 1px 0 22px;

  text-align: center;

  position: relative;
  overflow: hidden;
  transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);

  &:after {
    position: absolute;
    transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    content: '';
    width: 100%;
    right: auto;
    left: 0;
    bottom: 0;
    height: 2px;
    background: ${colors.blue};
  }

  &:hover {
    cursor: pointer;
    &:after {
      width: 0%;
      right: 0;
      left: auto;
    }
  }
`

export const LowerTextWrapper = styled.div`
  padding-bottom: 40px;

  @media screen and (max-width: ${screens.lg}) {
    margin-left: 0px;
    text-align: center;
  }
`