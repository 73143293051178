import React from 'react'
import {
  FourthSectionWrapperCss,
  RightSideCss,
} from './index.css'
import ProcessStep from './process-step'
import Discovery from './discovery'
import Launch from './launch'
import Planning from './planning'
import Execution from './execution'
import Testing from './testing'
import SectionLayout from '../section-layout/section-layout'

const FourthSection = () => {
  return (
    <FourthSectionWrapperCss>
      <SectionLayout title="Our process">
        <RightSideCss>
          <ProcessStep index={1} title="Discovery">
            <Discovery />
          </ProcessStep>

          <ProcessStep index={2} title="Planning">
            <Planning />
          </ProcessStep>

          <ProcessStep index={3} title="Execution">
            <Execution />
          </ProcessStep>

          <ProcessStep index={4} title="Testing and QA">
            <Testing />
          </ProcessStep>

          <ProcessStep index={5} title="Launch">
            <Launch />
          </ProcessStep>
        </RightSideCss>
      </SectionLayout>
    </FourthSectionWrapperCss>
  )
}

export default FourthSection
