import styled, { css, keyframes } from 'styled-components/macro'
import { colors, screens } from '../../../constants/theme'
import pMask from './assets/p2.png'

export const Content = styled.div`
  display: flex;
  padding: 100px 110px 70px;
  flex: 1;
  justify-content: center;

  @media screen and (max-width: ${screens.lg}) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0px;
    padding: 60px 0px 10px;
  }
`

export const GifWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 50px 0px;

  @media screen and (max-width: ${screens.md}) {
    margin: 10px 0px;
  }
`

export const MaskedPContainer = styled.div`
  position: relative;
  max-width: 306px;
  align-self: center;

  @media screen and (max-width: ${screens.md}) {
    transform: scale(0.7, 0.7);
  }
`

export const MaskedP = styled.img`
  max-width: 306px;
  mask-image: url(${pMask});
  mask-repeat: no-repeat;
  mask-position: center;
`

const starsAnimation = keyframes`
  from {
    transform: translate(0px, 0px);
  }

  to {
    transform: translate(-47px, 44.5px);
  }
`

const narrowStarsAnimation = keyframes`
  from {
    transform: scale(0.8, 0.8) translate(0px, 0px);
  }

  to {
    transform: scale(0.8, 0.8) translate(-47px, 44.5px);
  }
`
export const Stars = styled.img`
  position: absolute;
  width: 53px;
  height: 96px;
  right: -47px;
  top: -70px;

  ${(props) => setStarsAnimation(props)}

  @media (max-width: ${screens.xs}) {
    right: -40px;
    top: -60px;
    transform: scale(0.8, 0.8);
    ${(props) => setStarsAnimation(props, true)}
  }
`

const planetAnimation = keyframes`
  from {
    transform: translate(0px, 0px);
  }

  to {
    transform: translate(45.5px, -42.5px);
  }
`

const narrowPlanetAnimation = keyframes`
  from {
    transform: scale(0.8, 0.8) translate(0px, 0px);
  }

  to {
    transform: scale(0.8, 0.8) translate(45.5px, -42.5px);
  }
`
export const Planet = styled.img`
  position: absolute;
  width: 95px;
  height: 113px;
  bottom: -85px;
  left: -91px;

  ${(props) => setPlanetAnimation(props)}

  @media (max-width: ${screens.xs}) {
    bottom: -75px;
    left: -81px;
    transform: scale(0.8, 0.8);
    ${(props) => setPlanetAnimation(props, true)}
  }
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;

  p,
  h3 {
    max-width: 530px;
  }

  p:first-of-type {
    margin-top: 0px;
  }

  @media screen and (max-width: ${screens.md}) {
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0px;
  }
`

export const Paragraph = styled.p`
  &&& {
    font-size: 16px;
    color: ${colors.grey4};
  }
`

// ====== UTILS ======

const setStarsAnimation = (props, isNarrow) => {
  return (
    props.startAnimation &&
    css`
      animation: ${isNarrow ? narrowStarsAnimation : starsAnimation} 1s linear;
      animation-fill-mode: forwards;
    `
  )
}

const setPlanetAnimation = (props, isNarrow) => {
  return (
    props.startAnimation &&
    css`
      animation: ${isNarrow ? narrowPlanetAnimation : planetAnimation} 1s linear;
      animation-fill-mode: forwards;
    `
  )
}
