import React from "react";
import './svg6.scss'
const svg6 = () => {
  return (
    <>
      <svg
        width="100%"
        viewBox="0 0 221 107"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M157.862 47.6836C157.862 60.8563 150.094 73.6591 138.853 83.2102C127.618 92.7569 113.072 98.9141 99.7921 98.9141C86.591 98.9141 77.0989 92.8312 70.8659 83.4201C64.6048 73.9665 61.6191 61.1204 61.6191 47.6836C61.6191 34.4184 66.8252 24.9329 75.2868 18.7303C83.7918 12.496 95.6827 9.51074 109.119 9.51074C122.555 9.51074 134.765 12.4961 143.586 18.74C152.364 24.9527 157.862 34.4402 157.862 47.6836Z"
          stroke="#4ABAF7"
          stroke-width="2"
          class="TVyeBBEj_0"
        ></path>
        <path
          d="M57.2236 1.54736V105.156"
          stroke="#EAEAEA"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-dasharray="1 8"
          class="TVyeBBEj_1"
        ></path>
        <path
          d="M162.258 1.54736V105.156"
          stroke="#EAEAEA"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-dasharray="1 8"
          class="TVyeBBEj_2"
        ></path>
        <path
          d="M43.4525 54.9195C43.8431 54.529 43.8431 53.8958 43.4525 53.5053L37.0886 47.1413C36.698 46.7508 36.0649 46.7508 35.6744 47.1413C35.2838 47.5319 35.2838 48.165 35.6744 48.5555L41.3312 54.2124L35.6744 59.8693C35.2838 60.2598 35.2838 60.8929 35.6744 61.2835C36.0649 61.674 36.698 61.674 37.0886 61.2835L43.4525 54.9195ZM0.147461 55.2124H42.7454V53.2124H0.147461V55.2124Z"
          fill="#4ABAF7"
          class="TVyeBBEj_3"
        ></path>
        <path
          d="M176.842 53.5053C176.452 53.8958 176.452 54.529 176.842 54.9195L183.206 61.2835C183.597 61.674 184.23 61.674 184.621 61.2835C185.011 60.8929 185.011 60.2598 184.621 59.8693L178.964 54.2124L184.621 48.5556C185.011 48.165 185.011 47.5319 184.621 47.1413C184.23 46.7508 183.597 46.7508 183.206 47.1413L176.842 53.5053ZM220.147 53.2124L177.549 53.2124L177.549 55.2124L220.147 55.2124L220.147 53.2124Z"
          fill="#4ABAF7"
          class="TVyeBBEj_4"
        ></path>
      </svg>
    </>
  );
};

export default svg6;
