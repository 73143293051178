import styled, { keyframes } from 'styled-components/macro'
import portalMask from './images/portal.png'
import portalGif from './images/portal.gif'
import { colors, screens } from '../../../constants/theme'

export const TextWrapperCSS = styled.div`
  max-width: 690px;

  &&& {
    p {
      line-height: 32px;
      color: ${colors.grey4};
    }

    p span {
      font-style: italic;
      color: white;
    }
  }
`

export const GifContainerCss = styled.div`
  align-self: flex-start;
  position: relative;
  margin: 100px;
  max-width: 350px;

  @media screen and (max-width: ${screens.sm}) {
    margin: 15px 0px 50px;
    transform: scale(0.7, 0.7);
  }

  @media (max-width: ${screens.xs}) {
    transform: scale(0.6, 0.6);
  }
`

export const ImageWrapperCss = styled.div`
  width: 350px;
  height: 320px;
  position: relative;

  background-image: url(${portalGif});
  background-size: content;
  background-position: center;
  background-repeat: no-repeat;

  mask-image: url(${portalMask});
  mask-repeat: no-repeat;
  mask-position: center;
`

// position icons when not animated
export const HandPickingFile = styled.div`
  position: absolute;
  top: -100px;
  left: -100px;
  transform: scale(0.7);
`

export const File = styled.div`
  position: absolute;
  top: 2px;
  left: -12px;
  transform: scale(0.7);
`

export const HandHoldingMug = styled.div`
  position: absolute;
  top: 210px;
  left: -130px;
  transform: scale(0.7);
`

export const Mug = styled.div`
  position: absolute;
  top: 208px;
  left: 23px;
  transform: scale(0.7) rotate(20deg);
`

export const HandHoldingKeyboard = styled.div`
  position: absolute;
  top: -50px;
  left: 220px;
  transform: scale(0.7);
`

export const KeyBoard = styled.div`
  position: absolute;
  top: -70px;
  left: 205px;
  transform: scale(0.7);
`

export const YerzCursor = styled.div`
  position: absolute;
  top: 190px;
  left: -45px;
  transform: scale(0.7);
`

export const RaluCursor = styled.div`
  position: absolute;
  top: 170px;
  left: 360px;
  transform: scale(0.7);
`
// -------------- Left Up corner animation --------------
const handUpAnimation = keyframes`
  from {
    transform: translate(0px, -300px);
  }

  to {
    transform: translate(5px, -290px) rotate(-15deg);
  }
`
export const HandUpAnimation = styled.div`
  animation: ${handUpAnimation} 1.2s forwards;
`

const fileAnimation = keyframes`
  from {
    transform: translate(0px, -300px);
  }

  to {
    transform: translate(50px, -220px) rotate(5deg);
  }
`
export const FileAnimation = styled.div`
  animation: ${fileAnimation} 1.3s forwards;
`

// -------------- Left Down corner animation --------------
const handDownAnimation = keyframes`
  from {
    transform: translate(0px, -305px);
  }

  to {
    transform: translate(25px, -335px);
  }
`
export const HandDownAnimation = styled.div`
  animation: ${handDownAnimation} 1.2s forwards;
`

const mugAnimation = keyframes`
  from {
    transform: translate(0px, -310px);
  }

  to {
    transform: translate(20px, -360px) rotate(-15deg);
  }
`
export const MugAnimation = styled.div`
  animation: ${mugAnimation} 1.3s forwards;
`

// -------------- Right Up corner animation --------------
const keyboardAnimation = keyframes`
  from {
    transform: translate(0px, -300px);
  }

  to {
    transform: translate(-55px, -160px) rotate(-35deg);
  }
`
export const KeyboardAnimation = styled.div`
  animation: ${keyboardAnimation} 1.3s forwards;
`
const handRightAnimation = keyframes`
  from {
    transform: translate(0px, -305px);
  }

  to {
    transform: translate(-20px, -200px) rotate(-35deg);
  }
`
export const HandRightAnimation = styled.div`
  animation: ${handRightAnimation} 1.2s forwards;
`

// -------------- Yerz & Ralu cursors animation --------------
const yerzAnimation = keyframes`
  from {
    transform: translate(0px, -300px);
  }

  to {
    transform: translate(60px, -326px);
  }
`
export const YerzAnimation = styled.div`
  animation: ${yerzAnimation} 1.3s forwards;
`

const raluAnimation = keyframes`
  from {
    transform: translate(0px, -300px);
  }

  to {
    transform: translate(-90px, -280px);
  }
`
export const RaluAnimation = styled.div`
  animation: ${raluAnimation} 1.3s forwards;
`
