import React from 'react'
import gif2 from './images/2.gif'
import SectionLayout from '../section-layout/section-layout';

import {
  ImageWrapperCss,
  NumbersCss,
  NumberColumnCss,
  ContentWrapperCss
} from './index.css'

const LeftGif = () => {
  return (
    <ImageWrapperCss>
      <img src={gif2} alt="gif2"/>
    </ImageWrapperCss>
  )
}

const RightContent = () => {
  return (
    <ContentWrapperCss>
      <h2>
        Our developers are our heroes: resourceful, highly
        skilled, energetic, and full of validated potential.
      </h2>

      <NumbersCss>
        <NumberColumnCss>
          <h1>53,421</h1>
          <h4><span>lines of code</span></h4>
        </NumberColumnCss>
        <NumberColumnCss>
          <h1>228</h1>
          <h4><span>tasks done</span></h4>
        </NumberColumnCss>
        <NumberColumnCss>
          <h1>0</h1>
          <h4>
            <span>
              developers were hurt in<br/> the process
            </span>
          </h4>
        </NumberColumnCss>
      </NumbersCss>

      <h2>
        Our team dynamics are similar to a sports team: a
        <span> performance-oriented collective </span>
        built on trust and skill. We’re the coach, you’re
        the player, and ahead is the goal.
      </h2>
    </ContentWrapperCss>
  )
}

const InternshipSection2 = () => {
  return (
    <SectionLayout leftTitle='Who are we'
      LeftComponent={LeftGif}
      rightTitle='We honour engineering.'
      RightComponent={RightContent}
    />
  )
}

export default InternshipSection2