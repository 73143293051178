import React, { useRef, useEffect, useState} from "react";
import { 
  SectionWrapperCss, 
  TimelineBlockCss, 
  ImgKaprioCss,
  TimelineScrollableOuter,
  TimelineScrollableCss,
  ImgTimelineCss,
  TellUsBlockCss,
  ImgDragCss
} from "./index.css";
import { PrimaryLinkCss } from '../../../layout/index.css';
import useDraggableScroll from "./useDraggableScroll";
import kaprio from "./images/kaprio.svg"
import timeline from "./images/timeline.svg"
import drag from "./images/drag.svg"

const Section3 = () => {
  const scrollableRef = useRef();
  const { onMouseDown } = useDraggableScroll(scrollableRef, { direction: 'horizontal' });

  const [scrollPosition, setScrollPosition] = useState(0);
  
  useEffect(() => {
    const handleScroll = () => {
      if(!!scrollableRef.current){
        const position = scrollableRef.current.scrollLeft;
        setScrollPosition(position);
      }
    };

    if(!!scrollableRef.current){
      scrollableRef.current.addEventListener('scroll', handleScroll, { passive: true });
  
      return () => {
          scrollableRef.current.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => { console.log(scrollPosition) }, [scrollPosition])

  return (
    <SectionWrapperCss>
      <h4>
				<span className="symbol">|</span> Our Process <span className="symbol">&lt;</span>
			</h4>
      <TimelineBlockCss>
        <ImgKaprioCss src={kaprio}/>
        <ImgDragCss src={drag} className={`${scrollPosition ? 'hide' : ''}`}/>
        <TimelineScrollableOuter>
          <TimelineScrollableCss ref={scrollableRef} onMouseDown={onMouseDown}>
            <ImgTimelineCss src={timeline}/>
            <TellUsBlockCss>
              <h1>Lets make something interesting </h1>
              <PrimaryLinkCss
                to="/contact-us"
              >
                Tell us your needs
              </PrimaryLinkCss>
            </TellUsBlockCss>
          </TimelineScrollableCss>
        </TimelineScrollableOuter>
      </TimelineBlockCss>
    </SectionWrapperCss>
  )  
}

export default Section3;

