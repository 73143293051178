import React from "react";
import './svg4.scss'
const svg4 = () => {
  return (
    <>
      <svg
        width="100%"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-made-with="vivus-instant"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M49.9983 61.6455C56.4306 61.6455 61.645 56.431 61.645 49.9987C61.6451 43.5664 56.4307 38.3521 49.9984 38.3521C43.566 38.3521 38.3516 43.5664 38.3516 49.9987C38.3516 56.431 43.566 61.6455 49.9983 61.6455Z"
            stroke="#EAEAEA"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_0"
          ></path>
          <path
            d="M74.8242 39.8824L96.3536 31.1765"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_1"
          ></path>
          <path
            d="M25.1769 60.1177L3.64746 68.8236"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_2"
          ></path>
          <path
            d="M39.8827 25.1766L31.1768 3.64722"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_3"
          ></path>
          <path
            d="M60.1182 74.8237L68.8241 96.3531"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_4"
          ></path>
          <path
            d="M74.7061 60.3528L96.1179 69.4116"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_5"
          ></path>
          <path
            d="M25.2946 39.6472L3.88281 30.5884"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_6"
          ></path>
          <path
            d="M60.3535 25.2943L69.4123 3.88257"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_7"
          ></path>
          <path
            d="M39.6468 74.7063L30.5879 96.118"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_8"
          ></path>
          <path
            d="M76.8232 50H99.9996"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_9"
          ></path>
          <path
            d="M23.1765 50H0"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_10"
          ></path>
          <path
            d="M50 23.1765V0"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_11"
          ></path>
          <path
            d="M50 76.8237V100"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_12"
          ></path>
          <path
            d="M68.9404 68.9412L85.4109 85.2941"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_13"
          ></path>
          <path
            d="M31.0585 31.0587L14.5879 14.7058"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_14"
          ></path>
          <path
            d="M68.9404 31.0589L85.2933 14.5884"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_15"
          ></path>
          <path
            d="M31.0591 68.9412L14.7061 85.4117"
            stroke="#4ABAF7"
            stroke-width="2"
            stroke-miterlimit="10"
            class="UiXohNLG_16"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0">
            <path
              width="100"
              height="100"
              fill="white"
              d="M0 0 L100 0 L100 100 L0 100 Z"
              class="UiXohNLG_17"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default svg4;
