import styled from 'styled-components'
import { screens, colors } from '../../../constants/theme'
import { motion } from 'framer-motion'

export const CareerLampSectionWrapperCss = styled(motion.section)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.black};
  z-index: 1000;
  display: flex;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: calc(100vh - 300px) 150px 100px;
  max-height: 100vh;

  @media (min-width: ${screens.md}) {
    grid-template-rows: 500px 2fr 129px;
  }

  @media (min-width: ${screens.lg}) {
    grid-template-rows: 600px 1.5fr;
  }

  > div {
    width: 100vw;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    z-index: 1;
  }
`

export const SwingLampWrapperCss = styled.div`
  margin: 0 auto;
  height: 100%;
  overflow-y: hidden;
  width: 190vw;

  @media (min-width: ${screens.md}) {
    width: 150vw;
    max-width: 1000px;
  }

  @media (min-width: ${screens.lg}) {
    width: 100vw;
    margin-top: -150px;
    max-width: 1440px;
  }

  > div {
    height: 121% !important;
  }
`

export const ButtonContinueCss = styled.button`
  background: transparent;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.03em;
  text-decoration: none;
  width: fit-content;
  color: ${colors.white};
  display: block;

  padding: 10px 15px;
  margin: 0 auto 20px;
  height: 48px;

  text-align: center;

  position: relative;
  overflow: hidden;
  transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);

  &:after {
    position: absolute;
    transition: all 0.8s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    content: '';
    width: 100%;
    right: auto;
    left: 0;
    bottom: 0;
    height: 2px;
    background: ${colors.blue};
  }

  &:hover {
    cursor: pointer;
    &:after {
      width: 0%;
      right: 0;
      left: auto;
    }
  }

  @media (max-width: ${screens.sm}) {
    padding: 7px 0px 8px;
  }
`

export const QuotesBlockCss = styled(motion.section)`
  display: flex;
  flex-direction: column;
  color: ${colors.white};
  width: 84vw;
  max-width: 350px;
  place-self: center;
  position: relative;
  z-index: 2;

  @media (min-width: ${screens.lg}) {
    width: 100%;
    max-width: 508px;
    margin: auto auto 50px auto;
    text-align: right;
    place-self: unset;
  }

  p {
    margin: 0;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 1.3 !important;
    margin-bottom: 16px;

    @media (min-width: ${screens.lg}) {
      font-size: 26px !important;
    }
  }

  span {
    font-style: italic;
    font-size: 18px;
    font-weight: 300 !important;
    @media (min-width: ${screens.lg}) {
      font-size: 20px;
    }
  }
`

export const DesktopSliderBlockCss = styled(motion.section)`
  position: absolute;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 50px;
  left: 0;
  right: 0;

  @media (min-width: ${screens.lg}) {
    top: 0;
    bottom: 0;
    right: 120px;
    left: unset;
  }

  .wrapper {
    height: 208px;
    width: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .circle {
    margin-top: 9px;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 1px solid #b8b8b8;
    opacity: 0;

    @media (min-width: ${screens.lg}) {
      opacity: 1;
    }
  }

  .line {
    display: block;
    width: 0;
    height: 131px;
    border-left: 1px dashed ${colors.blue};
    opacity: 0;

    @media (min-width: ${screens.lg}) {
      opacity: 1;
    }
  }

  button {
    all: unset;
    width: 44px;
    height: 44px;
    cursor: grab;
    position: absolute;
    left: 0;
    right: 0;

    .text-helper {
      position: absolute;
      left: 0;
      bottom: -24px;
      right: 0;
      text-align: center;
      color: ${colors.white};
      white-space: nowrap;
      display: grid ;
      width: 100%;
      grid-template-columns: 1fr;
      place-content: center ;
      font-size: 12px ;

      @media (min-width: ${screens.lg}) {
        display: none;
      }
    }
  }

`
