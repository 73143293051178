import styled from 'styled-components';
import { colors, screens } from "../../../constants/theme";
import { PrimaryLinkCss } from '../../../layout/index.css';


export const SubscribeModalBackdropCss = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const SubscribeModalContent = styled.div`
  background-color: ${colors.black};
  padding: 20px;
  width: 100%;
  height: 100%;
  z-index: 101;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;



  h2 {
    margin: 0;
    margin-bottom: 27px;
  }

  p {
    margin: 0;
    color: ${colors.grey4} !important;
  }

  @media (min-width: ${screens.lg}) {
    max-width: 965px;
    height: auto; 
    padding: 90px 158px;
    border: 1px solid ${colors.white};
    border-radius: 8px;
  }
`;

export const CloseButtonCss = styled.button`
  all: unset;
  position: absolute;
  top: 35px;
  right: 20px;
  color: ${colors.white};
  
  @media (min-width: ${screens.lg}) {
    top: 20px;
    cursor: pointer;
  }
`;

export const TitleCss = styled.h4`
  margin: 0px;
  margin-bottom: 25px;
`;


export const InputWrapperCss = styled.div`
  position: relative;
  border: 1px solid ${colors.white};
  border-radius: 5px;
  padding: 15px 25px;
  display: flex;
  width: calc(100% - 50px);
  margin-top: 40px;

  @media (min-width: ${screens.lg}) {
    max-width: 420px;
  }
`

export const InputLabelCss = styled.label`
  position: absolute;
  top: 0;
  left: 25px;
  margin: 0;
  padding: 0 20px;
  transform: translateY(-50%);
  color: white;
  background-color: ${colors.black};
`

export const InputEmailCss = styled.input`
  all: unset;
  background: transparent;
  flex: 1;
  margin-right: 20px;
  caret-color: ${colors.blue};
  color: ${colors.white};
  width: 150px;
  
  &::placeholder {
    color: ${colors.grey4} !important;
    font-style: italic;
  }
`

export const InputErrorCss = styled.span`
  font-size: 0.75rem;
  color: red;
  display: inline-block;
  margin-top: 10px;
`

export const NotifyButton = styled(PrimaryLinkCss)`
  margin: 0;
  font-size: 16px;
  padding: 0 !important;
`



