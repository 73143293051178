import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';

import Homepage from "./pages/homepage";
// import WhoWeAre from "./pages/whoweare";
import Careers from "./pages/careers";
import Projects from "./pages/projects";
import Internship from "./pages/internship";
import Nova from "./pages/nova";
import Bryant from "./pages/bryant-dental";
import Blog from "./pages/blog";
import Bloomd from './pages/bloomd';
import BlogPage from "./pages/blog-page";
import LayoutPath from "./layout/index";
import Subscribe from './pages/subscribe';
import Privacy from './pages/privacy';
import BookVlad from './pages/book-vlad';
import ContactUs from './pages/contact-us';
import AboutUs from './pages/about-us';

import Header from './components/Header';

import { LayoutsWrapperCss, GlobalStyle } from "./app.css";
function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
export default function App() {

  return (
    <Router>
      <ScrollToTop />
      <LayoutsWrapperCss>
        <GlobalStyle />
        <Header />
          <Switch>
            <LayoutPath exact path="/" component={Homepage} blocks={[1, 2, 3]} />
            <LayoutPath
              path="/bryant-dental"
              component={Bryant}
              project={true}
              blocks={[1, 2, 3]}
            />

            <LayoutPath
              path="/bloomd"
              component={Bloomd}
              project={true}
              blocks={[1, 2, 3]}
            />

            <LayoutPath
              path="/nova"
              component={Nova}
              project={true}
              blocks={[1, 2, 3]}
            />
            {/* <LayoutPath path="/whoweare" component={WhoWeAre} blocks={[2, 3]} /> */}
            <LayoutPath path="/about-us" component={AboutUs} blocks={[2, 3]} />
            <LayoutPath path="/privacy" component={Privacy} blocks={[2, 3]} />
            <LayoutPath path="/careers" component={Careers} blocks={[2, 1, 3]} />
            <LayoutPath path="/blog" exact component={Blog} blocks={[3]} />
            <LayoutPath path="/blog/:id" exact component={BlogPage} blocks={[3]} />
            <LayoutPath path="/projects" exact component={Projects} blocks={[3]} />
            <LayoutPath
              path="/internship"
              component={Internship}
              blocks={[1, 3]}
            />
            <LayoutPath
              path="/subscribe"
              component={Subscribe}
            />
            <LayoutPath
              path="/contact-us"
              component={ContactUs}
            />
            <LayoutPath
              path="/unsubscribe"
              component={Subscribe}
            />
            <LayoutPath
              path="/book-vlad"
              component={BookVlad}
            />
          </Switch>
      </LayoutsWrapperCss>
    </Router>
  )
}
