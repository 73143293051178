import React from 'react';
import { AboutUsFirstSectionWrapperCss, InfoBlockCss, GifBlockCss } from './index.css';
import toss from './images/toss.gif';

const AboutUsFirstSection = () => {
	return (
		<AboutUsFirstSectionWrapperCss>
			<h4>
				<span className="symbol">|</span> How it all started <span className="symbol">&lt;</span>
			</h4>
			<InfoBlockCss>
				<p>Kappa came into being a few years ago in London.</p>

				<p>
					In the beginning, we were two developers looking for a project to make our voices heard on the market. The first vote of confidence came with the first client, but there's only so much that can be done in a team of two. We had to grow fast, and we did.
				</p>

				<GifBlockCss src={toss} />
				
			</InfoBlockCss>
		</AboutUsFirstSectionWrapperCss>
	);
};

export default AboutUsFirstSection;
