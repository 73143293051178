import React from 'react'
import Container from '../../Container/index'
import * as css from './index.css'
import pGif from './assets/p2.gif'
import stars from './assets/stars.svg'
import planet from './assets/planet.svg'
import { useOnScreen } from '../section5'

const InternshipSection11 = () => {
  const [pGifRef, pVisible] = useOnScreen({ threshold: 1 })

  return (
    <Container>
      <css.Content>
        {/* P Gif - Dog shaking hand */}
        <css.GifWrapper>
          <css.MaskedPContainer ref={pGifRef}>
            <css.MaskedP src={pGif} alt="Doggo: It's a deal!" />

            <css.Stars
              src={stars}
              alt="Stars svg"
              startAnimation={pVisible}
            />

            <css.Planet
              src={planet}
              alt="Planet svg"
              startAnimation={pVisible}
            />
          </css.MaskedPContainer>
        </css.GifWrapper>

        <css.TextContent>
          <h3>You had us when...</h3>

          <css.Paragraph>
            ... you changed your Tinder bio to “BSc/MSc Computer Science, JS
            training, and some 10k lines of code”.
          </css.Paragraph>

          <css.Paragraph>
            If we’re a match made in heaven, who are we to question the
            universe? The best interns stick around: you have the possibility to
            continue working with us after your internship ends.
          </css.Paragraph>
        </css.TextContent>
      </css.Content>
    </Container>
  )
}

export default InternshipSection11
