import React from 'react'
import Container from '../../Container'
import dental from './images/dental.png'
import bloomd from './images/bloomd.png'
import aura from './images/aura.png'
import Card from './card'
import { 
  BackgroundCss,
  WrapperCss,
  CardsContainerWrapperCss,
  PrimaryLinkCss,
  LowerTextWrapper,
} from './index.css'
import SectionLayout from '../section-layout/section-layout'

const GetInvolved = () => {
  return (
    <LowerTextWrapper>
      <h1>Put your name on it</h1>
      <PrimaryLinkCss>
        Participate in incoming projects
      </PrimaryLinkCss>
    </LowerTextWrapper>
  )
}

const InternshipSection4 = () => {
  const upperCards = [
    {
      image: dental,
      title: 'Bryant Dental',
      redirect: 'https://bryant.dental/',
      text: 
        'A UK-based dentistry equipment manufacturer and distributor. Our interns implemented eye-candy animations within an award-winning website design.'
    },
    {
      image: aura,
      title: 'Aura',
      redirect: null,
      text:
        'Reshaping the UK dentistry industry through software innovation. Our biggest project yet that helps dentists do their job a lot more efficiently.',
    },
    {
      image: bloomd,
      title: 'Bloomd',
      redirect: 'https://www.bloomd.com/',
      text:
        'Connecting senior citizens with the youth through positive life experience discussions. Multiple functionalities (i.e. chat, login, forum, replies) were coded by our interns.',
    },
  ]

  const lowerCards = [
    {
      image: null,
      title: 'CV Labs',
      redirect: null,
      text:
        'A dynamic resume builder. A personal project that was aimed towards freelancers and young professionals who were job searching. ',
    },
    {
      image: null,
      title: 'Honor Cycles',
      redirect: null,
      text:
        'We built multiple systems for them to carry out their business more efficiently, such as an online service reservation widget and an online payment system.',
    },
    {
      image: null,
      title: 'Create Live',
      redirect: null,
      text:
        'Personal project that tackled the Social Media Marketing industry. Create Live is a Facebook Live tool that offers users the opportunity to heavily customise their Live sessions.',
    },
  ]

  return (
    <BackgroundCss>
      <Container>
        <WrapperCss>

          <CardsContainerWrapperCss>
            {upperCards.map((card) => {
              return <Card cardInfo={card} key={card.title}/>
            })}
          </CardsContainerWrapperCss>

          <CardsContainerWrapperCss>
            {lowerCards.map((card) => {
              return <Card cardInfo={card} key={card.title}/>
            })}
          </CardsContainerWrapperCss>
      
        </WrapperCss>
      </Container>

      <SectionLayout RightComponent={GetInvolved}/>
    </BackgroundCss>
  )
}

export default InternshipSection4