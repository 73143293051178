import React from "react";
import './svg5.scss'
const svg5 = () => {
  return (
    <>
      <svg
        width="100%"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M91.587 67.4633L90.6695 67.5393V68.4599V69.3804V71.8271C90.6695 75.1409 87.9832 77.8271 84.6695 77.8271H8.00001C4.6863 77.8271 2 75.1408 2 71.8271V28.5383C2 25.2246 4.68629 22.5383 8 22.5383H84.6695C87.9832 22.5383 90.6695 25.2246 90.6695 28.5383V30.9846V31.9051V32.8256L91.5868 32.9017L92.5043 32.9778L92.5044 32.9778C95.5809 33.2327 98 35.8133 98 38.957V61.4081C98 64.5519 95.5809 67.1324 92.5044 67.3873L91.587 67.4633Z"
          stroke="#4ABAF7"
          stroke-width="2"
          class="HfcobzHh_0"
        ></path>
        <path
          d="M45.1975 53.7531H37.3291L53.0666 33.0608L49.4236 45.8841H57.8753L42.2834 67.3047L45.1975 53.7531Z"
          stroke="#EAEAEA"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="HfcobzHh_1"
        ></path>
      </svg>
    </>
  );
};

export default svg5;
