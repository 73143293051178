import styled from 'styled-components';
import { screens } from '../../../constants/theme';

export const BlockCss = styled.div`
	display: flex;
	margin-bottom: 105px;
	align-items: center;

	@media (max-width: ${screens.lg}) {
		flex-direction: column-reverse;
	}
`;

export const InfoCss = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	max-width: 424px;

	margin-right: 73px;

  a {
		margin-top: 15px;
    text-decoration: none;
  }

	@media (max-width: ${screens.lg}) {
		margin: 0px 25px;
		width: auto;

		h2 {
			margin-top: 33px;
			margin-bottom: 0px;
		}
	}
`;

export const ImageCss = styled.div`
	width: 58.31vw;

	@media (max-width: ${screens.lg}) {
		width: 100%;
	}

	img {
		width: 100%;
	}
`;