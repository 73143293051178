import React, { Fragment, useState } from 'react'
import { Section1, LampSection, EmptySection, StoriesCarouselSection, BenefitsSection, GotYourAttentionSection } from '../components/Careers'
import PageTransition from '../components/PageTransition'

const Careers = () => {
  const [isLampDone, setIsLampDone] = useState(false);
  return (
    <Fragment>
      <LampSection onFinish={() => setIsLampDone(true)}/>
      <Fragment>
        <EmptySection show={!isLampDone}/>
        <EmptySection show={false}/>
        <Section1 />
        <StoriesCarouselSection />
        <BenefitsSection />
        <GotYourAttentionSection />
      </Fragment>
    </Fragment>
  )
}

export default Careers
