import styled from "styled-components";
import { screens, colors} from '../../constants/theme';


export const BlogPageWrapperCss = styled.div`
  margin: 100px auto;
  width: 100%;
  padding: 0 25px;
  box-sizing: border-box;
  color: ${colors.grey};

  @media (min-width: ${screens.lg}) {
    margin: 100px auto;
    max-width: 680px;
    padding: 60px 0;
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: ${colors.white};
  }

  figure {
    display: grid;
    place-content: center;
    margin: 60px 0;

    img {
      width: 100vw;

      @media (min-width: ${screens.lg}) {
        max-width: 100%;
      }
    }
  }
`

export const BlogPageTitleCss = styled.h1`
  font-size: 2rem !important;
  line-height: 1.2 !important;
  margin-bottom: 0 !important;
 
  @media (min-width: ${screens.lg}) {
    font-size: 3rem !important;
  }


`

export const BlogFigureCss = styled.figure`
  display: grid;
  place-items: center;

  img {
    width: 100vw;
    max-width: calc(100vw - 15px) !important;
    height: auto;
  }
`

export const AuthorWrapperCss = styled.div`
  padding-top: 60px;
  display: flex;
`

export const AuthorTextCss = styled.span`
  padding-right: 20px;
  white-space: nowrap;
  opacity: 0.7;
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 400;
`

export const AuthorLineSeparatorCss = styled.span`
  height: 1px;
  width: 100%;
  margin-top: 5px;
  background: ${colors.blue};
  width: 35%;
`