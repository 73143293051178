import React from 'react'
import aGif from './assets/a2.gif'
import bubbles from './assets/bubbles.svg'
import soap from './assets/soap.svg'
import Container from '../../Container/index'
import * as css from './index.css'
import { useOnScreen } from '../section5'

const InternshipSection12 = () => {
  const tips = [
    'Where to look for jobs;',
    'How to build your professional online profile;',
    'Resume tips;',
    'What employers look for in an ideal candidate;',
    'How to mention the right skills in the right manner;',
    "Structure your work into an online presence (we're not talking about LinkedIn);",
    'Freelancing versus full-time job;',
  ]
  const [aGifRef, aVisible] = useOnScreen({ threshold: 1 })

  return (
    <Container>
      <css.Wrapper>
        <css.TextContent>
          <h3>But wait, there's more...</h3>

          <css.Paragraph>
            We’ll forge you into desirable and indispensable professionals. Let
            us show you around our secret stash of HR tips and tricks.
          </css.Paragraph>
        </css.TextContent>

        <css.Content>
          <css.TextContent>
            <h4>
              <span className="symbol">|&nbsp;</span>
              You may walk away with:&nbsp;
              <span className="symbol">&lt;</span>
            </h4>

            <css.TipsList>
              {tips.map((tip, index) => (
                <css.ListItem key={index}>
                  <css.Paragraph>{tip}</css.Paragraph>
                </css.ListItem>
              ))}
            </css.TipsList>
          </css.TextContent>

          {/* A Gif - Brad Pitt */}
          <css.GifWrapper>
            <css.MakedAContainer ref={aGifRef}>
              <css.MaskedA src={aGif} alt="Tips from Brad Pitt" />

              <css.Bubbles
                src={bubbles}
                alt="Bubbles svg"
                startAnimation={aVisible}
              />

              <css.Soap
                src={soap}
                alt="Soap svg"
                startAnimation={aVisible}
              />
            </css.MakedAContainer>
          </css.GifWrapper>
        </css.Content>
      </css.Wrapper>
    </Container>
  )
}

export default InternshipSection12
